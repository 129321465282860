import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US22 from '../../../assets/images/US22-p-500.png';
import US23 from '../../../assets/images/US23-p-500.png';
import US24 from '../../../assets/images/US24-p-500.png';
import US25 from '../../../assets/images/US25-p-500.png';
import US26 from '../../../assets/images/US26.png';
import US29 from '../../../assets/images/US29-p-500.png';
import US30 from '../../../assets/images/US30-p-500.png';
import US31 from '../../../assets/images/US31-p-500.png';
import US32 from '../../../assets/images/US32-p-500.png';
import US33 from '../../../assets/images/US33-p-500.png';
import US34 from '../../../assets/images/US34-p-500.png';
import US49 from '../../../assets/images/US49.png';
import US48 from '../../../assets/images/US48-p-500.png';
import US47 from '../../../assets/images/US47-p-500.png';

const images = [
    {
        src: US22,
        title: "Wall mural that reads yy with head"
    },
    {
        src: US23,
        title: "Children read the56 mural"
    }
];
const images1 = [
    {
        src: US24,
        title: "Wall mural that 56 with head"
    }, {
        src: US25,
        title: "An academy student 45 while drawing an assignment"
    },
    {
        src: US26,
        title: "Children read the mu 25ral"
    }
];
const images2 = [
    {
        src: US29,
        title: "Wall mural that reads"
    },
    {
        src: US30,
        title: "Children read the m yural"
    }, {
        src: US31,
        title: "An smiles while drawing an assignment"
    }
];
const images3 = [
    {
        src: US32,
        title: "Wall mural that reads with head, h"
    },
    {
        src: US33,
        title: "Children read the m ural 33"
    },
    {
        src: US34,
        title: "Children read the mura l"
    }
];
const images4 = [
    {
        src: US49,
        title: "Wall mural that reads with head, heart, and hands"
    },
    {
        src: US48,
        title: "Children read the mura l"
    },
    {
        src: US47,
        title: "Children read the mu ral"
    }
];
const RanchoSespePiru = () => {
    const allImages = {
        images,
        images1,
        images2,
        images3,
        images4
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = allImages[arrayName];
        if (selectedArray) {
            toggleModal();
            setCurrentIndex(selectedArray[imageId]);
        }
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1>Rancho Sespe/Piru Summer school and year-round enrichment program</h1>

                    <h2><strong>Sacrifice in Action</strong></h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} style={{ height:"400px",objectFit:"cover" }}/>
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>Students at Rancho Sespe made decorative birdhouses as one of many projects when they engaged in the unit
                        on Sacrifice. <br />The following unit took them on a field trip to conduct an environmental cleanup at a
                        regional lake.</p>
                    <p>At the lake, Gorgonio (pictured here) then age 11, saved a girl from drowning. When his friend Arturo
                        called out, “But you can’t swim!”, he replied, “I know, but I can sacrifice.” &nbsp;Arturo said, “Yes,
                        we both know how to sacrifice.” The two non-swimmers saved the life of their classmate, endangering
                        their own in the process. Consequently, Gorgonio set out on a path to become an emergency medical
                        technician. Arturo studied at a university and became a highly successful engineer. (Among other things,
                        he worked on satellite technologies to identify areas of drought and potential food insecurity.) Both
                        boys were the first in their families to have a chance to enter high school and go on to college. Their
                        Full-Circle Learning course had cultivated and reinforced the spirit of sacrificial service inherent in
                        family culture.</p>
                    <h2 className="heading-32">Recognizing Habit-of-Heart Role Models</h2>
                    <div className="muli-colmn-tw">
                        {images1.map((image, index) => (
                            <Card
                                className="image-card"
                                key={image.title}
                                onClick={() => showModalImage(index, "images1")}
                                style={{ padding: "6px 0px" }}
                            >
                                <CardImg src={image.src} />
                            </Card>
                        ))}
                    </div>
                    <p>Firemen often became teary-eyed when receiving habit-of-heart awards and songs from Full-Circle Learners.
                    </p>
                    <p>These two firefighter events occurred in the early 2000s. &nbsp;Students also honored life guards,
                        farmers, researchers, astronomist, bakers, disabled workers and others.</p>
                    <p>While studying the habit of Advocay, students presented plans for species preservation at a regional
                        recreational lake. With songs and poems, they honored the park official who considers the concerns of
                        both humans and other living things. In turn, he invited them to come to the lake and assist with an
                        environmental cleanup.</p>
                    <h2 className="heading-33">Mastery Begins with Me</h2>
                    <div className="muli-colmn-tw">
                        {images2.map((image, index) => (
                            <Card
                                className="image-card"
                                key={image.title}
                                onClick={() => showModalImage(index, "images2")}
                                style={{ padding: "6px 0px" }}
                            >
                                <CardImg src={image.src} />
                            </Card>
                        ))}
                    </div>
                    <p>Each year’s mastery ceremony, pictured here, K-12 students receive certificates from teachers for the
                        habit-of-heart they best mastered. Next, they each give a certificate to caregivers or family members to
                        honor them for the habit-of-heart they most appreciate. The farmworker families at Rancho Sespe are not
                        the only ones to feel inspired. Mastery Ceremonies occur around the world. After Hurricane Katrina,
                        families from Louisiana moved west. The Rancho Sespe village and its Full-Circle Learning school opened
                        their doors to Kayla, who learned the habit of Vision Seeking. Here, she envisioned her future life as a
                        doctor.</p>
                    <h2 className="heading-34">Passing Along a Tradition of Peacemaking</h2>
                    <div className="muli-colmn-tw">
                        {images3.map((image, index) => (
                            <Card
                                className="image-card"
                                key={image.title}
                                onClick={() => showModalImage(index, "images3")}
                                style={{ padding: "6px 0px" }}
                            >
                                <CardImg src={image.src} />
                            </Card>
                        ))}
                    </div>
                    <p>The first group of learners at Rancho Sespe created a newspaper called Planters of Peace (named by the
                        students. Communicating their message every year became a tradition.</p>
                    <p>Johanna carried on the tradition of respect for elders by introducing her little sister to an adopted
                        grandparent.The first group of learners at Rancho Sespe created a newspaper called Planters of Peace
                        (named by the students). Communicating their message every year became a tradition.</p>
                    <p>In 2008, a study of tree planting engaged Pedro and other Ambassadors in a global wisdom exchange.
                        Memorizing songs, learning conflict resolution skills and acting on their personal convictions helped
                        the youth encourage friends to avoid gangs except for “pro-peace” gangs.Johanna carried on the tradition
                        of respect for elders by introducing her little sister to an adopted grandparent.The first group of
                        learners at Rancho Sespe created a newspaper called Planters of Peace (named by the students).
                        Communicating their message every year became a tradition.</p>
                    <p>Learners from preschool age (the Peacemakers), to primary grades (the Rebuilders) to secondary students
                        (the Ambassadors) helped reduce the level of community over the first decade of their participation at
                        Full-Circle Learning Rancho Sespe.In 2008, a study of tree planting engaged Pedro and other Ambassadors
                        in a global wisdom exchange. Memorizing songs, learning conflict resolution skills and acting on their
                        personal convictions helped the youth encourage friends to avoid gangs except for “pro-peace”
                        gangs.Johanna carried on the tradition of respect for elders by introducing her little sister to an
                        adopted grandparent.The first group of learners at Rancho Sespe created a newspaper called Planters of
                        Peace (named by the students). Communicating their message every year became a tradition.</p>
                    <p>The learners overcame generational and regional differences. Here, Johanna carried on the tradition of
                        respect for elders by introducing her little sister to an adopted grandparent.</p>
                    <h2><strong>Pandemic Approaches</strong></h2>
                    <div className="muli-colmn-tw">
                        {images4.map((image, index) => (
                            <Card
                                className="image-card"
                                key={image.title}
                                onClick={() => showModalImage(index, "images4")}
                                style={{ padding: "6px 0px" }}
                            >
                                <CardImg src={image.src} />
                            </Card>
                        ))}
                    </div>
                    <p>&nbsp;Learners could no longer engage in kinesthetic strategies to “walk as one” during the 2020
                        pandemic.</p>
                    <p>&nbsp;Full-Circle Learning responded to the need for social distancing by suppling Chromebook computers
                        for families in its Piru-based summer school.</p>
                    <p>The board helped teachers and parents ease into distance learning without losing the altruistic purpose
                        embedded in the Habit-of-Heart units.</p>

                </Col>


            </Row>
        </Container>
    )
}

export default RanchoSespePiru