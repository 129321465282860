import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';
import Bolivia1 from '../../assets/images/Bolivia1-p-1600.png';
const images = [
  {
    src: Bolivia1,
    title: "Anisa Qualls among the trees in Bolivia"
  }
];

const Bolivia = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Bolivia</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className=' my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >

          <div className="rich-text-block-2 color-link w-richtext">
            <p>Early in her career as an educator, Anisa Qualls traveled across Bolivia and Brazil, presenting
              Full-Circle Learning and its strategies at various schools. Some classrooms flourished with new
              projects, launching celebrations of cultural diversity and service projects. The experience prepared
              Anisa for a longer stay at her next post, Chennai India, where she nurtured the first teachers at
              its Full-Circle Learning Academy. Eventually, Anisa resumed her graduate studies and began to teach
              in a university in her home state, Ohio. By then she had made her mark on teachers and children.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>


      </Row>
    </Container>
  )
}

export default Bolivia