import './custom.css';
import MyRouter from './Router/Router';
import Footer from './components/Footer';
function App() {
  return (
    <>
      <MyRouter />
      <Footer />
      </>
  );
}

export default App;
