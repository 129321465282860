import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import japan1 from '../../assets/images/japan1.jpg';
import japan2 from '../../assets/images/japan2.jpg';
import Japan3 from '../../assets/images/Japan3.png';
import Japan4 from '../../assets/images/Japan4.png';
import Japan5 from '../../assets/images/Japan5.png';


const img_block_1 = [
  {
    src: japan1,
    alt: 'Leaders each make a page of the coral reef book for Japan.',
    class: '',
    key: 'japan1'

  },
  {
    src: japan2,
    alt: 'Yamaguchi translators with the coral reef books.',
    class: '',
    key: 'japan2'
  }
];

const images = [
  {
    src: Japan3,
    title: 'Students exploring the tradition of Thanksgiving',
    class: 'img_class_inline',
    key: 'Japan3'
  },
  {
    src: Japan4,
    title: 'Students at the chalkboard',
    class: 'img_class_inline',
    key: 'Japan4'
  },
  {
    src: Japan5,
    title: 'Students in class standing on paper coordinated on the floor',
    class: 'img_class_inline',
    key: 'Japan5'
  }
];

const Japan = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Japan</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="w-richtext">
            <h1><strong>The Legacy of Marilyn Higgins</strong></h1>
            <p>Full-Circle Learning (FCL) pays tribute to the late Dr. Marilyn Higgins, who introduced the
              Full-Circle Learning educational model in Japan and passed away in November 2021. Over many years,
              Dr. Higgins trained educators, championed FCL in schools, and directed her multicultural club at
              Yamaguchi University to translate wisdom exchanges between classrooms in the US and Japan. She led
              professors on a global tour and consulted with them about making FCL's guided imagery stories
              accessible for parents, after which she and her husband, Dr. Michael Higgins, produced the first
              recordings in Japanese, with original music. She then coordinated publication of the works in
              English through North Pacific Press, in the book, The Sky Belongs to Everyone (available on
              Amazon).&nbsp;</p>
            <p>Over the years, Marilyn spoke at academic conferences across Asia, especially to share FCL as a tool
              for creating balance in systems where academic pressure without a greater purpose creates depression
              among young people. She promoted FCL's statistics and success stories, in which academics melded
              with compassionate service to help learners focus their skills outward, making life meaningful while
              knitting the nations and instilling habits of the hearts through altruism.&nbsp;</p>
            <p>Indeed, Marilyn Higgins touched many lives across the Full-Circle Learning world. We will miss her
              optimism and smile full of love, her camaraderie, and her confidence that anything worth doing is
              doable.&nbsp;</p>
            <p>(In the wisdom exchange pictured here, circa 2007, US students wrote to Japanese counterparts seeking
              their partnership in saving coral reefs around the world. Also pictured, the Yamaguchi translators
              go to work on the coral reef letters.)</p>
            {img_block_1.map((img, index) => (
              <figure className="w-richtext-align-center w-richtext-figure-type-image" style={{ "textAlign": "center" }}>
                <div key={img.key}><img
                  src={img.src}
                  loading="lazy" alt={img.alt} />
                </div>
              </figure>
            ))}
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>


        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p> In 2008, Full-Circle Learning developed a relationship with Japan’s Yamaguchi University, through
              Dr. MarilynHiggins. Over the years, this relationship took on many aspects: training Japanese
              schools, facilitating wisdom exchanges abroad, and defining how the educational model could prevent
              academic stress and trauma while promoting life skills and resiliency. </p>
            <p> Ultimately, a team of professors from Yamaguchi University traveled to the FCL headquarters, where
              they identified, in particular, the value of infusing the habits-of-heart into guided imagery
              exercises. They encouraged the development of a bilingual treatment ofFCL’s related stories. The
              English version of <em>The SkyBelongs to Everyone</em> was published
              in 2020 by Japan’sPacific North Pacific Press and is available onamazon.com. </p>
            <p> The Japanese visitors had also visited a Full-CircleLearning school in the US where children had
              studied the treatment of Japanese Americans living in internment camps. The children presented them
              with dioramas of unity garden, to thank the captured Japanese families for demonstrating a sense of
              unity by working together to </p>
            <p> offer health, education, social services and rock gardens, despite the deplorable conditions and
              hardships of camp life. </p>
            <p> In another act of reconciliation, a fourth-grade class inLos Angeles engaged in a wisdom exchange
              with aJapanese school. The children learned about radiation sickness in relation to the events of
              WWII. They read a book called <em>Sadako and the Thousand Paper Cranes.</em>Because
              of the similarity of leukemia and radiation sickness, they learned the origami art form and
              made1,000 paper cranes to hang in a local children’s hospital.They made another thousand cranes and
              sent them to their wisdom exchange partner classroom in Japan. TheJapanese school exhibited the
              cranes at the WorldCurriculum Conference in the Philippines, recommending the project to other Asian
              countries. </p>
            <p> Dr. Michael Higgins created recordings of guided imagery, complete with music. Dr. Marilyn Higgins
              spoke at conferences, taught the Full-Circle Learning model to local schools, and worked with the
              multicultural club at the university to translate wisdom exchanges. Her influence proved especially
              valuable for learners inside and outside Japan. </p>
            <p> Below, students wore Thanksgiving costumes as they explored the customs and history of their wisdom
              exchange partner. Several teachers helped students integrate locally relevant coursework and context
              into their conflict bridge exercises.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>




      </Row>
    </Container>
  )
}

export default Japan