import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US54 from '../../../assets/images/US54-p-500.png';
import US55 from '../../../assets/images/US55-p-500.png';

const images = [
    {
        src: US54,
        title: "Wall mural that reads with head"
    },
    {
        src: US55,
        title: "Children read the mural"
    }
];


const NorthernCalifornia = () => {

    const allImages = {
        images
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = allImages[arrayName];
        if (selectedArray) {
            toggleModal();
            setCurrentIndex(selectedArray[imageId]);
        }
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1><strong>Northern California 2: </strong>Community Education Initiative (CEI) Summer School – El Cerritos
                    </h1>

                    <h2>&nbsp;<strong>Teachers put Training into Practice</strong></h2>

                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} style={{ height:"400px",objectFit:"cover" }}/>
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>&nbsp;Northern Californians have had opportunities for Full-Circle Learning professional development
                        training at many venues, from district offices to hospital meeting rooms. These teachers consult at the
                        San Leandro public library.&nbsp;</p>
                    <p>Participant Mina Wilson acted on her enthusiasm, establishing an educational initiative in El Cerrito,
                        sponsored by her organization, Community Education Initiatives.</p>
                    <p>At the mastery ceremony, an El Cerrito student gratefully received his certificate as teachers urged him
                        on.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default NorthernCalifornia