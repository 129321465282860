import React from "react";
import { Row, Col, Card, Container } from "reactstrap";
const MissionAndVision = () => {
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <Card className="line_height">
            <h1 style={{ fontSize: "44px" }}>Mission and Vision</h1>
            <p>
              Full-Circle Learning exists to help young people embrace their
              role as society’s humanitarians and change agents. With the help
              of contributors like you, we provide:
            </p>
            <ul>
              <li>
                Capacity building for teachers, learners, and schools worldwide
              </li>
              <li>Community impact grants</li>
              <li>Student wisdom exchanges across borders</li>
              <li>Selected scholarships</li>
              <li>
                Humanitarian aid for learning communities in extreme crisis
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default MissionAndVision;
