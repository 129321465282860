import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";

const CareSkillsCourse = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1">Care Skills Course</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Row>
                        <Col sm="12">
                            <h2 className="heading-25">Introduction</h2>
                            <p>Health care providers work continually to save lives and to reduce human suffering during a public health crisis. They can also promote good health on a regular basis in communities worldwide. Dr. Farzin Rahmani designed this online course for those aspiring to become skilled nurses, in-home care givers or for any student who want to take an exploratory first step toward a career as a general practitioner. The modules also offer advanced tools for student volunteers ready to strengthen their usefulness as community health advocates. The course will be led by a trained nurse. To participate as a self-directed learner, follow along with nursing instructor Nikki Smith. For inspiration on the importance of the habits-of-heart in the field of nursing, watch the videos at the end of the course.</p>
                            <a target="_blank" rel="noreferrer" href="/" className="download-button is-empty w-button">Download</a>
                            <div className="div-block-8">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FhBvvTzgriLI%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DhBvvTzgriLI&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FhBvvTzgriLI%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" allow="autoplay; fullscreen" allowFullScreen ></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12">
                            <h2 className="heading-25">Care Skills Module One</h2>
                            <p className="w-dyn-bind-empty"></p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/careskillscourse/care-skills-one.pdf" className="download-button is-empty w-button">Download</a>
                            <div className="div-block-8">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvjuBJ8e-YPk&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvjuBJ8e-YPk&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvjuBJ8e-YPk%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" allow="autoplay; fullscreen" allowFullScreen ></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12">
                            <h2 className="heading-25">Care Skills Module Two</h2>
                            <p className="w-dyn-bind-empty"></p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/careskillscourse/care-skills-two.pdf" className="download-button is-empty w-button">Download</a>
                            <div className="div-block-8">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEGBYar2SYEA%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEGBYar2SYEA&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEGBYar2SYEA%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" allow="autoplay; fullscreen" allowFullScreen ></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12">
                            <h2 className="heading-25">Coronavirus</h2>
                            <p className="w-dyn-bind-empty"></p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/careskillscourse/care-skills-coronavirus.pdf" className="download-button is-empty w-button">Download</a>
                            <div className="div-block-8">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FU6HTRWJdBAM&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DU6HTRWJdBAM&amp;image=http%3A%2F%2Fi.ytimg.com%2Fvi%2FU6HTRWJdBAM%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" allow="autoplay; fullscreen" allowFullScreen ></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12">
                            <h2 className="heading-25">Assignment Questions Module One</h2>
                            <p className="w-dyn-bind-empty"></p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/careskillscourse/care-skills-questions-one.pdf" className="download-button is-empty w-button">Download</a>
                            <div className="div-block-8">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12">
                            <h2 className="heading-25">Assignment Questions Module Two</h2>
                            <p className="w-dyn-bind-empty"></p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/careskillscourse/care-skills-question-two.pdf" className="download-button is-empty w-button">Download</a>
                            <div className="div-block-8">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12">
                            <h2 className="heading-25">Glossary</h2>
                            <p className="w-dyn-bind-empty"></p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/careskillscourse/care-skills-glossary.pdf" className="download-button is-empty w-button">Download</a>
                            <div className="div-block-8">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default CareSkillsCourse