import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import threeboysinclass from '../../assets/images/threeboysinclass.png';
import GirlDoorWayAfghanistan from '../../assets/images/GirlDoorWayAfghanistan.png';
import girlWithStudyMaterials from '../../assets/images/girlWithStudyMaterials.png';
const images = [
  {
    src: threeboysinclass,
    title: "Three boys playing in class and smiling at the camera"
  }, {
    src: girlWithStudyMaterials,
    title: "A young girl looks up from her study materials in class"
  }, {
    src: GirlDoorWayAfghanistan,
    title: "A young girl stands in the doorway looking in"
  }
];

const Afghanistan = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Afghanistan</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Afghanistan’s education system went through a rejuvenation period after the fall of the Taliban, with
              the help of NGOs and local educators who wanted to preserve their culture, participating in the
              effort to bring science and art back to the classroom and to admit girls to school.</p>
            <p>In 2002, Full-Circle Learning was asked by Meridian and San Francisco’s Zeum Museum California to
              help children around the world contribute to an environmental art exhibit. Children from multiple
              countries would create work to fill the exhibit space.</p>
            <p>California students had already created handmade wisdom exchanges for Afghan students. On a
              Full-Circle Learning trip, a trainer helped the distant “brothers and sisters” exchange poetry and
              art on the theme, “What is the most precious thing to you about your environment, and what will you
              do to protect it?”</p>
          </div>
          <div className="rich-text-block w-richtext">
            <p>At one street school, many students attended only half days because their families depended on their
              work as“cowboys” tending the family cow or goat. They cherished the chance to come to school and
              learn at last.</p>
            <p>When the students heard the question, each student busily drew their response through art. One small
              boy of nine drew his animal on the hillside with a missile falling toward it from a plane. The hill
              had been pocked with landmines for decades. Before the explosive could reach its target, the boy
              circled it and drew a line through it. When asked to explain his art, he stood and firmly spoke,
              saying, “We live in a beautiful country, with hillsides of snow and flowers and trees and animals,
              and yet war has threatened our environment since before our parents were born.We cannot protect our
              environment until we have peace. &ZeroWidthSpace;<em>We</em>&ZeroWidthSpace; must be the first
              generation of peacemakers.” </p>
            <p> </p>
            <p>&zwj;</p>
          </div>
        </Col>


      </Row>
    </Container>
  )
}

export default Afghanistan