import React, {
  useState
} from 'react';
import {
  Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US13 from '../../../assets/images/US13.png';
import US14 from '../../../assets/images/US14-p-500.png';
import US15 from '../../../assets/images/US15-p-500.png';
import US16 from '../../../assets/images/US16-p-500.png';
import US17 from '../../../assets/images/US17-p-500.png';
import US18 from '../../../assets/images/US18.png';
import US19 from '../../../assets/images/US19-p-500.png';
import US71 from '../../../assets/images/US71-p-500.png';
import US72 from '../../../assets/images/US72-p-500.png';
import US73 from '../../../assets/images/US73-p-500.png';
import US74 from '../../../assets/images/US74-p-500.png';
import US76 from '../../../assets/images/US76-p-500.png';
import US75 from '../../../assets/images/US75-p-500.png';

const images = [
  {
    src: US13,
    title: "Wall mural that reads with head"
  },
  {
    src: US14,
    title: "Children read the mural"
  }, {
    src: US15,
    title: "An academy student smiles while drawing an assignment"
  }, {
    src: US16,
    title: "Student at the beach gathering sand for a project"
  }
];
const images1 = [
  {
    src: US17,
    title: "Wall mural that reads with head"
  }, {
    src: US19,
    title: "An academy student smiles while drawing an assignment"
  },
  {
    src: US18,
    title: "Children read the mural"
  }
];
const images2 = [
  {
    src: US71,
    title: "Wall mural that reads with head, heart, and hands, we help the human family"
  },
  {
    src: US72,
    title: "Children read the mural"
  }, {
    src: US73,
    title: "An academy student smiles while drawing an assignment"
  }, {
    src: US74,
    title: "Student at the beach gathering sand for a project"
  }
];
const images3 = [
  {
    src: US76,
    title: "Wall mural that reads with head, heart, and hands, we help the human family"
  },
  {
    src: US75,
    title: "Children read the mural"
  }
];
const FullCircleLearningAcademy = () => {
  const allImages = {
    images,
    images1,
    images2,
    images3
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };

  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className='text_font_line'><strong>Full-Circle Learning Academy</strong></h1>

          <h2 className='mt-4 educator_size'>Teaching Is Learning</h2>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} style={{ height:"400px",objectFit:"cover" }}/>
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='educator_page'
        >
          <p>&nbsp;The infusion of projects in which learners teach the community appears in FCL projects around the
            world. The first pilot program and its consequent FCL Alumni club practiced the concept to its greatest
            extent. In this case. After entering secondary school and seeing how violence affected learning, they
            and their families vowed to start a K-8 charter school in the inner city. The process took years, but
            their recruitment efforts helped fulfill the requirements of the approval process. They later supported
            and encouraged the school, which raised motivation, conflict resolution and academic skills over the
            course of five years, doubled in size and occupied two campuses.&nbsp;</p>
          <p>&nbsp;At an elementary school graduation ceremony one year, one student spoke about the coral reef
            project, in which the class had written to then-President Barack Obama to assist them in their research
            and campaign to mitigate the profound effects of coral reef degradation. Because he hailed from Hawaii,
            they reasoned, he President would assist them in their campaign. One graduate told the audience, “<em>I
              was a terrible writer. I never knew why I should learn. After and revising my letter so many times
              before sending it, now I know!</em>” Here, one classmate worked on a coral reef illustration while
            another analyzed beach pollution.</p>
          <h2 className='educator_size'><strong>&nbsp;Sharing Empathy&nbsp;and Wisdom</strong></h2>
          <p>A learning unit on Empathy led second- and third-graders to make statistical charts concerning the causes
            of homelessness. They resolved hypothetical conflicts. They created art based on the faces of homeless
            people captured in a photography book. Pictured here, they marched at a downtown event to give homeless
            people a face, also writing appeals to their city leaders imploring them to consider the housing
            challenges of the homeless population. At last they wrote to their wisdom exchange partner school in
            India, asking if they had further ideas on empathy for homeless people.<br /></p>
          <p>&nbsp;The Full-Circle Learning Academy in India conducted a project in response. They wrote a letter
            describing the “orphaned grandparents” in their midst as the most impoverished in their community. The
            Indian children made a book of poems and beautiful mosaics from recycled materials. They brought a copy
            to the elders and shared a copy with the Los Angeles students. They had presented food, gifts, and
            “kisses in exchange for blessings,” because the grandparents had no one to bless.</p>
          <p>Teary-eyed, the teachers read the letter to their students in Los Angeles, many of whom lived with their
            grandparents. A silence followed, then hands shot up in the air. The learners suddenly wanted to know
            where they could study the Tamil language and how to qualify to go to college and earn enough to permit
            travel. They had redefined poverty and wanted to gain new wisdom from their brothers and sisters in
            India.</p>
          <p>&nbsp;In another wisdom exchange, middle school students at the Full-Circle Learning Academy integrated a
            study of respect into mathematical surveys, science concepts in genetics, social studies concepts in
            water conservation, and supporting literature and art. They sent their respect quilt to Blessed Vale
            School in Zambia as part of a wisdom exchange. The teacher felt moved that her project-based learning
            had resulted in community change not only nearby but a continent away. For their part, the Zambia
            students did not have desks but expressed increased motivation to learn, based on the exchange.</p>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='educator_page'
        >
          <h2 className="heading-35 educator_size mt-3"><strong>Celebrating Personal and Group Transformation</strong></h2>
          <div className="muli-colmn-tw">
            {images2.map((image, index) => (
              <Card
                className="image-card"
                key={image.title}
                onClick={() => showModalImage(index, "images2")}
                style={{ padding: "6px 0px" }}
              >
                <CardImg src={image.src} style={{ height:"300px",objectFit:"cover" }}/>
              </Card>
            ))}
          </div>
          <p>Parents, teachers and students gathered at culmination activities each year at the Full-Circle Learning
            Academy Los Angeles&nbsp;(Activities pictured here feature 2007 and 2012 events).</p>
          <p>Together, the community annually celebrated ways in which learning had improved life for the local and
            global human family. Below, student spoke about their habits-of-heart and their community building
            projects at mastery ceremonies, where they gave parents certificates for the habit they most admired and
            also&nbsp; performed&nbsp; the FCL music that had enhanced their service-learning field trips.&nbsp;</p>
          <p>Full-Circle Learning Academy Middle school participated in projects such as a visit to an architect to
            study ways to generate equitable options for housing and public services.</p>
          <p>&nbsp;Students pictured below walked a garden where &nbsp; Gandhi’s ashes are buried and presented a Walk
            Around the World presentation for peace. They spoke to guests about the parallels of Martin Luther King,
            Jr. and Gandhi in the effort to promote nonviolent conflict resolution.</p>
          <div className="muli-colmn-tw">
            {images3.map((image, index) => (
              <Card
                className="image-card"
                key={image.title}
                onClick={() => showModalImage(index, "images3")}
                style={{ padding: "6px 0px" }}
              >
                <CardImg src={image.src} style={{ height:"330px",objectFit:"cover" }}/>
              </Card>
            ))}
          </div>
        </Col>


      </Row>
    </Container>
  )
}

export default FullCircleLearningAcademy