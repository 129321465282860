import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import kenya1 from '../../assets/images/kenya1.jpeg';
import kenya2 from '../../assets/images/kenya2.jpeg';
import kenya3 from '../../assets/images/kenya3.jpeg';
import kenya4 from '../../assets/images/kenya4.png';
import kenya5 from '../../assets/images/kenya5.png';
import kenya6 from '../../assets/images/kenya6.png';

const images = [
  {
    src: kenya4,
    title: "Students conducting experiments in the fields."
  }, {
    src: kenya6,
    title: "Sherna Deamer working with a Teacher at Deamer Academy"
  }, {
    src: kenya5,
    title: "A group of school children singing."
  }
];

const Kenya = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Kenya</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="w-richtext">
            <figure className="w-richtext-figure-type-image w-richtext-align-center" style={{
              "width": "50%",
              "margin-left": "auto",
              "margin-right": "auto"
            }}>
              <div><img
                src={kenya1}
                loading="lazy" alt="Kenyan student sitting with a plate of food." /></div>
            </figure>
            <p><strong>A Feast of Hope</strong></p>
            <p>This year, hunger flares with food shortages and rising prices. The Russia-Ukraine war has now
              destroyed crops across Europe’s breadbasket, cutting off grain exports and leaving many Africans
              without enough to eat.</p>
            <p>At Chebilat Primary School in Kenya, a discussion of the challenges and the importance of peace
              prompted one student to suggest putting compassion on the plate—in the form of a project to grow and
              distribute student lunches to more remote schools in the region.</p>
            <p>The Chebilat team of students worked together to grow vegetables in raised beds made with repurposed
              burlap sacks. They soon provided healthy, balanced meals for the young children at Nyamanga Nursery
              School, who thankfully received the gift of compassion (pictured below).</p>
            <p>The project will continue in 2023, with the Chebilat students scheduled to feed six new schools in
              two other counties in January alone.&nbsp;&nbsp;Full-Circle Learning-funded Kenya programs continue
              to grow based on popular demand, opening the door to more wisdom exchanges among the world’s
              children.</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center" style={{
              "width": "50%",
              "margin-left": "auto",
              "margin-right": "auto"
            }}>
              <div><img
                src={kenya2}
                loading="lazy"
                alt="Students giving a thumbs up and showing a sign reading Thank you for the Lunch Program." />
              </div>
            </figure>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                src={kenya3}
                loading="lazy" alt="Students pulling vegetables from a garden." /></div>
            </figure>
          </div>

        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Kenya became the first Full-Circle Learning global wisdom exchange partner for American children in
              the 1990s. While practicing the habit of compassion, the South Los Angeles students wrote to ask
              young Marlou, a Plan International “foster child,” how he could run so far and fast for the family’s
              daily drinking water. Marlou took the letters to his teachers at the Kivva School. Soon the two
              schools began sharing exciting projects together Their first communication led to a scientific
              experiment between the two distant schools, to see whether solar cookers could speed the
              pasteurization process for drinking water. (TheAmerican children made solar cookers from cracker
              boxes, and the Kenyan students conducted experiments and documented the best conditions for
              effective pasteurization.</p>
            <p>Later wisdom exchanges compared agricultural techniques, nutrition, girls’ education opportunities,
              and ways to promote harmony within the home and community.</p>
            <p>A peak experience occurred when the Kivva students were invited to take on the
              <em>Beauty Is What You Do</em> challenge, evaluating what they found
              most precious in their environment and committing to protect it. A resulting piece of art from each
              country would be displayed in San Francisco’s Zeum museum. The Kivva School shared its results with
              the planners in a letter, writing:</p>
            <p><em>Thank you for sending us this challenge based on the song,Beauty is What You Do. We first took
              action to beautify our community with acts of kindness. Next, we cleared the local watering hole
              of donkey manure for all who fetch water there. We then terraced the hillside, so the plants
              would thrive and receive more rain. We held a training for parents, to teach them
              drought-resistant farming. We made pictures of lions from sand and straw, and we sent them to
              the museum.</em>&zwj;</p>
            <p><em>By then it was time to take our song on the road, for we had been invited to go by bus on a
              five-hour drive to Nairobi to sing at the ceremony for the outgoing director of the UN
              Environment Programme. There, we sang inside an art installation that included a tree“on life
              support.” We were honored to sing for Nobel prizewinner Wangari Mathai, who came to meet us
              afterward. It was the most memorable day of our lives! </em><em>At last our project was
                complete. We would have done more,but we had only two weeks!</em> </p>
          </div>
          <div className="rich-text-block w-richtext">
            <p>Other projects in Kenya included food aid during the 2020 pandemic for schools on lockdown. A few
              years earlier, in 2008, Sherna Deamer also trained the staff of the Deamer Academy
              in El Dorat, a school designed “to bring up children who will
              benefit society in the future as part of a community building process.”</p>
            <p>&zwj;</p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Kenya