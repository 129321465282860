import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import India1 from '../../assets/images/India1.png';
import India2 from '../../assets/images/India2.png';
import India3 from '../../assets/images/India3.png';
import India4 from '../../assets/images/India4.png';
import India5 from '../../assets/images/India5.png';
import India6 from '../../assets/images/India6.png';
import India7 from '../../assets/images/India7.png';
import India8 from '../../assets/images/India8.png';
import India9 from '../../assets/images/India9.png';
import India10 from '../../assets/images/India10.png';
import India11 from '../../assets/images/India11.png';

const images = [
  {
    src: India1,
    title: 'A portrait of Nishanth Selvakumar behind a ceremonial dish.',
    class: 'img_class_inline',
    key: 'India1'
  },
  {
    src: India2,
    title: 'A student at his desk building a project',
    class: '',
    key: 'India2'
  },
  {
    src: India3,
    title: 'Students parading outside of their school',
    class: '',
    key: 'India3'
  },
  {
    src: India4,
    title: 'Students standing around a newly planted tree',
    class: 'img_class_inline',
    key: 'India4'
  }
];

const images1 = [
  {
    src: India5,
    title: 'Students on stage performing',
    key: 'India5'
  },
  {
    src: India6,
    title: "Young students on the steps of Ghandi's resting place",
    key: 'India6'
  },
  {
    src: India7,
    title: 'Students in Los Angeles presenting on non-violent strategies',
    key: 'India7'
  },
  {
    src: India8,
    title: 'Students standing in a circle in class',
    key: 'India8'
  },
  {
    src: India9,
    title: 'A teacher sorting through curriculum materials',
    key: 'India9'
  },
  {
    src: India10,
    title: 'lightbox-link-10 w-inline-block w-lightbox',
    key: 'India10'
  },
  {
    src: India11,
    title: 'Students performing by singing and movement',
    key: 'India11'
  }
];


const India = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">India</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p> A young man, 24 year-old Nishanth Selvakumar, lost his life in a car accident in January 2005, while
              working in California. </p>
            <p> This tragic event brought heartache to his family, friends and coworkers, as he was known by so many
              for his loving presence and formidable character.</p>
            <p>Nishanth’s mother had always committed her efforts to educating and helping the children of her
              community. Now the only way she could ease the pain of her loss was to turn her immense heartache
              into an effort to help children rise to greater levels of achievement and civic service. </p>
            <p> Unwilling to let tragedy bring an end to this son’s influence, the family wanted to extend his
              goodwill by helping other young people become like Nishanth. His father and mother, Selva andShanti,
              set up an educational family trust and renovated the family property beside where Nishanth grew up
              to prepare to open the first educational project in their son’s name, in Chennai, India.The school
              was located on the family’s property, Nishtanth’s childhood home as well as his resting place. </p>
            <p> Kavian Maghzy, the boy’s former employer, promised to facilitate the training of teachers. As a
              supporter of Full-Circle Learning, hearranged a collaboration between Shanti and the board, to
              provide curricula, professional development services, and collaboration on how to launch a school.
            </p>
            <p> The project’s first class of students learned to infuse love, respect and sacrifice into their
              studies. They applied the lessons of national hero Gandhi into their application of academic and
              artistic skills to improve their community. </p>
            <p>The Year of Love project in Chennai, India began when teacher trainer Anisa Qualls presented the
              children with a glass of water, an ice cube and a cloud. The class discussed what each of these has
              in common. (They are all made of water.) They observed the special properties of water and
              demonstrated how molecules stick together even as water changes from solid to liquid to gas.Students
              built water molecules from tinker toys.</p>
            <p> Anisa used the molecular structure of water (the bonding of hydrogen and oxygen) as a metaphor for
              love: We show our love in many ways. It can take the form of words, affection or service.Love is a
              bond that holds people together, just as molecules hold water together to form ice cubes, clouds or
              oceans. Like a drink of cool water each day, love keeps us alive and growing. </p>
            <p> The principal, Raji, and the teachers at<br />the school, went on to hold many activities that helped
              the children express love for each other and for their community. They discussed ways to conserve
              clean drinking water and to keep their families’ water supplies clean. They held a march for clean
              water and air in their community, to remind others about the life-sustaining importance of these
              principles. </p>
            <p> Next, they talked about ways to show love by developing qualities such as selflessness, generosity
              and inner beauty. On Founder’sDay, a five-day community holiday, they held a festival and invited
              everyone. They celebrated the occasion with Full-Circle Learning songs about each of these
              qualities. They enacted role plays to show the community how to treat others. They demonstrated how
              to use the conflict bridge to resolve difficulties. They held storytelling sessions. After cleaning
              up their water supplies, they held meetings and provided examples to teach their community the idea
              that “Beauty is caring. Beauty is helping. Beauty is loving.Beauty is what you do. “ </p>
            <p> After five years, local officials honor the Selvakumar family for the changes in education and in
              community life brought about by these changes, through the Nishanth Full-Circle LearningAcademy.</p>
            <p> As a tuition-free school for children of servants, the school could still convince children their
              services were needed.Below, left, they learned how to plant trees and taught orphan show to beautify
              their own surroundings. </p>
            <p> Children in each grade level presented issues they wanted to change in society at a community
              program.These children sang about communal harmony. </p>
            <p> Each year on Gandhi’s birthday, learners went to his resting place to advocate for peace.Some
              reenacted the life choices of Gandhi. </p>
            <p> Wisdom exchange partners at the Full-Circle Learning Academy in Los Angeles performed a simultaneous
              program, comparing the nonviolent strategies of Gandhi and Dr. Martin Luther King. </p>
            <p>Teachers selected books that reinforced theFull-Circle Learning curricular approach, customizing for
              context and culture.&ZeroWidthSpace; &ZeroWidthSpace;Teachers experienced the benefits of
              action-based learning over rote memorization alone.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

export default India