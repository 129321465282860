import React from 'react'
import {
  Row, Col, Container
} from "reactstrap";

const Congo = () => {
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Congo</h1>
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Teachers at schools in the Congo and in Gabon participated in training sessions in 2018-2019.
              Potential regional leaders continue to study the model online. A project in the works at this
              writing would create wisdom exchange opportunities between the Congo, Hungary, and Chad.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>


  )
}

export default Congo