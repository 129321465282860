import React from 'react'
import f_image2 from '../../assets/images/f_image2-p-500.jpeg';
import {
    Row, Col, Container
} from "reactstrap";
const Fclonline = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Row>
                        <Col sm="6" className='d-flex justify-content-center my-2'>
                            <div className="">
                                <img src={f_image2} loading="lazy" width="300" sizes="(max-width: 479px) 100vw, 300px" alt="A woman holds up a t-shirt saying, She believed she could change the world so she became a teacher" className="image-49" />
                            </div>
                        </Col>
                        <Col sm="6" className='my-2'>
                            <div className="link_hov">
                                <h2 className='fs-1'>Believe You Can!</h2>
                                <p>Sign up for a Full-Circle Learning online course, as a school or as an individual. Participants are entitled to all FCL materials. Visit: <a href="http://fullcirclelearningcourses.teachable.com" className="link-17">fullcirclelearningcourses.teachable.com</a></p>
                                <p>Contact <a href="mailto:info@fullcirclelearning.org" className="link-16">info@fullcirclelearning.org</a> to inquire about leading a group or to see if you qualify for free or discounted training.</p>
                                <p>Or support schools in the developing world with your tuition as a paying participant. (All proceeds directly benefit Full-Circle Learning's community impact grants.)</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default Fclonline