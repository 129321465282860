import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US20 from '../../../assets/images/US20.png';
import US21 from '../../../assets/images/US21.png';

const images = [
    {
        src: US20,
        title: "Wall mural that reads with head"
    },
    {
        src: US21,
        title: "Children read the mural"
    }
];

const HuntingtonHotel = () => {
    const allImages = {
        images
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = allImages[arrayName];
        if (selectedArray) {
            toggleModal();
            setCurrentIndex(selectedArray[imageId]);
        }
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className='text_font_line'>Huntington Hotel FCL&nbsp;School</h1>

                    <h2 className='mt-4'>Honoring the Potential of All</h2>

                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" ,height:"100%"}}
                        >
                            <CardImg src={image.src} style={{ height:"100%"}}/>
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className="mt-2"
                >
                    <p>Huntington Hotel requested a Full-Circle Learning school for homeless students. Those who participated
                        later became members of the Alumni Club. Here, a board member joins them in singing carols to their
                        adopted grandparents at a senior care facility, Longwood Manor. For many years prior, visits to this
                        inner-city facility incorporated the theme of the learning unit. For example, they interviewed seniors
                        about their origin during a unit on Sacrifice. The students later returned with a “newspaper” about each
                        of the elders and the ways their generation had practiced the habit of sacrifice during the Great
                        Depression.&nbsp;</p>
                    <p>Visits to adopted grandparents became a standard feature of Full-Circle Learning programs, including the
                        long-standing program in Fillmore, California, Rancho Sespe.</p>
                </Col>


            </Row>
        </Container>
    )
}

export default HuntingtonHotel