import React from 'react'
import {
  Row, Col, Container
} from "reactstrap";

const Senegal = () => {
  return (
    <Container>

      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Senegal</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p> A United Nations Children’s Day project in the late 1990s resulted in a wisdom exchange between
              students in Senegal and SouthLos Angeles. </p>
            <p> The Los Angeles students had spent a year studying various ways to act on their convictions. After
              interviewing a farmer about the patience needed to grow food, they witnessed vast economic
              disparities on field trips to both lush farmland and urban food deserts. Their resulting hunger
              projects spanned multiple habit-of-heart units and helped them understand the complexity of hunger
              issues, both local and global. They had helped the farmer harvest pumpkins, which they used to make
              pies for a homeless hotel. They studied the healthy-heart benefits of various plants and compared
              their pea plants with the cassava grown by African students in their community gardens. They also
              learned about the causes of global hunger, such as per capita income, loss of grain silos during
              extreme weather, and apartheid restrictions that had limited the skills of new farmers who suddenly
              could own land. </p>
            <p> The service learning projects throughout the year touched many lives. The students raised money for
              world hunger. They made lunches every day for a local feeding program serving the homeless
              population. Their service over the year also addressed environmental and other issues. </p>
            <p> The following October, when the students were asked to speak onUnited Nations Day, each one shared a
              letter about their deep convictions, and how service had changed their own lives and the lives of
              others. </p>
            <p> The partner NGO, Plan International, sent the letters along with a motorcyclist touring Senegal.
              They urged him to offer the convictions of the children as gifts to new wisdom exchange partners in
              a Senegalese village. </p>
            <p> After studying the letters about how to act on convictions, theSenegalese school leaders and
              students felt inspired and energized to infuse purpose into their own learning. They divided </p>
            <p> the campus into multiple schools. Each school would act based on a theme, conducting projects
              related to the conviction described in a particular student letter. </p>
            <p> This project so transformed the life of the community that village leaders baptized the motorcyclist
              as a member of their tribe.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Senegal