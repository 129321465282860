import React from 'react'
import tshirt_motto from '../../assets/images/t-shirt-motto-cameroon-p.jpg';
import {
  Container, Row, Col
} from "reactstrap";

const index = () => {
  return (
    <Container className='mb-4 educator_page'>
    <Row className='justify-content-center'>
      <Col
        sm="12"
        md={{
          size: 10
        }}
      >
        <div>
          <img src={tshirt_motto} alt="Two teachers showing the motto on their back." />
        </div>
        <p className="paragraph-53">The FCL motto inspires teachers as role models, committed to a coherent common vision about the potential of learning to inspire action.</p>
        <Col
        sm="12"
        md={{
          size: 10,
          offset: 1,
        }}
      >
        <Row className="d-flex justify-content-center align-items-center flex-wrap">
          <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-3">
            <a href="/educator/fcl-online-course-link" className="rounded-link w-button">FCL Online Course</a>
          </Col>
          <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-3">
            <a href="/educator/teacher-resources" className="rounded-link w-button">Teacher Resources</a>
          </Col>
          <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-3">
            <a href="/educator/curricula" className="rounded-link w-button">Curricula</a>
          </Col>
          <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-3">
            <a href="/educator/songs" className="rounded-link w-button">Songs</a>
          </Col>
          <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-3">
            <a href="/educator/staff-development-outlines" className="rounded-link w-button">Staff Development Outlines</a>
          </Col>
          <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-3">
          </Col>
        </Row>
        </Col>

        <Row>
          <h2 className="heading-52 my-4 fw-bold educator_size inquire_font">School-Generated DL Units</h2>
          <Col
        sm="12"
        md={{
          size: 10,
          offset: 1,
        }}
      >
          <Row>
            <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-2">
              <a href="/learner/resiliency-unit" className="rounded-link w-button">Resiliency</a>
            </Col>
            <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-2">
              <a href="/learner/empathy-unit" className="rounded-link w-button">Empathy</a>
            </Col>
            <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-2">
              <a href="/learner/honor-unit" className="rounded-link w-button">Honor</a>
            </Col>
            <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-2">
              <a href="/learner/helpfulness-unit" className="rounded-link w-button">Helpfulness</a>
            </Col>
            <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-2">
              <a href="/learner/forgiveness-unit" className="rounded-link w-button">Forgiveness</a>
            </Col>
            <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-2">
              <a href="/learner/unity-unit" className="rounded-link w-button">Unity</a>
            </Col>
            <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-2">
              <a href="/learner/love-unit" className="rounded-link w-button">Love</a>
            </Col>
            <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-2">
              <a href="/learner/care-skills-course" className="rounded-link w-button">Care Skills Course</a>
            </Col>
            <Col sm="4" className="d-flex justify-content-center align-items-center flex-wrap my-2">
            </Col>
          </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    </Container>
  )
}

export default index
