import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Guyana1 from '../../assets/images/Guyana1.png';

const images = [
  {
    src: Guyana1,
    title: "Two teachers in Guyana"
  }
];

const Guyana = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Guyana</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
            <div className="rich-text-block-2 color-link w-richtext">
              <p><em>Our Place in Time</em> challenged students around the world to learn about other
                parts of the globe, to describe the distinctions of their own environments, and to commit to
                improving life in their regions. </p>
              <p>Students gathered to study, to create art and to write collaborative poetry that would prompt their
                community actions, based on the unique perspectives of their communities and the challenges they
                strived to address. </p>
              <p>Upper-division students from School of the Nations teamed up in class to write the sample poems first
                sent to other nations. The breadth of their concerns inspired ideas in other regions. In2018-18, a
                number of integrated education projects sprang up around the world, in service to the community,
                inspired by local student poetry and the examples written at Guyana’s School of the Nations. </p>
              <p> A few examples of those poems appear on the following page. </p>
              <p> Below, two Guyanese teachers offer a classroom tour.</p>
            </div>
            <div className="rich-text-block w-richtext">
              <p>As the sun cascades down the water, it falls with a force.A symbol that represents the strength of
                togetherness.<br />I, as a member of that unity, would forever stand with it. </p>
              <p><em>School of the Nations – Guyana Marish Singh, Annalisa Majid &amp; Zenefa Mohamed</em></p>
              <p>Wake up, said the sun kissing the skyUnity will prevail, let us all arise<br />The morning is here, I
                will overcome </p>
              <p><em>School of the Nations – Guyana Kahna Mathann &amp; Ambika Deol</em></p>
              <p>A blossoming heart<br />Hatred rumbling, crackling, trying to intrudeBut soar is what my voice will
                do,<br />and forward is how my feet will move </p>
              <p><em>School of the Nations – Guyana Haema Dasrath</em></p>
            </div>
          </Col>




      </Row>
    </Container>
  )
}

export default Guyana