import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Sudan1 from '../../assets/images/Sudan1.png';
import Sudan2 from '../../assets/images/Sudan2.png';
import Sudan3 from '../../assets/images/Sudan3.png';

const images = [
  {
    src: Sudan1,
    title: 'Three students looking over materials.'

  },
  {
    src: Sudan2,
    title: "A drawing of peace between North and South Sudan."
  },
  {
    src: Sudan3,
    title: "Another drawing with words reading I show love by acknowledging other people's accomplishments."
  }
];

const SouthSudan = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">South Sudan</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Membership in one human family means share a global commitment to peace and well-being.Learners at
              SANAD, inOrange County, California, reached out to others at their Saturday School for the
              preservation ofSudanese culture. </p>
            <p>They used the humanity circle as their daily introduction, sharing insights and examining ways to
              meet the needs of others.</p>
            <p>For example, during a unit on empathy, they assessed the statistical needs of their city’s homeless
              community before conducting a related service-learning field trip.</p>
            <p>During a unit on love, their concern over the war in Sudan led them to conduct a conflict resolution
              wisdom exchange to share messages of peace and hope with students in South Sudan.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default SouthSudan