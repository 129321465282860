import React from 'react'
import {
    Container, Row, Col
} from "reactstrap";

const EmpathyUnit = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1">Empathy</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Row className="">
                        <Col sm="12" className="">
                            <h2>Introduction</h2>
                            <p>Enjoy relevant, purposeful learning. Follow the steps of the Full-Circle Learning model through a series of activities applicable for school or home use. Lesson plans include links for broadcast or online classrooms as well as print options for classrooms or homes without technology.</p>
                            <a style={{ display: "none"}} href="/" className="download-button w-dyn-bind-empty w-button">.1</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Outline</h2>
                            <p>Teachers, parents or self-guided students can use this document to preview the elements and assignments to include based on available technologies.</p>
                            <a 
                                target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-outline.pdf" 
                                className="download-button w-button">
                                    Download
                            </a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 1 The Aroma of Empathy</h2>
                            <p>Secondary Students contemplate the value of empathy through the eyes of someone who shares the gift of rice.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-one.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-video w-embed inline_style_pading">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FKpqEPN1J9n8%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DKpqEPN1J9n8&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FKpqEPN1J9n8%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="640" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 2 Empathy on the Conflict Bridge</h2>
                            <p>Students prepare to resolve conflicts that arise in sharing, serving and growing food.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-two.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 3 Storytell It: Seeing Others as One Family</h2><p>Through videos and by reading and writing literature, students examine the value of seeing the world through the eyes of another.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-three.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 4 Protecting the Rice Plant and the Planet</h2>
                            <p>Videos help students balance environmental concerns with the need for agricultural yields and make recommendations to regional growers.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-four.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2"><div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FyjXAvNaSOWA&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DyjXAvNaSOWA&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FyjXAvNaSOWA%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 5 Empathy Heals</h2>
                            <p>Students compare the role of empathy in addressing pain as well as hunger pangs. They learn health care skills for treating Covid-19 through hands on video and print lessons and they create a lesson to teach others.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-five.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 6 Making Music to Promote Empathy</h2>
                            <p>A student composer shares her song. Students learn it to enhance their projects.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-six.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fv7-rcJAxdr4%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dv7-rcJAxdr4&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fv7-rcJAxdr4%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 7 Rice Poem Activities</h2>
                            <p>Students present poetry on video. Students discuss the messages. They tally rice imports to enhance the themes of their own poems.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-seven.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FN6Du_7AX-U4%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DN6Du_7AX-U4&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FN6Du_7AX-U4%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 8 Share Empathy at Home</h2>
                            <p>Seeing through the eyes of family members, students anticipate and act on the specific needs of family members.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-eight.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 9 Send Face Masks to Prevent Covid-19</h2><p>Students make face masks for delivery to essential workers and to loved ones, to promote community prevention of infectious disease.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-nine.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FanwBuTgUw-E%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DanwBuTgUw-E&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FanwBuTgUw-E%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 9-B Knowledge as a Tool</h2>
                            <p>Collect one of the best antidotes for curing disease: knowledge. This step will show you how.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-9B.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FdK_DSXfil20%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DdK_DSXfil20&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FdK_DSXfil20%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F7ar5uiau8Qw&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D7ar5uiau8Qw&amp;image=http%3A%2F%2Fi.ytimg.com%2Fvi%2F7ar5uiau8Qw%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 10 Journaling to Sustain Empathy</h2><p>Students follow up with daily writing activities to practice the good habits they’ve developed.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-ten.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2>Element 11 Glossary</h2>
                            <p>Readers can preview and review terms not defined in the reading material.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/empathy/empathy-element-glossary.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-2">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default EmpathyUnit