import React, { useContext, useEffect } from "react";
import { SuccessContext } from "./helpers/SuccessContext";
import { Alert, Container } from "reactstrap";

const SuccessPage = () => {
  const { success, setSuccess } = useContext(SuccessContext);

  useEffect(() => {
    if (success) {
      // Reset success state after displaying the message
      setTimeout(() => setSuccess(false), 5000); // Clear the message after 5 seconds
    }
  }, [success, setSuccess]);

  return (
    <Container className="full">
      {success && <Alert color="success">Payment succeeded! Thank you!</Alert>}
      <Alert color="success">Thank you for your donation!</Alert>
    </Container>
  );
};

export default SuccessPage;
