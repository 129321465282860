import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US59 from '../../../assets/images/US59-p-500.png';
import US61 from '../../../assets/images/US61-p-500.png';
import US62 from '../../../assets/images/US62-p-500.png';
import US60 from '../../../assets/images/US60-p-500.png';
import US63 from '../../../assets/images/US63.png';
import US64 from '../../../assets/images/US64-p-500.png';
const images = [
    {
        src: US59,
        title: "Young students playing a game with a ribbon grouping them together"
    },
    {
        src: US61,
        title: "Students outside passing cups behind them while standing in a line"
    }, {
        src: US62,
        title: "Students holding hands in a large group game"
    }, {
        src: US60,
        title: "A student demonstrates a project to other students"
    }
];
const images1 = [
    {
        src: US63,
        title: "A teacher helps a student with measuring"
    },
    {
        src: US64,
        title: "Students use letter blocks in their project"
    }
];

const ClimateChange = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = arrayName === 'images' ? images : images1;
        toggleModal();
        setCurrentIndex(selectedArray[imageId]);
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1>&nbsp;Climate Change Agents Camp, Nevada County</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} style={{ height:"400px",objectFit:"cover" }}/>
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    <h2><strong>&nbsp;Climate Change Agents in California</strong></h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>A weeklong overnight camp to integrate habits-of-heart, research and assistance with climate impacts near
                        and far began in 2014. Nevada County Climate Action Now (NC-CAN) launched the camp and co-sponsors it
                        with Full-Circle Learning.</p>
                    <p>Above, students practiced cooperation skills in 2014. Below, Zach and Kaiden applied architectural skills
                        to an ethical Village in 2016.</p>
                    <p>Climate Change Agents studied water issues in 2017.</p>
                    <p>Projects included simulations of a watershed, watershed management studies and clearing a flood plain,
                        among other things. At right, Jessica Rivenes examined the engineering of a permeable sidewalk.&nbsp;
                    </p>
                    <p>Through annual themes linking a climate change impact to a life skills goal, the Change Agents, by 2020,
                        had helped their community and those of wisdom exchange partners apply local and global solutions to the
                        impacts of drought, food insecurity, sea rise, weather extremes, wildfire, and habitat loss. They also
                        helped a community shift toward renewable energies. Their integrated service-learning projects aimed at
                        community transformation, both local and global. Multiple environmental organizations help fund the
                        camp, which is completely scholarship-based.&nbsp; While addressing the impacts of wildfire in the
                        photos above, students used trigonometry to conduct a carbon count and practiced bridging conflicts
                        among forest managers. They also taught the public how to manage PTSD after a fire. Below, they prepared
                        elements of a League of Species project.&nbsp;</p>
                    <p>These photos feature the significance of “wisdom exchanges,” with Ana Maria assisting Pax with his
                        clinometer, Jamie Ervin leading forestry activities, and Vanuatuan scholarship recipient Rossana Kalulu
                        joining a project after offering creative water catchment options from her country.</p>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images1.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images1")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} style={{ height:"500px",objectFit:"cover" }}/>
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default ClimateChange