import React from 'react'
import {
  Row, Col, Container
} from "reactstrap";

import uganda1 from '../../assets/images/uganda1.png';
import uganda2 from '../../assets/images/uganda2.png';
import uganda3 from '../../assets/images/uganda3.png';

const Uganda = () => {
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Uganda</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="w-richtext">
            <h2>Poultry Growing in Uganda</h2>
            <figure className="w-richtext-align-center w-richtext-figure-type-video inline_style_elSalvador" style={{
              "padding-bottom": "109.23076923076923%",
              "position": "relative"
            }}>
              <div><iframe allowFullScreen
                src="https://player.vimeo.com/video/777878505"
                title="VIDEO-2022-11-16-21-10-30 duck project" style={{
                  "height": "100%",
                  "width": "100%",
                  "position": "absolute"
                }}></iframe></div>
            </figure>
            <p>Uganda’s Nakivale Refugee Camp is grooming a generation attending school for the first time, engaging
              the arts, and conducting community building efforts such as a permaculture garden&nbsp;to feed the
              population. This boy appeared in a video in fall of 2022 to answer questions about
              his&nbsp;initiative, motivated by a desire to reduce hunger and help the community.</p>
            <p>&zwj;</p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                alt="my alt"
                src={uganda1}
                loading="lazy" /></div>
            </figure>
            <h2><strong>April 2022: Joy and Unity in Nakivale Refugee Camp</strong></h2>
            <p>Children in Uganda’s Nakivale refugee camp have been displaced from school and detached from their
              dreams. The world’s eighth largest refugee settlement stretches out across 78 villages, blooming for
              half a century in the dusty earth 200 km from Kampala, near the Tanzanian border.</p>
            <p>Last summer, an NGO called Now and Tomorrow requested our help in transforming life for the refugee
              families, and especially for 345 displaced primary school aged students.&nbsp; Soon after embracing
              the Full-Circle Learning (FCL) philosophy, the camp’s Soma! Soma! (school-readiness) teachers
              rallied discouraged parents and instilled in children a renewed desire to learn, along with tangible
              skills in character, conflict resolution, art, and applied service learning that would transform the
              camp.&nbsp; Teachers at a dozen schools surrounding the camp received the same FCL training and
              mentorship, to better welcome the refugee children.</p>
            <p>One of the schools, Arise, soon presented a unit on the
              habit-of-heart&nbsp;<em>Love.</em>&nbsp;Children mastered it as they applied permaculture techniques
              to feed a community of 50. They learned to construct sack mounds filled with topsoil and stones to
              ease the watering of plants, pictured above.</p>
            <p>Today, just six months after the first Full-Circle Learning workshops began, the 345 primary students
              “are fully motivated to pursue their educational careers and their dreams in life, as some of them
              aspire to be doctors, lawyers, teachers, law makers, social workers and more,” wrote FCL Ugandan
              facilitator Fiston Muganda. He continued:&nbsp;</p>
            <p><em>The elements of joy and unity have been observed as children arrange to go to school. Since
              schools that they enrolled into after graduating from Soma! Soma! are a bit distant, the
              children have organized various teams, staying in the same neighborhood to assist in mobilizing
              the rest of the children as they go to school early in the morning, in these teams.&nbsp;</em>
            </p>
            <p><em>They go sharing stories and singing while holding the youngest kids’ hands. Such a mechanism has
              brought about joy and has definitely enhanced the bonds among the pupils... Community members
              feel so indebted and appreciate the work of Full Circle Leaning in their community, especially
              the concept of Art as a service and the habits of heart in conflict resolution.</em></p>
            <p>[Below, students display artwork and Soma! Soma! teachers gather at a workshop.]</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                alt="my alt"
                src={uganda2}
                loading="lazy" /></div>
            </figure>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                alt="my alt"
                src={uganda3}
                loading="lazy" /></div>
            </figure>
          </div>

        </Col>
        <div className="section-6">
          <div className="w-container">
            <div className="rich-text-block-2 color-link w-dyn-bind-empty w-richtext"></div>
            <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
          </div>
        </div>



      </Row>
    </Container>
  )
}

export default Uganda