import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  Col,
  Row,
  Card,
  CardImg
} from 'reactstrap';

import ImageCarousel from './ImageCarousel';

import Liberianlearners2 from '../../assets/images/Liberianlearners2.png';
import photographed from '../../assets/images/Bong-- girls photographed with sheep.jpeg';
import batik_scaled from '../../assets/images/students-model-batik-scaled.png';
import gardening_chad from '../../assets/images/children-gardening-chad.jpg';
import e_nigeriahealth from '../../assets/images/e_nigeriahealth-p-500.jpeg';
import Dahn_students from '../../assets/images/Dahn-students-bridge-p-500.png';
import food_cycle from '../../assets/images/food-cycle-p-500.png';
const images = [
  {
    id:1,
    src: Liberianlearners2,
    title: "img alt"
  },
  {
    id:2,
    src: photographed,
    title: "img alt"
  },
  {
    id:3,
    src: batik_scaled,
    title: "img alt"
  },
  {
    id:4,
    src: gardening_chad,
    title: "img alt"
  },
  {
    id:5,
    src: e_nigeriahealth,
    title: "img alt"
  },
  {
    id:6,
    src: Dahn_students,
    title: "img alt"
  },
  {
    id:7,
    src: food_cycle,
    title: "img alt"
  }
];
const ImageGallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId) => {
    toggleModal();
    setCurrentIndex(imageId);
  };
  return (
    <div>
      {images.map((image, index) => (
          <Card 
            className="image-card" 
            key={image.id}
            onClick={() => showModalImage(index)}
            style={{ padding: "6px 0px"}}
          >
            <CardImg src={image.src} />
          </Card>
      ))}
      <Modal
        className="modal-xl"
        isOpen={isModalOpen}
        toggle={toggleModal}
      >
        <ModalBody>
          <Row>
            <Col md="12">
              <ImageCarousel images={images} currentIndex={currentIndex} />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ImageGallery
