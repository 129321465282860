import React, { useState } from 'react'
import { Card, Row, Col } from "reactstrap";
import { locations } from '../../utils/constant';
import addworld from '../../assets/latest/604af88c7373be692ef9b359_menu-icon.png'
const Gmap = () => {
  const [isHovered, setIsHovered] = useState(null);
  const handleHover = index => {
    setIsHovered(index);
    const item = document.getElementById(`location-${index}`);
    item.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
    <Card className="">
      <img
        src={addworld}
        loading="lazy"
        sizes="100vw"
        alt="Map of the world with locations of full-circle learning sites"
        className="image-3"
      />
      <Row className="collection-wrapper">
        <Col className="collection-list">
          {locations.map((location, index) => (
            <div key={location.name} className="collection-item w-dyn-item">
              <div className="html-embed w-embed">
                <a
                  href={location.url}
                  className="link-block-3 map_dot w-inline-block"
                  style={{ marginTop: location.marginTop, marginLeft: location.marginLeft }}
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={() => setIsHovered(null)}
                >
                  <p
                    id={`location-${index}`}
                    className="paragraph-3 map_location"
                    style={{
                      display: isHovered === index ? 'block' : 'none',
                      opacity: isHovered === index ? 1 : 0,
                      transform: isHovered === index ? 'translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style' : 'none',
                      transformStyle: 'preserve-3d'
                    }}
                  >
                    {location.name}
                  </p>
                  <div className="map_circle" style={{ width: "1em" }}>
                    <div
                      className="map_fill"
                      style={{
                        opacity: isHovered === index ? '0.24' : 1,
                        transform: isHovered === index ? "translate3d(0px, 0px, 0px) scale3d(1.5, 1.5, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)" : "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </Col>
      </Row>
      {/* <a href="/" className="link-block-3 map_dot hidden-visually w-inline-block">
          <p
            className="paragraph-3 map_location"
            style={{
              display: "none",
              opacity: "0",
              transform: "translate3d(50%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
          >
            Location Name
          </p>
          <div className="map_circle">
            <div className="map_fill"></div>
          </div>
        </a> */}
    </Card>
    <Row>
    <Col
      sm="12"
      md={{
        offset: 2,
        size: 8
      }}
      className='mt-4 d-flex justify-content-center'>
      <p className="fs-2">
        <a
          rel="noreferrer"
          target='_blank'
          href="https://fullcirclelearningassets.s3.amazonaws.com/Full-Circle-Learning-Annual-Report-2023.pdf"style={{ color:"#0645ad" }}
        >
          <strong className="bold-text-8">See the most recent community impact report!</strong>
        </a>
      </p>
    </Col>
  </Row>
  </>

  )
}

export default Gmap
