import React, { useEffect, useState } from "react";
import {
  Row, Col, Container, Form, Label, Input, Button
} from "reactstrap";
import { useLocation } from "react-router-dom";
import { allContent } from "../../utils/StaticContent";

const SearchResult = () => {
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search).get("query");
  const siteUrl = window.location.origin;
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [value, setValue] = useState('')
  const handleChange = (e) => {
    console.log(e);
    setValue(e.target.value);
  }

  const TruncatedText = ({ text }) => {
    const regex = new RegExp(query, "gi");
    let boldedContent = text.replace(regex, "<strong>$&</strong>");

    const words = boldedContent.split(' ');
    const truncatedText = words.slice(0, 40).join(' ');
    const displayText = words.length > 40 ? `${truncatedText} ...` : truncatedText;

    return <p dangerouslySetInnerHTML={{ __html: displayText }} />;
  };

  useEffect(() => {
    setQuery(queryParam);
    setValue(queryParam)

    const filteredResults = allContent
    .filter(page => {
        // Check if either the name or the content includes the query
        const contentMatch = page.content.toLowerCase().includes(query.toLowerCase());
        const nameMatch = page.name.toLowerCase().includes(query.toLowerCase());
        return contentMatch || nameMatch;
    })
    .map(page => {
        const sentences = page.content.split(/(?<=[.!?])\s+(?=[A-Z])/);
        const filteredSentences = sentences.filter(sentence =>
            sentence.toLowerCase().includes(query.toLowerCase())
        );
        const result = filteredSentences.join(' ');
        return {
            name: page.name,
            page_url: page.page_url,
            content: result
        };
    });
    
    const firstTenResults = filteredResults.slice(0, 10);

    setSearchResults(firstTenResults);
  }, [query, queryParam]);

  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 fs-1">Search results</h1>
          <Form action="/search" className="search">
            <Label htmlFor="search-2" className="field-label">Search</Label>
            <Input
              className="search-input"
              maxLength="256"
              name="query"
              placeholder="Search…"
              type="search"
              id="search"
              required=""
              value={value}
              onChange={handleChange}
            />
            <Button
              type="submit"
              color="primary"
              className="download-button search-button"
            >
              Search
            </Button>
          </Form>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          {searchResults.length > 0 ?
            searchResults.map((searchResult) => (
              <div
                key={searchResult.name} >
                <a href={searchResult.page_url}>{searchResult.name}</a>
                <p>{siteUrl}{searchResult.page_url}</p>
                <TruncatedText text={searchResult.content} />
              </div>
            ))
            :
            <p>No matching results.</p>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default SearchResult
