import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";
const WhereWeServe = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1 fw-bold">Where We Serve</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>Full-Circle Learning has served participants from 40 nations on five continents:</p>
                    <p>Afghanistan, Australia, Cameroon, Chad, China, Colombia, Congo, Costa Rica, Bolivia, Brazil, Ecuador, El Salvador, Ethiopia, the Gambia, Ghana, Guatemala, Guyana, Haiti, India, Ian, Israel, Japan, Kenya, Lesotho, Liberia, Mexico, Navajo Nation, Nigeria, Norway, Panama, Papua New Guinea, Senegal, South Africa, South Sudan, Tanzania, Tonga, Uganda, United States, Vanuatu, and Zambia. (See map stories on the home page.)</p>

                </Col>
            </Row>
        </Container>
    )
}
export default WhereWeServe