import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US50 from '../../../assets/images/US50-p-500.png';
import US53 from '../../../assets/images/US53-p-500.png';
import US51 from '../../../assets/images/US51-p-500.png';

const images = [
    {
        src: US50,
        title: "Wall mural that reads yy with head"
    },
    {
        src: US53,
        title: "Children read thet6 mural"
    },
    {
        src: US51,
        title: "Children read the56 mural"
    }
];
const SpurgeonSchoolFclProgram = () => {
    const allImages = {
        images
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = allImages[arrayName];
        if (selectedArray) {
            toggleModal();
            setCurrentIndex(selectedArray[imageId]);
        }
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1>Spurgeon School FCL&nbsp;Program</h1>
                    <h2>Revelance Across the Curriculum in Santa Ana</h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} />
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>After their school received professional development training, sixth-graders at Spurgeon Middle School
                        devised a class Code of Ethics. They compared the ethnical needs of the present with those of ancient
                        civilizations, from&nbsp; Paleolithic to Mesopotamian societies.</p>
                    <p>&nbsp;Santa Ana was the site for this training of professional development coaches. Other&nbsp;training
                        workshops occurred at local venues including integrated math/science courses at Chapman University.</p>
                    <p>Student teachers incorporated Full-Circle Learning into science classes at King Elementary School.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default SpurgeonSchoolFclProgram