import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US35 from '../../../assets/images/US35-p-500.png';
import US36 from '../../../assets/images/US36.png';
import US39 from '../../../assets/images/US39.png';
import US40 from '../../../assets/images/US40.png';
import US41 from '../../../assets/images/US41-p-500.png';
import US37 from '../../../assets/images/US37-p-500.png';
import US38 from '../../../assets/images/US38.png';

const images = [
    {
        src: US35,
        title: "Wall mural that reads yy with head"
    },
    {
        src: US36,
        title: "Children read the56 mural"
    }
];
const images1 = [
    {
        src: US39,
        title: "Wall mural that 39 with head"
    },
    {
        src: US40,
        title: "Wall mural that 56 with head"
    }, {
        src: US41,
        title: "An academy student 4kjk5 while drawing an assignment"
    },
    {
        src: US37,
        title: "Children read the mu 2888 jhj5ral"
    },
    {
        src: US38,
        title: "Children read the mu 28ral"
    }
];

const TarzanaElementary = () => {
    const allImages = {
        images,
        images1
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = allImages[arrayName];
        if (selectedArray) {
            toggleModal();
            setCurrentIndex(selectedArray[imageId]);
        }
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1>Tarzana Elementary: Schoolwide Professional Development and Habits-of-Heart Club</h1>

                    <h2>&nbsp;<strong>A Legacy of Relevant Learning</strong></h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} style={{ height:"400px",objectFit:"cover" }}/>
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>Tarzana Elementary School’s Habits-of-Heart Club has changed lives since 2001, supported the first few
                        years through Full-Circle Learning’s fundraising efforts and then through the Mona Foundation and the
                        Parent Teachers Association. The principal, Dr. Vicki Lee, shepherded the program for many years, until
                        her retirement, and it continues despite pandemic conditions. A list of teachers made their mark, along
                        with parent volunteer Consuelo Banderas.&nbsp;</p>
                    <p>&nbsp;The school serves as home to new immigrants from more than 40 countries. Over the years, Tarzana
                        students have addressed the needs not only of local citizens in their diverse community but also people
                        around the world. Above, students applied the habit of Unity to a service-based agricultural project. A
                        few long-term highlights include:&nbsp;</p>
                    <ul>
                        <li>A student-led handwashing campaign during a virulent influenza outbreak</li>
                        <li>Consequent pre- and post-Ebola projects in West Africa, with preventive health and education across
                            many school communities</li>
                        <li>Projects connecting learners in wisdom exchanges with China</li>
                        <li>The study of internment camps, which culminated in a unity garden and presentations with visiting
                            scholars from Japan</li>
                        <li>Wisdom exchanges with indigenous peoples, local and global</li>
                        <li>Habits-of-heart based conflict resolution regarding local labor strikes</li>
                        <li>Studies of earthquake-proof building and a relief for Ecuador</li>
                        <li>Poverty and hunger projects, including food and toy drives</li>
                        <li>Years of service-learning visits, on foot, to the local senior center</li>
                    </ul>
                    <h2><strong>Integrating Character Strengths and Service-Learning</strong></h2>
                    <p>During a study of homes and homelessness, Tarzana students shared their symbol for Advocacy, an A that
                        also replicates the shape of a roof.</p>
                    <p>&nbsp;Tarzana students launched a tree-planting project on their school grounds. In the same year, they
                        conducted a diabetes awareness fair, with health information and BMI checks for parents.</p>
                    <p>Sponsoring a march to raise awareness of the&nbsp;children around the world, the Habits-of-Heart Club
                        helped purchase books and supplies for orphans after Ebola.</p>
                    <p>The Oak Park High Full-Circle Learning Club mentored Tarzana’s adopted grandparent visits for two years.
                    </p>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images1.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images1")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src}  />
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h2>&nbsp;<strong>Connections Across Generations</strong></h2>
                    <p>The Oak Park High Full-Circle Learning Club, led by Ben Winck, mentored Tarzana’s adopted grandparent
                        program over two years of visits.</p>
                    <p>&nbsp;The high schoolers also offered tutoring and support for environmental projects and charity drives
                        for Tarzana Elementary School’s Full-Circle Learning program.</p>
                    <p>&nbsp;Above, a resident far from her Israeli relatives cherished moments of bonding with adopted
                        grandchildren of all ages, including Ben.</p>
                    <p>Above, second column, students sang to honor medical researchers in 2003-4 (left). They made plans to
                        send supplies to refugees in 2019.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default TarzanaElementary