import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import image1 from '../../assets/images/image1-31.jpeg';
import image22 from '../../assets/images/image22-33.jpeg';
import Liberia1 from '../../assets/images/Liberia1.png';
import Liberia2 from '../../assets/images/Liberia2.png';
import Bong from '../../assets/images/kidswithgoat2.jpeg';
import studentsonthebus from '../../assets/images/studentsonthebus.png';
import Image from '../../assets/images/Image From FCL CC June 2021 stories.png';
import Libra_Image2 from '../../assets/images/Libra_Image2.png';
import Libra_image3 from '../../assets/images/Libra_image3.png';
import Libra_image4 from '../../assets/images/Libra_image4.png';
import Libra_image5 from '../../assets/images/Libra_image5.png';
import Liberia12 from '../../assets/images/Liberia12.png';
import Liberia21 from '../../assets/images/Liberia21.png';
import Liberia31 from '../../assets/images/Liberia31.png';
import Liberia41 from '../../assets/images/Liberia41.png';
import Liberia51 from '../../assets/images/Liberia51.png';
import Liberia61 from '../../assets/images/Liberia61.png';
import Liberia71 from '../../assets/images/Liberia71.png';
import Liberia81 from '../../assets/images/Liberia81.png';
import Liberia82 from '../../assets/images/Liberia82.png';
import Liberia91 from '../../assets/images/Liberia91.png';

const images = [
  {
    src: Liberia12,
    title: 'Students holding banners saying Full-circle Learning'
  },
  {
    src: Liberia21,
    title: 'Young students looking down at a pot of soup.'
  },
  {
    src: Liberia31,
    title: 'A student sitting at his desk in class.'
  },
  {
    src: Liberia41,
    title: 'A family standing in front of a building.'
  }
];

const images1 = [
  {
    src: Liberia51,
    title: 'Five girls from Girls United Club holding signs calling for end to gender violence.'
  },
  {
    src: Liberia61,
    title: "A student with a sign that reads Say no to gender based violence"
  },
  {
    src: Liberia71,
    title: 'Students sign a parachute with their wishes'
  },
  {
    src: Liberia81,
    title: 'A child shakes hands with a judge in a courtroom'
  },
  {
    src: Liberia82,
    title: 'A student in the field smiling'
  },
  {
    src: Liberia91,
    title: 'A child, Monlu, outside his home having received food assistance.'
  }
];

const Liberia = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Liberia</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="w-richtext">
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                src={image1}
                loading="lazy" alt="Students holding up the Liberian flag" /></div>
            </figure>
            <h2>Out of the Storm</h2>
            <p>A spring storm devastated the Tunapu School in Liberia, destroying the building’s roof and forcing
              students to miss classes for nearly two weeks. In this small village, Tunapu is the only school—and
              the only hope for local children. Despite being out of class, the students visited the damaged
              school building daily to contribute to the rebuilding process with spades and pails, eager to get
              back to their Full-Circle Learning lessons.</p>
            <p>Other Full-Circle Learning schools in nearby villages heard the news of Tunapu’s destruction. The
              students at the Millennium and CMA Schools, studying compassion and empathy as part of their
              curriculum, saw Tunapu’s tragedy as an opportunity to practice these habits-of-heart.</p>
            <p>Students from these schools spoke to parents and teachers to prepare a celebration for the Tunapu
              students once the roof had been put back in place. Full-Circle Learning's humanitarian aid program
              came to the rescue, helping to replace the roof. When the work was done, the community of learners
              gathered to give flowers to the Tunapu students as they returned to their repaired school, welcoming
              them back to class with love.</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                src={image22}
                loading="lazy" alt="Tunapu students with flowers" /></div>
            </figure>
            <p>&zwj;</p>
            <p>&zwj;</p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div>
                <img
                  alt="my alt"
                  src={Liberia1}
                  loading="lazy" /></div>
            </figure>
            <h1>Bearing the Torch of Compassion</h1>
            <p>If people are innocent until proven guilty, how much more so a baby languishing alongside its mother
              in a dank prison, partitioned from the outside world?&nbsp;</p>
            <p>So thought the learners at the Torchbearer School, who carried warmth and light to imprisoned
              infants. While researching government systems and social justice, they embarked on a six-month
              advocacy project to act on their awareness.</p>
            <p>The prison mothers, teen victims of drug addiction without family support, lacked sufficient food,
              diapers, and counseling to provide for their newborns. After interviews with authorities and the
              prison’s maternity ward, the Torchbearers visited the prison, cuddling the babies, committing to
              their care, and bringing comfort and understanding to these isolated young souls, both mother and
              baby.</p>
            <p>In doing so, the students of Liberia’s Montserrado County brought light to the lives of society’s
              most vulnerable.</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth">
              <div>
                <img
                  alt="my alt"
                  src={Liberia2}
                  loading="lazy" /></div>
            </figure>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth">
              <div><img
                src={Bong}
                loading="lazy" alt="A group of students help a goat." /></div>
            </figure>
            <h1>Altruism on the Farm</h1>
            <h2>A team of girls cares for vulnerable people by caring for animals, too.</h2>
            <h3>A Farmyard Full of Good Works</h3>
            <p>Liberia’s Girls United clubs use their imaginations to transform their communities. Hosted by
              Full-Circle Learning (FCL), Girls United chapters around the country have recently worked to combat
              poverty and disease, clean up polluted drinking water, and build a garden to celebrate peace. This
              spring, the girls in Bong County created a project that combines animal science, entrepreneurship,
              and altruism to nourish their community for many years to come.<br /></p>
            <p>The girls requested an FCL project grant to purchase 41 chickens and five sheep. Each girl cares for
              a chicken for three to four months, until the chickens hatch chicks of their own. The girls have
              done the math—by the end of the year, their 41 chickens will have produced 205 more.<br /></p>
            <p>Of these hens, most will be sold at market for just under $5 USD each. The profit will go to purchase
              food for 20 widowed and elderly community members and to buy school supplies for ten students who
              would otherwise be unable to afford their education. A few unsold chickens will serve as the mother
              hens for the next generation of chicks, allowing the girls to continue the project to bring food and
              resources to the community members who are most in need.<br /></p>
            <p>As for the sheep, the same logic applies. The five sheep will produce another ten offspring, and the
              funds from the sale of the sheep at market will go towards supporting those who need it most. The
              girls will hold on to a few sheep and use them to continue the project next year.<br /></p>
            <p>Over the course of their work, the girls will study marketing and other entrepreneurial skills, to
              ensure they get the best prices for the animals they’ve worked so hard to raise. As their stock of
              animals improves and grows with time, the girls will be able to help more people, creating years’
              worth of positive change.</p>
            <p><em>Help Full-Circle Learning fund tomorrow’s young leaders. </em></p>
            <p><a href="/friends/how-you-can-help" className='inquires'>Please click below to donate
              today.</a></p>
            <p><br /></p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth">
              <div>
                <img
                  src={studentsonthebus}
                  loading="lazy" alt="Students on the bus" /></div>
            </figure>
            <h1>Students Halt a Killer in the Air</h1>
            <h2>Young leaders across Africa are fighting malaria—and winning.</h2>
            <h3>Exchanging Wisdom to End Disease</h3>
            <p>Across Africa each year, 215 million people suffer from malaria, according to the World Health
              Organization. In some regions, the death toll surpasses that of Covid-19. When malaria strikes a
              community, 67% of the dead are children—but Africa’s children also lead the campaign to make malaria
              a problem of the past.</p>
            <p>Full-Circle Learning students realized that tackling any public health crisis begins with the
              habit-of-heart of awareness. Schools in Liberia, Nigeria, and Zambia currently work together to
              spread the awareness that can protect communities from malaria.</p>
            <p>In Nigeria, Harvard International students traveled across the region to galvanize peers as health
              advocates in their effort to save lives.&nbsp;A team of student researchers learned from their visit
              to a local hospital and taught their new knowledge to their classmates for a half an hour daily. Via
              cell phone, they met with learning partners abroad. Inspired by this international exchange of
              wisdom, they next traveled to another town, Umutu, where they visited six schools and trained 2,700
              children and 170 parents to act as health advocates. In the photo above, the students are on the
              road to make this change.&nbsp;</p>
            <p>Meanwhile, the&nbsp;rainy season brought flooding to Liberia. Standing water bred new mosquitoes to
              carry the malaria parasite. At Ma Vonyee G. Dahn Memorial School, students toured a malaria research
              lab and a public health department to get the facts. They shared specially treated mosquito nets and
              information about treatment with families in 14 homes. Elderly and high-risk community members who
              lived near the school happily received the gifts. Other community members welcomed a public
              presentation (see photo below) which detailed the steps for mosquito eradication.</p>
            <p>Zambia’s Gifteria School also undertook a malaria prevention project. Students, parents, and teachers
              worked together to clean up the local environment and drain standing pools where mosquitoes could
              breed. Two local orphanages were especially vulnerable, due to their location near a stream. The
              Gifteria students visited these orphanages and the surrounding community, mosquito-proofing the
              landscape and giving treated nets to those in greatest need.</p>
            <p>By enhancing their projects through “wisdom exchanges,” each group of global learners accomplished
              more than they could have on their own. At present, the students continue their public health
              advocacy to improve the wellbeing of their human family.</p>
            <p><em>Help FCL’s students slap out malaria. </em></p>
            <p><a href="/friends/how-you-can-help" className='inquires'>Please click below to donate
              today.</a></p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth ">
              <div>
                <img
                  alt="my alt"
                  src={Image}
                  loading="lazy" /></div>
            </figure>
            <h2>The Light of Hope</h2>
            <h3>How your contributions helped students save a man’s life.</h3>
            <p>In the Liberian village of Blacktom, elderly citizens lived without electricity. The presence of
              dangerous animals, including snakes, meant they risked their lives every time they got out of bed in
              the night.</p>
            <p>While exploring the habit-of-heart of open-mindedness, students at the Harvest Mission and Hill City
              schools recognized the struggles of their elders and vowed to help.</p>
            <p>In a global wisdom exchange linking clean energy and the habit of compassion, the students first
              aimed to offer solar panels as affordable energy for the elders. Though they weren’t able to access
              this solar technology, they didn’t give up. The students learned how to wire and install
              battery-powered light strips, electrifying five local homes.</p>
            <p>On the first night after the installation, one man stepped out of bed and found his feet inches from
              a black cobra—the largest cobra species. Thanks to the light in his home, he could see to get rid of
              the snake before it could harm him with its deadly fangs.</p>
            <p>Thrilled at their project’s immediate impacts, the group of students went on to restore and
              chlorinate the water supply in seven abandoned wells in town. Through an extension grant, students
              are working to make the village’s new electrical systems solar and sustainable.</p>
            <p>Now Blacktom’s elders have water and light. Our thanks to you for fostering this lifesaving,
              earth-preserving work with your donations.</p>
            <p>&zwj;</p>
            <h2>Saving Lives on the Front Lines of Climate Change</h2>
            <h3>How one outstanding student brought clean water to her town.</h3>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                alt="my alt"
                src={Libra_Image2}
                loading="lazy" /></div>
            </figure>
            <h3>A Flood of Creativity</h3>
            <p>13-year-old Bendu Johnson is a powerhouse of creative altruism. Earlier this spring, she taught
              impoverished families in her town to feed and support themselves by catching crabs. As a member of
              the Girls United club, she’s been part of clean energy projects, demonstrations against gender-based
              violence, and even a meeting with a Nobel laureate, Madame Leymah Gbowee. NGO workers and teachers
              often visit Bendu to see what problem she’s planning to tackle next.</p>
            <p>This month, the problem is one of too much water—and not enough to drink. The rainy season in Liberia
              has flooded villages across the country, with rainfall levels amplified due to global anthropogenic
              climate change. The floodwaters contaminate town wells, introducing mud and pathogens into the water
              used for cooking and drinking.</p>
            <p>Unsafe water destroys the health and deepens the poverty of the most vulnerable people. Wealthier
              citizens can purchase purified water, but for low-income people, clean water costs too much. When
              children and elderly people come down with dysentery, treatment costs even more.</p>
            <p>Enter Bendu. The Full-Circle Learning scholarship student initiated a community-wide effort to solve
              this growing public health problem. Using the habits-of-heart of conviction and creativity, Bendu
              resolved to teach her community in Paynesville how to purify water with recycled supplies available
              for free.</p>
            <p>In the image below, she fills an empty rice bag with sand. When water passes through the sand and the
              fabric of the bag, many contaminants remain behind. The filtered water is safer to drink. Like many
              of FCL’s students, Bendu works hard to lead her community in adapting to the effects of the climate
              crisis.</p>
            <p><em>Your contributions make Bendu’s education possible. </em><a
              href="/friends/how-you-can-help" className='inquires'><em>Click to donate.</em></a>
            </p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center"><a
              href="/friends/how-you-can-help" className="w-inline-block inquires">
              <div><img
                alt="my alt"
                src={Libra_image3}
                loading="lazy" /></div>
            </a></figure>
            <p>&zwj;</p>
            <h2>Message in a Bottle</h2>
            <h3>How FCL’s young leaders are making a difference for World Oceans Day</h3>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                alt="my alt"
                src={Libra_image4}
                loading="lazy" /></div>
            </figure>
            <h3>Cleaning Beaches, Opening Minds</h3>
            <p>Last month, someone in the UK tossed away an empty water bottle. This week, the&nbsp;Change Agents in
              Liberia intercepted it during their beach cleanup. For these young leaders, every discarded bottle
              holds a message in it: "One&nbsp;Ocean, One Climate, One Future."&nbsp;</p>
            <p>Eight students from the GANDOMA and Korto schools organized a field trip to a key fishing port on the
              Atlantic coast. In many Liberian homes, fish is the main protein source, so these students know
              firsthand the importance of the health of the oceans for human health. To the students of the
              Climate Change Agents club, a beach covered in plastic is a sign of a planet in trouble.</p>
            <p>The students commemorated the United Nations’ World Oceans Day by facing the global plastics crisis
              head-on, collecting a mountain of bottles and other refuse. They filled two enormous bags, recycling
              all they could.</p>
            <p>The students also made homemade signs in support of ocean preservation. These signs opened the door
              for thoughtful conversations with other beachgoers about the many ways life on Earth depends on the
              oceans. Students were able to educate their community about the importance of keeping beaches and
              oceans clean and healthy.</p>
            <p>Everyone loves a day at the beach, but the greatest joy comes from making a positive difference for
              the planet and the people on it. Your contributions fund education beyond the classroom, giving
              students the reward of creating broader change.</p>
            <p><em>Thank you for your support for tomorrow’s leaders. </em><a
              href="/friends/how-you-can-help" className='inquires'><em>Click to donate.</em></a>
            </p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center"><a
              href="/friends/how-you-can-help" className="w-inline-block inquires">
              <div><img
                alt="my alt"
                src={Libra_image5}
                loading="lazy" /></div>
            </a></figure>
          </div>
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p> Full-Circle Learning Liberia hosted professional development training and capacity building for 475
              schools between 2010 and 2020. During the2014 Ebola outbreak, requests surged as teachers realized
              the humanitarian impact they could have, based on the prevention efforts at 31 schools. </p>
            <p> Exemplary school projects, as a result of these trainings, are too numerous to name. The photos here
              offer a glimpse. </p>
            <p> Children convening in a mobile schoolduring school closures learned by doing.They had to spell the
              ingredients of hotpepper soup before gathering thoseingredients and putting them into a pot,which
              they delivered to neighbors forcomfort. They avoided approaching thecorpses that lay covered in the
              streets asthey courageously conducted theirservice. </p>
            <p> As teachers delivered bleach and food, throughthe help of a Nevada County fundraisingconcert,
              students conducted hand-washingcampaigns and made “comfort pillowcase flags.” </p>
            <p> Five years afterward, as Liberia still sufferedfrom a shortage of doctors and nurses, theFull-Circle
              Learning students became advocatesfor community health, learning skills they couldteach their
              parents. </p>
            <p>&zwj;</p>
          </div>
          <div className="rich-text-block w-richtext">
            <p> In the ensuing years, projects ranged from literacy projects in which children taught adults, to a
              peace rally that students held to prevent civil war. </p>
            <p> Girls United Clubs at Full-Circle Learning<br />schools conducted numerous projects, including
              participation with UN officials in the 16-Day Campaign againstGender Violence. </p>
            <p> Girls also taught their community climate change awareness. Here they signed a parachute with their
              wishes and lifted it high, to represent the ozone layer. </p>
            <p> In one Full-CircleLearning school, teachers immediately incorporated service-learning field trips
              within each learnin gunit. One fourth grade teacher taught his students about natural resources.
              When their parents complained that </p>
            <p>“justice for the poor” was not a resource, he took them on a field trip to a court room. After the
              trial, the judge said, “Both the defendant and the victim were poor. Each had two free attorneys,
              the jury and myself toad minister a fair trial. Do you now think we have justice for the poor?” The
              class president delivered a statement to the judge, and the class sang for her and honored her for
              creating justice. They went home to give their parents a new perspective.</p>
            <p> The coronavirus pandemic of 2020 brought months of distance learning, on foot and online. Liberia
              contributed to the creation of distance learning courses for its own country and beyond. </p>
            <p>By 2020, through the EHG fund and other donors, FCL had established scholarships for ten students.
              They worked online and on the ground to apply the habit-of-heartEmpathy. Laura (left) </p>
            <p>arranged to deliver produce from her school farm to the elderly, starving during the lockdown.
              Monlu’s community (right) received FCL food aid from FCL, to prevent starvations during school
              lockdowns.</p>
            <p> In 2020, several schools received Exemplary Project grants. Each one used multiple skill sets to
              apply a habit-of-heart toward a sustainable development goal, such as food security, disease
              prevention, eldercare, innovative agriculture or a sustainable environment.</p>
            <p> In these photos, students from several communities sprayed for pest control, taught sanitation, and
              performed demonstrations on the conflict bridge to promote community awareness of </p>
            <p>infectious disease caused by pests.</p>
            <p>At other schools, students grew and delivered food for the widows in the village or designed new
              techniques for farming. Academies and village schools designated to lead individual or multi-group
              projects included Korto, Grace International,United Christian Ministry, Redeemed Christian Ministry,
              and Faith Academy and Gandoma.</p>
          </div>
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>


      </Row>
    </Container>
  )
}

export default Liberia