import React, { useState } from 'react'
import {
    Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US6 from '../../../assets/images/US6-p-500.png';
import US7 from '../../../assets/images/US7-p-500.png';
import US10 from '../../../assets/images/US10-p-500.png';
import US11 from '../../../assets/images/US11-p-500.png';
import US8 from '../../../assets/images/US8-p-500.png';
import US9 from '../../../assets/images/US9.png';
import US12 from '../../../assets/images/US12-p-500.png';

const images = [
    {
        src: US6,
        title: "Students performing on stage"
    },
    {
        src: US7,
        title: "Four students performing with instruments"
    }, {
        src: US10,
        title: "Sonny Douglas with students in Lesotho."
    }, {
        src: US11,
        title: "Gretchen Newcomb teaching students in Liberia"
    },
];
const images1 = [
  {
    src: US12,
    title: "Jade Romain with students in Zambia."
  },
  {
    src: US8,
    title: "Students standing in front of the art at the art show"
  },
  {
    src: US9,
    title: "Students sitting at the shoreline"
  }
];

const AlumniClubs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({src: '', title: ''});

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = arrayName === 'images' ? images : images1;
        toggleModal();
        setCurrentIndex(selectedArray[imageId]);
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1><strong>Alumni Clubs</strong></h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h2>&nbsp;<strong>A Club’s Creative Global Wisdom Exchanges</strong></h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} style={{ height:"350px",objectFit:"cover" }}/>
                        </Card>
                    ))}
                </Col>

                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>&nbsp;The Full-Circle Learning Alumni Club, from Los Angeles, taught the habits-of heart through wisdom
                        exchange. They turned the stories and letters of a Kenyan partner school into drama and dance, performed
                        here at a Santa Monica.</p>
                    <p>In exchange, the alumni sent a blueprint for a Harmony House, below, a structure with unifying elements
                        where musical harmony completes the metaphor and contributes to the serenity.</p>
                    <p>&nbsp;Over a weekend spent in a mountain community, Full-Circle Leaning alumni studied bark beetle
                        impacts, working with a global partner school to compare environmental concerns experienced in urban
                        verses rural environments.&nbsp;</p>
                    <p>Full-Circle Learning alumni have been instrumental in connecting the seams of the world.</p>
                    <p>&nbsp;Sonny Douglas wanted to act on his convictions. After his 8th grade year, he spent a summer
                        integrating Full-Circle Learning into his math tutoring experience in Mokhotlong Lesotho. He was younger
                        than the students he taught!</p>
                    <p>&nbsp;Full-Circle Learning home school alumni alumnus Gretchen Newcomb taught music and science to K-12
                        students in Liberia during her gap year. She is now studying opera and has a vibrant singing career.</p>
                    <p>&nbsp;Like the others, Jade Romain was shaped by her childhood Full-Circle Learning experiences and
                        wanted to give back. She traveled to Zambia and taught a class of preschoolers at Blessed Vale. She now
                        has expertise in speech pathology and criminal justice sentencing.</p>
                </Col>

                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images1.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images1")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} />
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default AlumniClubs