import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import ethiopia1 from '../../assets/images/ethiopia1.jpeg';
import ethiopia2 from '../../assets/images/ethiopia2.jpeg';
import ethiopia3 from '../../assets/images/ethiopia3.png';
import ethiopia4 from '../../assets/images/ethiopia4.png';
import ethiopia5 from '../../assets/images/ethiopia5.png';
import ethiopia6 from '../../assets/images/ethiopia6.png';
import ethiopia7 from '../../assets/images/ethiopia7.png';
import ethiopia8 from '../../assets/images/ethiopia8.png';
import ethiopia9 from '../../assets/images/ethiopia9.png';

const images = [
  {
    src: ethiopia5,
    title: "Four students in suits at the African Union building."
  }, {
    src: ethiopia6,
    title: "Representative of Full-circle Learning ready to speak at the African Union."
  }, {
    src: ethiopia7,
    title: "A man and woman smiling at the African Union building."
  }, {
    src: ethiopia8,
    title: "A group gathered in front of the African Union building."
  }
];

const images1 = [
  {
    src: ethiopia9,
    title: "A group of teachers in front of a school."
  }
];

const Ethiopia = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Ethiopia</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="w-richtext">
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" >
              <div><img
                src={ethiopia1}
                loading="lazy" alt="Several Youth sitting together talking" /></div>
            </figure>
            <p><br /></p>
            <h2><strong>Ethiopian Youth Address Hunger, Highway Safety and Happiness</strong></h2>
            <p>Young people care for their human family across the Gondar region of Ethiopia. FCL partner Yenege
              Tesfa Integrated Development Humanitarian Organization has inspired them, over the past six months,
              to use their time and knowledge to increase the happiness quotient. Three recent examples illustrate
              the point.</p>
            <p>The Humanitarian Club at Fasiledes Higher Education Preparatory School reached out to overcome a
              multi-agency shortage of volunteers who serve the elderly. At two care centers, 332 elders, mentally
              ill residents and orphans benefited from the students’ service. The increase in volunteers came as a
              relief to residents such as Ato (Uncle) Abera Mengistu, an elder who confirmed that over the 13
              weeks of consecutive service, happiness and cleanliness became “the profile” for those living at the
              center.</p>
            <p>The same school acted on a five-year study of traffic accidents in Gondar City, which illuminated the
              extent of the deaths, injuries and property damage caused by accidents each year, even in areas with
              few cars. Thirty students of all ages received traffic safety training, to educate the community on
              traffic safety. &nbsp;(Below, participants discuss traffic safety strategies.)</p>
            <p>Meanwhile, community activist groups at two more schools,&nbsp;‘Hibret’ and ‘Abiyot- Frie’, conducted
              field assessments to evaluate food security. They discovered that a lack of funds had contributed to
              malnutrition and widespread illness among many families. The students created model gardens and
              taught families based on their own emergent understanding of small-scale farming. Yenege Tesfa
              launched the experimental gardens in six shelters and student food centers, helping students raising
              awareness among 74 people in five different neighborhoods of Gondar City. How many more will they
              feed, with our help?</p>
            <figure className="w-richtext-align-fullwidth w-richtext-figure-type-image">
              <div><img
                src={ethiopia2}
                loading="lazy" alt="Parents gathered to talk about traffic." /></div>
            </figure>
            <h2><strong>Orphanage Builds Hope</strong></h2>
            <p>Yenege Tesfa (YT), or Hope for Tomorrow, serves 27,000 Ethiopian orphans. The organization joined the
              Full-Circle Learning family in 2022, relying on FCL as its US fiscal agent and also jumping into the
              training process, to expand the humanitarian capacities of youth.&nbsp;</p>
            <p>This orphanage system offers housing, medical assistance, and educational support for orphans in the
              conflict-ridden Gondar area, which borders the Tigray region where fighting has displaced thousands
              since 2020.&nbsp;</p>
            <p>The YT staff trained a pilot group of 54 students and led them in field research to identify the
              initial community needs each of their schools would address. The teams determined to target: 1) a
              need for increased volunteerism to assist the elderly and mentally ill; 2) malnutrition impairing
              the health of children; and 3) a high rate of traffic accidents on the main routes to schools
              attended by 7,000 children.&nbsp;</p>
            <p>This Ethiopian community development team is quickly enhancing the significance of its programs with
              services that not only attend to survival needs but also help young people embrace their role as
              society’s change agents.&nbsp;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                src={ethiopia3}
                loading="lazy" alt="Hailu engages in field assesment of community needs." /></div>
              <figcaption>Students, led by Hailu Gebeyehu, engaged in a door-to-door field assessment of community
                needs.</figcaption>
            </figure>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                src={ethiopia4}
                loading="lazy" alt="Students joining groups gathered to assess community needs." /></div>
              <figcaption>These Hibret Primary School students joined the groups who gathered to identify
                community needs.</figcaption>
            </figure>
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>


        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
            <div className="rich-text-block-2 color-link w-richtext">
              <p>Young college students attended a Full-Circle Learning training, intent to share their learning in
                Addis Ababa. They trained multiple schools in Full-Circle Learning’s philosophy, vision, and
                strategies. The One Planet School, still formulating its pedagogy at that time, attracted the
                admiration of many in that city. Its students later became wisdom exchange partners for
                third-graders at the Full-Circle Learning Academy in Los Angeles, exchanging habit-of-heart based
                service-learning projects.</p>
              <p>At One Planet’s ten-year anniversary, held at the African Union building, the school invited
                Full-Circle Learning to speak to the gathering assembled there, to remind them of the vision that
                had inspired the school from its inception. </p>
              <p>&zwj;</p>
            </div>
            <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
          </Col>

          <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        



      </Row>
    </Container>
  )
}

export default Ethiopia