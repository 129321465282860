import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";

const HonorUnit = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1 fw-bold">Honor</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >

                    <Row className="collection-list-3">
                        <Col sm="12" className="">
                            <h2 className="heading-18">Introduction</h2>
                            <p className="paragraph-6">Enjoy relevant, purposeful learning. Follow the steps of the Full-Circle Learning model through a series of activities applicable for school or home use. Lesson plans include links for broadcast or online classrooms as well as print options for classrooms or homes without technology.</p>
                            {/* <a href="/" className="download-button w-dyn-bind-empty w-button"></a> */}
                            <div className="div-block-3">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Outline</h2>
                            <p className="paragraph-6">Teachers, parents or self-guided students can use this document to preview the elements and assignments to include based on available technologies.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/habit-of-heart-honor.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 1 Let the Habit of Honor Flower</h2>
                            <p className="paragraph-6">Performing Artist Sands Hall introduces the meaning of Honor. If you have a flower, bring it as you watch the video or read along. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-one.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FZh723Q7aEL8%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DZh723Q7aEL8&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FZh723Q7aEL8%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 2 Stories of Honor</h2>
                            <p className="paragraph-6">Write your own story after reading these: Prize-winning author Charles McNair tells a Cherokee story about Honor. Dr. Adela Castro translates. Social worker Rosemarie Smith follows up with a story about middle school students in the UK. Liberian novelist Saah Millimono offers a story about honor for high school readers.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-two.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FcaxKinOoZZw%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DcaxKinOoZZw&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FcaxKinOoZZw%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 3 Make My Heart Sing</h2>
                            <p className="paragraph-6">Learn songs to enhance your projects: Celia Behar’s Mexican favorite, “Des Colores” in Spanish. Sands Hall’s “Drop in the Bucket.” Olivia Newcomb’s rendition of “Honor.”</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-three.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F7Q-1C88i0Zg%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D7Q-1C88i0Zg&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F7Q-1C88i0Zg%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FUVWXHUemQOw%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DUVWXHUemQOw&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FUVWXHUemQOw%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 4 A Moment to Decide</h2>
                            <p className="paragraph-6">Make your own conflict resolution bridge. Join with a partner to practice three role plays that require honor.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-four.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 5 Birds of a Color</h2>
                            <p className="paragraph-6">From colors to quizzes to birdhouses, a range of options sing out to learners from age 4 to 14. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-five.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3"><div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 6 Flowers of a Color</h2>
                            <p className="paragraph-6">Primary school students experiment with color and learn more about the biology of flowers.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-six.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 7 A Rose by any other Name</h2>
                            <p className="paragraph-6">Secondary students consider a code of honor while learning about free trade and fair trade—and then draw up plans for an imaginary rose growing operation!</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-seven.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 8 A Drop in the Bucket</h2>
                            <p className="paragraph-6">Explore history’s heroes, teach others about your own heroes, and write down steps for decision-making. View a video about heroic Liberian students who helped prevent civil unrest.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-eight.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fs_yjrPTMHVs%3Fstart%3D45%26feature%3Doembed%26start%3D45&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Ds_yjrPTMHVs&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fs_yjrPTMHVs%2Fhqdefault.jpg&amp;args=start%3D45&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 9 Arrange to Honor Someone</h2>
                            <p className="paragraph-6">Reflect on the decisions of others that have improved your life. Honor them with a homegrown flower arrangement. Fariba Mahjour will show you how, on this video. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-nine.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F0JRYJ_GxG1Q%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D0JRYJ_GxG1Q&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F0JRYJ_GxG1Q%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 10 Sending Wisdom—Stella’s Challenge</h2>
                            <p className="paragraph-6">Receive a visit from a student “wisdom exchange partner” facing decisions about her life. Watch the video, read the letter and respond to her or to other distant learners.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-ten.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F8CYPPV2oxNM&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D8CYPPV2oxNM&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F8CYPPV2oxNM%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Element 11 Leaving an Imprint</h2>
                            <p className="paragraph-6">Experience a story about your honorable actions. Listen to a video by Rómulo Castro called La Rosa Maori. (visit his youtube channel.) Finally, make an artistic clay tile walkway to celebrate the imprint your group has left on the world.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-eleven.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F44ZZbLO-iEE%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D44ZZbLO-iEE&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F44ZZbLO-iEE%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-18">Glossary</h2>
                            <p className="paragraph-6">Preview and review unfamiliar words, as needed.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/honor/honor-element-glossary.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-3">
                                <div className="w-dyn-bind-empty w-video w-embed">
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed">
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed">
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default HonorUnit