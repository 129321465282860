import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";

const ForgivenessUnit = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h2 className="mt-4 mb-4 fs-1">Forgiveness</h2>
                </Col>


                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >

                    <Row>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Outline</h3>
                            <p className="paragraph-7">Teachers, parents or self-guided students can use this document to preview the elements and assignments to include based on available technologies.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-outline.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Element 1: It Starts with a Breath</h3>
                            <p className="paragraph-7">Listen to a secret for preparing to forgive! Video elements shared by René Sprattling and Drue Mathies and Renée Murray Smith also appear in text.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-one.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fi5f4bIAMzRY%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Di5f4bIAMzRY&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fi5f4bIAMzRY%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRbFRm7qXEyk%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRbFRm7qXEyk&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRbFRm7qXEyk%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Element 2: The Power of Song</h3>
                            <p className="paragraph-7">See and hear about music and its role in forgiveness, then learn how to create your own song!</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-two.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Element 3: Forgiveness Around the World</h3>
                            <p className="paragraph-7">Stories and images will help you learn words in English if you are new to the language. Read them for fun if you already read English!</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-three.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Element 4: Words as Tools</h3>
                            <p className="paragraph-7">Play Word Games and Math Games. See and hear English phrases in other languages that mean “I’m sorry” or “I forgive you.” </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-four.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Element 5: Restorative Justice</h3>
                            <p className="paragraph-7">Learn how to restore justice when someone is hurt. The stories, games and tools here will help.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-five.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Element 6: Forgiveness in the Air</h3>
                            <p className="paragraph-7">Learn how students used their science skill to improvise ventilators that help everyone, despite differences. Use this chart to practice unconditional caring. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-six.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Element 7: Taking an Oath</h3>
                            <p className="paragraph-7">This group activity will stretch your thinking about choices and help you prepare for oaths you may later take in your profession. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-seven.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Element 8: A Need to Forgive</h3>
                            <p className="paragraph-7">A short story reading and writing challenge for high school students will help you prepare for a Storyteller’s Circle.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-eight.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Element 9: What Can You Do?</h3>
                            <p className="paragraph-7">Learn how others have used their engineering skills to improve job skills will improving life on the planet. Present a dream design with built-in unity and forgiveness.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-nine.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3 className="heading-23">Glossary</h3>
                            <p className="paragraph-7">Preview and review unfamiliar words.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/forgiveness/forgiveness-element-glossary.pdf" className="button-2 download-button w-button">Download</a>
                            <div className="div-block-7">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default ForgivenessUnit