import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";
const TeacherResources = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1 fw-bold">Teacher's Resources</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Row className="collection-list-2 w-dyn-items mt-2">
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Planning Tools and Templates</h2>
                            <p className="paragraph-4 w-dyn-bind-empty"></p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/planning-templates-tools.pdf" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Mastery Ceremony Guidelines</h2>
                            <p className="paragraph-4">Showcase community transformation projects and recognize your change agents and families for what they have mastered during the year. Plan early for a successful event with universal participation.</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/6055299f9bd40a9a30d5c7a5_Mastery%20Ceremony%20Guidelines%20and%205-week%20checklist%2C%2011-15.doc" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Schoolwide Evaluation Rubric</h2>
                            <p className="paragraph-4">This tool for staff development will help you determine all the variables that raise student motivation and acheivement by first helping young change agents apply relevant academic and artistic skills and character strengths and actualize their potential in the communty transformation process..</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/605529ac61cc33f0e68c2902_RubricforSchool-WideEvaluation-revised2016.docx" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">classNameroom Management Based on Group Self-Mastery Goals</h2>
                            <p className="paragraph-4">Design your pacing plan in the summer, but save time to saddle up your classNameroom management plan to the same goals!</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/605529b93f17b64b097c6bc4_ClassroomManagementBasedonSelf-Mastery.docx" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Explanation of the 13-S Steps</h2>
                            <p className="paragraph-4">What's this item about? What makes it interesting? Write a catchy description to grab your audience's attention...</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/605529bff0b9d8e76d0f67c4_13-SSteps-Explanation.docx" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Template for Full-Circle Learning 13-S Steps</h2>
                            <p className="paragraph-4">After on-land training or onsite refresher course, download this form to brainstorm ideas for each new unit plan.</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/605529c88ad4633f9019b90e_TemplateforFull-CircleLeaning13-SSteps.docx" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Matching Community Needs and Resources</h2>
                            <p className="paragraph-4">Download to collect information when identifying concerns that inspire community transformation projects</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/605529d261cc33ed0d8c2907_MatchingCommunityNeedsandResourcesgridsheet.docx" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Project Assessment Rubric</h2>
                            <p className="paragraph-4">Blank template and completed example help teachers show they value process as well as content on major projects.</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/605529ac61cc33f0e68c2902_RubricforSchool-WideEvaluation-revised2016.docx" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Habit-of-Heart Certificate</h2>
                            <p className="paragraph-4">Customize individually for the habit each student has mastered by the end of the year; or for students to each give their caregivers; or for presenters and field trip hosts.</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/60552a12bd5d9036e37be712_BlankSampleHabit-of-HeartCertificate%20forGuestParentorStudent.doc" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Habit-of-Heart Poster</h2>
                            <p className="paragraph-4">A handy reference for planning your own learning units around the themes in each learning module.</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/60552a1bf0b9d8a0270f68fc_HabitsofHeartPoster.pdf" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Daily Checklist</h2>
                            <p className="paragraph-4">Self-Assess to Align Planning, classNameroom Management Practices and Intentions.</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/60552a2291ba0c0493e22431_Daily%20Checklist-AligningmyClassrroomManagementPracticeswithMyIntentions.doc" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-13">Teachers’ Anonymous Self-Quiz</h2>
                            <p className="paragraph-4">Aids reflection and planning as you measure progress toward personal and group goals.</p>
                            <a target="_blank" rel="noreferrer" href="https://assets-global.website-files.com/604af88c7373bea957f9b334/60552a269f59da6b0a39c24c_TeachersAnonymousSelf-Quiz.docx" className="download-button w-button">Download</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default TeacherResources