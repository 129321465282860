import React from 'react'
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from 'prop-types';

const MyModal = ({currentIndex, isModalOpen, toggleModal}) => {
  return (
    <Modal
      className="modal-lg"
      isOpen={isModalOpen}
      toggle={toggleModal}
    >
      <ModalHeader style={{ border: "none" }} toggle={toggleModal}></ModalHeader>
      <ModalBody className='d-flex justify-content-center'>
        <img src={currentIndex.src} alt={currentIndex.title} />
      </ModalBody>
    </Modal>
  )
}
MyModal.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  currentIndex: PropTypes.shape({
    src: PropTypes.string,
    title: PropTypes.string
  })
};

export default MyModal
