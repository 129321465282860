import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US65 from '../../../assets/images/US65-p-500.png';
import US67 from '../../../assets/images/US67-p-500.png';
import US68 from '../../../assets/images/US68-p-500.png';
import US66 from '../../../assets/images/US66-p-500.png';
import US69 from '../../../assets/images/US69-p-500.png';
import US70 from '../../../assets/images/US70-p-500.png';

const images = [
    {
        src: US65,
        title: "Wall mural that reads with head"
    },
    {
        src: US67,
        title: "Children read the mural"
    }, {
        src: US68,
        title: "An academy student smiles while drawing an assignment"
    }, {
        src: US66,
        title: "Student at the beach gathering sand for a project"
    }
];
const images1 = [
    {
        src: US69,
        title: "Wall mural that reads with head"
    },
    {
        src: US70,
        title: "Children read the mural"
    }
];

const GrassValleyEducation = () => {
    const allImages = {
        images,
        images1
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = allImages[arrayName];
        if (selectedArray) {
            toggleModal();
            setCurrentIndex(selectedArray[imageId]);
        }
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1>Grass Valley (Education as Transformation statewide training)</h1>

                    <h2><strong>Word of Mouth and Action-based Learning</strong></h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} style={{ height:"400px",objectFit:"cover" }}/>
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>Universities, schools and community-based organizations across California have requested Full-Circle
                        Learning’s professional development programs.</p>
                    <p>Here, educators gathered in Quincy for a workshop sponsored by Plumas Audubon, with invited organizations
                        supporting girls’ education, indigenous education, environmental education, and charter schools. A local
                        teacher later kept FCL’s conflict bridge handy for classroom use.</p>
                    <p>Below, teachers, trainers and board members convened in Orange County for a refresher course.</p>
                    <p>Multiple Nevada County training events near FCL’s home base brought together educators from diverse
                        regions, schools and projects.</p>
                    <p>The Nevada County FCL workshop in 2016 focused on adaptations for Latin America and Navajo schools,
                        uniting local educators with participants from Arizona and Ohio.</p>
                    <p>The 2015 Marin County workshop at left was one of ten Northern California workshops within the same time
                        period.</p>
                    <p>Those featured below, at a Grass Valley workshop, represented&nbsp;projects in Davis, Reno, Orange
                        County, Africa, and Pakistan.</p>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images1.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images1")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} style={{ height:"150px",objectFit:"cover" }}/>
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default GrassValleyEducation