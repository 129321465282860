import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import zambia1 from '../../assets/images/zambia1.png';
import zambia2 from '../../assets/images/zambia2.png';
import Zambia3 from '../../assets/images/Zambia3.png';
import Zambia4 from '../../assets/images/Zambia4.png';
import studentsonthebus from '../../assets/images/studentsonthebus.png';
import twocountriesexchange from '../../assets/images/twocountriesexchange.png';
import Zambia5 from '../../assets/images/Zambia5.png';
import Zambia6 from '../../assets/images/Zambia6.png';
import Zambia7 from '../../assets/images/Zambia7.png';
import Zambia8 from '../../assets/images/Zambia8.png';
import Zambia9 from '../../assets/images/Zambia9.png';
import Zambia10 from '../../assets/images/Zambia10.png';
import Zambia11 from '../../assets/images/Zambia11.png';
import Zambia12 from '../../assets/images/Zambia12.png';
import Zambia13 from '../../assets/images/Zambia13.png';

const images = [
  {
    src: Zambia5,
    title: 'Students in class smile in the foreground with a banner in the background that reads Welcome to the class of oneness.'
  },
  {
    src: Zambia6,
    title: "Mildred Academy's fourth graders gathered smiling at the camera."
  },
  {
    src: Zambia7,
    title: 'Dr. Farzin Rahmani visiting the staff of Blessed Vale school.'
  },
  {
    src: Zambia8,
    title: 'A group of Teachers in a nest formation.'
  }
];
const images1 = [
  {
    src: Zambia9,
    title: 'Eric Muleya is joined by two students outside a school.'

  },
  {
    src: Zambia10,
    title: "Teachers gathered together having participated in a workshop."
  },
  {
    src: Zambia11,
    title: 'Students are standing in line with stretches of tape marking social distancing.'
  },
  {
    src: Zambia12,
    title: 'Two boys in class holding a sign that reads Habit of Heroes, Vision Seeking Advocacy Dedication Selflessness'
  },
  {
    src: Zambia13,
    title: 'A group of boys outside of a classroom gather for the camera.'
  }
];

const Zambia = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Zambia</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="w-richtext">
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div>
                <img
                  alt="my alt"
                  src={zambia1}
                  loading="lazy" /></div>
              <figcaption><em>Above: Learning from the Nigerian team, the Zambian students went door to door
                educating people about Lassa fever, eventually drawing government recognition as well.</em>
              </figcaption>
            </figure>
            <h2><strong>Nothing heals quite like empathy, the universal remedy.</strong></h2>
            <p>One student-led “wisdom exchange” began in the secondary grades of the Harvard International school,
              in the Delta region of Nigeria, where students ultimately shared health education concepts with
              20,000 households in 2021. They followed a systematic process to do so. Their learning unit theme,
              Awareness, included discussions about the factors that promote equity and that keep societies
              healthy. Students met with the region’s medical director, who identified the area’s most relevant
              health risks and treatment disparities, focusing especially on Lassa fever among vulnerable
              populations.</p>
            <p>Because of the disease’s relative obscurity, the students, in their first academic unit, set a goal
              of heightening awareness of Lassa fever among nearby residents. They began walking door to door to
              help households understand their own health risks and guide them in safely clearing up rodent
              infestations. After their early successes, the school’s Health Disparities Team traveled to another
              town and then to another. Next, they presented information at multiple schools, to deputize and
              educate peers as public health advocates. Ultimately, they reached households in five towns with
              their message, tracking statistics as they measured the impact based on the average number of
              individuals in a household.</p>
            <p>Officials urged the school to expand its efforts, confident in the students’ emerging expertise and
              strong commitment to the common good. The school no longer needed to solicit health agencies for
              information, as government departments began to reach out to them as a resource, tasking them with
              public education about the next set of health priorities--malaria, diabetes, and high blood
              pressure—according to project leader Harry Kennedy.</p>
            <p>Another development convinced officials to reach out to the school, Kennedy reported. A conspiracy
              theory had led some citizens to discredit government health advisories, believing the intention was
              to increase the mortality rate and reduce the size of the population. With government-sponsored
              public awareness programs decreasing in their effectiveness, officials felt compelled to cultivate
              new trust builders.</p>
            <p>The confidence placed in them helped cement students’ sense of purpose as they reached across
              cultural and national boundaries. Seeing the results, the Education Ministry requested teacher
              training programs for educators at other schools. Kennedy remarked that the movement had begun to
              “revive our national system. This program is making a great difference in the lives of the
              children.”</p>
            <p>Meanwhile, wisdom exchange partners in two Zambian community schools, St. Thomas and Lushomo, began
              to mitigate the spread of typhoid. (A community school is defined as a typically free school for
              low-income learners, operated by parents, as opposed to tuition-based public or private schools.) In
              many of the schools, teachers strive to teach the national curriculum with little or no pay, while
              implementing an additional mission of fostering humanitarian goals. The Zambian learners compared
              their own disease prevention process with that of the Nigerian students at Harvard International.
              They visited health professionals to learn the causes and effects of typhoid. They created
              educational materials to carry out neighborhood awareness campaigns and environmental cleanups. They
              wrote letters back and forth with the Nigerian partners about best practices to build public trust.
              They conducted conflict resolution exercises to address tribal resistance to adding chlorine to
              drinking water. Through their correspondence with the Nigerian students, they also learned about the
              causes of the vector-borne disease Lassa fever, previously undocumented in Zambia.</p>
            <p>The Zambian students later contacted their environmental health technician about their findings with
              the Nigerian students concerning Lassa fever and initiated a pledge to add it as a research topic,
              to determine the possible existence of Lassa fever outbreaks in their country. Zambian Full-Circle
              Learning trainer Eric Muleya expressed amazement at this unanticipated wisdom exchange outcome.
              Originally the educators had anticipated an exploration of community-trust building and the process
              of reducing health disparities. When a community school directed its nation to explore a previously
              untreated disease, he remarked, “Essentially, these young students are realizing their capacity to
              influence global health!”</p>
            <p>The schools within each setting grew into their roles as ideal trust builders, enhancing the work of
              health agencies, clinics, and civil society organizations even beyond their own borders.</p>
            <p>&zwj;</p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center" style={{ "textAlign":"center" }}>
              <div>
                <img
                  alt="my alt"
                  src={zambia2}
                  loading="lazy" /></div>
            </figure>
            <h1>A Cornucopia of Capacities</h1>
            <p>Every child has a gift. At Zambia’s Multisensory School, they discover why their gifts matter.</p>
            <p>Teachers introduced the habit “Appreciation of Diversity” by challenging students to teach a younger
              class about a variety of fruits. The lessons not only included nutritional details but appreciation
              of the varied capacities that enrich a healthy society. Alter-abled students, including autistic
              children and children with Down’s syndrome, stood among neurotypical learners to educate their
              peers, demonstrating that diverse gifts make for a stronger classroom—and a better world.</p>
            <p>The children extended their voices in celebration of diversity by writing letters to parents, to
              government officials, and to students at neighboring schools.</p>
            <p>In the US, we struggle to make unheard voices equally heard. Imagine—no fewer
              than&nbsp;72&nbsp;ethnic tribes call Zambia home and vie for political influence. Full-Circle
              Learners show that maintaining harmony around the world begins with a generation who teach one
              another how to listen to every voice at the table.</p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center" style={{ "textAlign":"center" }}>
              <div>
                <img
                  alt="my alt"
                  src={Zambia3}
                  loading="lazy" /></div>
            </figure>
            <h1>Lighting the Path from Village to City to Country</h1>
            <p>Good news travels on foot, by bus and now via Zoom. Zambian educators can testify to that fact,
              thanks to new initiatives in the country that are bringing Full-Circle Learning to more bright young
              minds.</p>
            <p>This month, in the rural Mumbwa District of Zambia, local educators requested online capacity
              building workshops to help 12 new schools revitalize their communities. In the same month, 14
              schools in the capital city of Lusaka came together remotely, joining the movement with teacher
              training programs of their own.</p>
            <p>Two weeks later, Zambia’s school principals met online with visionary leaders from ten countries.
              They set out to help tackle humanity’s challenges through Full-Circle Learning’s transformational
              education processes and projects with habits-of-heart at their core.</p>
            <p>St. Thomas School principal, Raphael Chilwana, said: “We just want to thank you…for coming up with a
              program for school principals to help learners express love and unity in schools.”</p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" >
              <div>
                <img
                  alt="my alt"
                  src={Zambia4}
                  loading="lazy" /></div>
            </figure>
            <p>&zwj;</p>
            <p>&zwj;</p>
            <p>&zwj;</p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style={{ "textAlign":"center" }}>
              <div><img
                src={studentsonthebus}
                loading="lazy" alt="Students on a bus" /></div>
            </figure>
            <p>&zwj;</p>
            <h1>Students Halt a Killer in the Air</h1>
            <h2>Young leaders across Africa are fighting malaria—and winning.</h2>
            <h3>Exchanging Wisdom to End Disease</h3>
            <p>Across Africa each year, 215 million people suffer from malaria, according to the World Health
              Organization. In some regions, the death toll surpasses that of Covid-19. When malaria strikes a
              community, 67% of the dead are children—but Africa’s children also lead the campaign to make malaria
              a problem of the past.</p>
            <p>Full-Circle Learning students realized that tackling any public health crisis begins with the
              habit-of-heart of awareness. Schools in Liberia, Nigeria, and Zambia currently work together to
              spread the awareness that can protect communities from malaria.</p>
            <p>In Nigeria, Harvard International students traveled across the region to galvanize peers as health
              advocates in their effort to save lives.&nbsp;A team of student researchers learned from their visit
              to a local hospital and taught their new knowledge to their classmates for a half an hour daily. Via
              cell phone, they met with learning partners abroad. Inspired by this international exchange of
              wisdom, they next traveled to another town, Umutu, where they visited six schools and trained 2,700
              children and 170 parents to act as health advocates. In the photo above, the students are on the
              road to make this change.</p>
            <p>Meanwhile, the&nbsp;rainy season brought flooding to Liberia. Standing water bred new mosquitoes to
              carry the malaria parasite. At Ma Vonyee G. Dahn Memorial School, students toured a malaria research
              lab and a public health department to get the facts. They shared specially treated mosquito nets and
              information about treatment with families in 14 homes. Elderly and high-risk community members who
              lived near the school happily received the gifts. Other community members welcomed a public
              presentation (see photo below) which detailed the steps for mosquito eradication.</p>
            <p>Zambia’s Gifteria School also undertook a malaria prevention project. Students, parents, and teachers
              worked together to clean up the local environment and drain standing pools where mosquitoes could
              breed. Two local orphanages were especially vulnerable, due to their location near a stream. The
              Gifteria students visited these orphanages and the surrounding community, mosquito-proofing the
              landscape and giving treated nets to those in greatest need.</p>
            <p>By enhancing their projects through “wisdom exchanges,” each group of global learners accomplished
              more than they could have on their own. At present, the students continue their public health
              advocacy to improve the wellbeing of their human family.<br /></p>
            <p><em>Help FCL’s students slap out malaria.</em></p>
            <p><a href="/friends/how-you-can-help" className='inquires'>Please click below to donate
              today.</a></p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style={{ "textAlign":"center" }}>
              <div><img
                src={twocountriesexchange}
                loading="lazy" alt="Young students walk carrying a banner" /></div>
            </figure>
            <h1>How to Unify A Divided Nation</h1>
            <h2>Students spark global change as they dance their way to world peace</h2>
            <h3>Two Countries Exchange Wisdom for Togetherness</h3>
            <p>World peace begins with the leaders of the future. This spring, a determined group of students in
              Zambia and the Gambia worked together across borders to bring peaceful change to their
              countries.<br /></p>
            <p>In both countries, political leaders have been using tribalist language to pit ethnic groups against
              each other. In Zambia, this rhetoric amplifies the tensions between the nation's 72 tribes, and
              similar divisions are growing among the 8 tribes that call the Gambia home.<br /></p>
            <p>Students at FCL schools in the two countries independently recognized the growing problem, setting a
              course towards peace by organizing marches, conflict resolution demonstrations, and celebrations of
              cultural diversity.<br /></p>
            <p>When the two groups of students realized their goals were the same, their schools began to study the
              habit-of-heart “Appreciation of Diversity.” They connected internationally, sharing global ideas to
              create local change.<br /></p>
            <p>In both nations, students walked in the streets and gave bold speeches, advocating for unity. In the
              Gambia, an open mic allowed passersby to give their own speeches in the marketplace, which meant the
              whole community could contribute to the spirit of togetherness illuminating the gathering of
              hundreds. In Zambia, some said they would take the messages back to their villages.<br /></p>
            <p>The schools in both countries held cultural festivals to celebrate the diverse heritage of the many
              tribal groups living side by side. While enjoying the poetry, drama, songs, and traditional dances,
              students and guests expressed gratitude that they finally felt they had a place where they all
              belonged. In Zambia, the 50 students’ performances reached a wide TV and radio audience, in addition
              to bringing joy to the guests physically in attendance.&nbsp;<br /></p>
            <p>One Zambian grandmother, Mrs. Museteka, said in praise of the students’ work:<br /></p>
            <p>“We are one people. One nation. One human family.”</p>
            <p><em>Help FCL’s students bring peace to our human family. </em></p>
            <p><a href="/friends/how-you-can-help" className='inquires'>Please click below to donate
              today.</a></p>
            <p>&zwj;</p>
          </div>

        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>While building a medical clinic in Zambia around 2006-7, Dr.Farzin Rahmani had a life-changing ride
              in a cab. He expressed concern about the village schools, few and far between, which lacked basic
              infrastructure and trained teachers. He wished he knew a way to help. The cab driver turned around
              and took him to his sister’s school, Blessed Vale, where Beauty Nzila had begun teaching her
              children and had attracted the attention of neighbors seeking classes for their own children. She
              set up an outdoor school under a tree and eventually engaged volunteer teachers to help. When Dr.
              Rahmani saw the operation, he knew he had to have his contractors help construct a real school.
              Three buildings later, EDI arranged for a Full-Circle Learning teacher-capacity-building workshop,
              taught by Maureen Mungai.</p>
            <p>By 2020, more than a dozen schools in Zambia had been trained, and many had conducted effective
              community-building projects.Some of the Blessed Vale students had earned scholarships to medical
              school and later returned to educate other learners. Here are a few
              of many highlights from the schools of Zambia.</p>
          </div>
          <div className="rich-text-block w-richtext">
            <ul>
              <li>Early in Zambia’s history, primary students in a rural community applied their skills to
                persuade a rural mayor to electrify his community for the benefit of the health clinic and the
                schools. He changed his policies based on their presentations. Just in case he could not follow
                through, the students began a study of alternative energies. </li>
              <li>The Gifteria School made compassionate service a year-round weekly tradition.Compassion Day at
                Gifteria School evolved after a learning unit on the habit-of-heart Compassion, after which the
                children implored school leaders to set aside a portion of every Wednesday afternoon ensuring
                that nearby elders had food and water and teaching the preschool children. </li>
              <li>Mildred Academy’s fourth graders set out to reduce traffic deaths. They integrated the habit of
                Unity into a math and music project. They taught addition and subtraction with rocks, then sang
                to neighbors while demonstrating how to put the rocks into the potholes that had increased due
                to frequent flooding. Their efforts reduced the incidence of traffic injuries and deaths, while
                bringing joy to the community. </li>
              <li>Two years later, ten schools engaged in a project to teach autism awareness and appreciation of
                differences. Their songs and role-plays made a deep impact on their villages.</li>
            </ul>
            <ol > </ol>
            <p>In the pictures: Dr. Farzin Rahmani visited the staff of Blessed ValeSchool. He also introduced the
              project to Kenneth Kaunda, Zambia’s first president. 70 teachers formed a virtual “nest” to
              represent their role in the students’ lives. FCL facilitator Eric Muleya helped students reduce
              stigma and honor the skills and needs of diverse learners. Teachers from six schools participated in
              a Full-Circle Learning workshop in 2020. Students used math to learn social distancing. Two teachers
              learned the process of conflict resolution. Blessed Vale Students conducted an Advocacy project to
              encourage education in 2012. Their efforts to inspire street children to attend school doubled the
              population of their school. </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <ol > </ol>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
          </div>
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>




      </Row>
    </Container>
  )
}

export default Zambia