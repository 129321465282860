import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Tanzania1 from '../../assets/images/Tanzania1.png';
import Tanzania2 from '../../assets/images/Tanzania2.png';
import Tanzania3 from '../../assets/images/Tanzania3.png';

const images = [
  {
    src: Tanzania1,
    title: 'Teachers and students in blue school uniforms hold kindling.'
  },
  {
    src: Tanzania2,
    title: "Students with red tops and blue bottoms work on a project."
  }
];
const images1 = [
  {
    src: Tanzania3,
    title: 'A group of Teachers sitting at a table with Full-circle Learning materials.'
  }
];
const Tanzania = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Tanzania</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Teachers at the Dinka school, in Arusha, learned the Full-CircleLearning model from Maureen Mungai,
              when alumna Gretchen Newcomb funded a project there.</p>
            <p>As of 2020, orphaned girls continued to conduct service projects at the Scion Center, such as sewing
              bags for elders in the community and performing the Unity Circle Dance for a distance learning
              course. Frank Mollel conceived projects to help girls learn both job skills and life skills.</p>
            <p>One inspiring wisdom exchange had occurred in 2016, when theArusha students realized that the
              classrooms were filled with only boys, as the girls were out collecting firewood. The deforestation,
              as well as girls’ education, became important to the boys. </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p>&zwj;</p>
          </div>
          <div className="rich-text-block w-richtext">
            <p>The boys designed a plan to teach the adults to make dung patties for cooking, to replace kindling as
              the primary fuel source. They felt happy when the community learned<br />this process and the girls
              returned to school. Meanwhile, theClimate Change Agents camp in Nevada County compared dung patties
              with solar energy. They made a sample of a portable cardboard solar oven and sent it to Tanzania
              with orphanage co-owner Frank Mollel, who trained the students to use recycled materials to make
              more ovens.</p>
            <p>In two months, the American students received news that the solar ovens, heretofore unheard of in the
              community, had caught on. Everyone was using them, with training provided by the girls and boys at
              the school, who now learned together.</p>
            <p>Below, teachers gather at a training session at the Arusha School. </p>
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>




      </Row>
    </Container>
  )
}

export default Tanzania