import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import twokidsreading from '../../assets/images/twokidsreading.png';
import lesotho1 from '../../assets/images/lesotho1.png';
import lesotho3 from '../../assets/images/lesotho3.png';
import lesotho4 from '../../assets/images/lesotho4.png';
import lesotho5 from '../../assets/images/lesotho5.png';
import lesotho6 from '../../assets/images/lesotho6.png';
import lesotho7 from '../../assets/images/lesotho7.png';
import lesotho8 from '../../assets/images/lesotho8.png';

const images = [
  {
    src: twokidsreading,
    title: 'Two children reading in sunlight'
  },
  {
    src: lesotho1,
    title: 'A group of school children outside of class.'
  },
  {
    src: lesotho3,
    title: 'Children in a half circle singing.'
  },
  {
    src: lesotho4,
    title: 'A group of children in class wearing read sweaters raise their hands towards the camera.'
  }
];

const images1 = [
  {
    src: lesotho5,
    title: "A group of Teachers holding certificates at a Teacher's college."
  },
  {
    src: lesotho6,
    title: "A teacher teaching a class with students actively participating; raising their hands."
  },
  {
    src: lesotho7,
    title: "A children's class holding up drawings."
  },
  {
    src: lesotho8,
    title: 'A child smiling up at the camera with lunch in lap.'
  }
];

const Lesotho = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Lesotho</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Lesotho is a high mountain kingdom, surrounded on all sides by South Africa. The first project in
              Lesotho began when Full-Circle Learning was asked to provide teacher training and student service at
              a preschool in Mokhotlong, a town in the highlands. </p>
            <p>Soon the project expanded due to requests for teacher training in the surrounding schools. Six
              schools, from kindergarten to high school received weekly support. </p>
            <p>Learners turned the habit-of-heart into a fruitful outcome for their community when they studied the
              relationship between deforestation, hunger, and long-term planning. On a barren plateau, they grew
              trees, to offer the shade that would moisten the soil, prevent erosion and prepare for new
              agricultural projects. Here, students sang a traditional song urging the rain to fall and nourish
              the trees. </p>
            <p>Years later, the children, as young adults, saw the results of their farsightedness. Tall trees
              dotted the lush plateau. Government officials took note and realized that the FCL approach supported
              their sustainable development needs and reinforced their emerging educational goals.</p>
          </div>
          <div className="rich-text-block w-richtext">
            <p>Full-Circle Learning supported the government’s plan to introduce ethics, resiliency, sustainability,
              and spiritual development as learning outcomes, infused into all subjects in the new syllabus. In
              early 2020, Full-CircleLearning helped advance implementation of the progressive plan.</p>
            <p>The curriculum development department assigned eight pilot schools in the capitol city, Maseru, and
              invited its own staff, along with members of the Education Ministry and faculty from Lesotho
              Teacher’s College, to attend. Pictured here, one of three groups, integrating various institutions,
              earned certificates for the planning skills earned in the course. </p>
            <p>Over the years, the Basuto children at the grassroots had engaged in FCL projects. Students atSt.
              Peter’s eagerly received a friendship-spreading challenge from their wisdom exchange partner, a
              class at the Full-Circle Learning Academy in Los Angeles.</p>
            <p>Early service projects included student-made cards for hospital wishes (right). Teachers also
              dismantled stigmas against HIV and albino students. Principals raved about the success of the
              conflict resolution practices.&ZeroWidthSpace; &ZeroWidthSpace;On the next page, a happy learner was
              one of the early recipients of FCL service in Mokhotlong.</p>
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>

      </Row>
    </Container>
  )
}

export default Lesotho