import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US1 from '../../../assets/images/US1-p-500.png';
import US2 from '../../../assets/images/US2-p-500.png';
import US3 from '../../../assets/images/US3-p-500.png';
import US4 from '../../../assets/images/US4.png';
import US5 from '../../../assets/images/US5-p-500.png';
const images = [
    {
        src: US1,
        title: "Two students present a buffet of food"
    },
    {
        src: US2,
        title: "Students patiently working on classwork"
    }, {
        src: US3,
        title: "Sonny Douglas with students in Lesotho."
    }, {
        src: US4,
        title: "A student in className peers into a microscope"
    }
];
const images1 = [
    {
        src: US5,
        title: "Students standing in front"
    }
];
const ChildrensEnrichment = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({src: '', title: ''});

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = arrayName === 'images' ? images : images1;
        toggleModal();
        setCurrentIndex(selectedArray[imageId]);
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className='text_font_line'><strong>Children’s Enrichment Summer and After-school Programs</strong></h1>
                    <h2 className='mt-4'><strong>A Model Emerges</strong></h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} />
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='mt-3'
                >
                    <p><strong>&nbsp;</strong>Many students in the original pilot program, here, increased their grade
                        equivalency and understanding of relevant purpose over the first two years in the program.&nbsp;</p>
                    <p>&nbsp;Inspired by integrated learning, they set purposeful humanitarian goals in elementary school. Many
                        of those tracked by FCL grew up to pursue professions in health care, education, academia the arts.</p>
                    <p>&nbsp;Students hosted a library reading by one of their classmates who had illustrated a book to help
                        early learners aspire to serve.</p>
                    <p>&nbsp;The bilingual book, <em>Animal Aspirations</em>, illustrated by Melissa Douglas, especially became
                        a favorite for Spanish readers in distant countries and in the Full-Circle Learning preschool.</p>
                    <p>&nbsp;The Full-Circle Learning model delivered at the pilot site in Los Angeles, California featured an
                        average of 14 service-learning projects each year, each one culminating from habit-of-heart units that
                        linked social cohesion, applied learning, and community building. The overnight experience at a dude
                        ranch taught students to groom and care for animals in the mid-1990s</p>
                    <p>&nbsp;Science lessons one year included a study of healthy human cells, turned into paintings to offer
                        oncologists at the hospital, to thank them for their service. The class sang to teary-eyed nurses and
                        left hats for patients undergoing chemotherapy. Each student also sent a letter to the National Health
                        Institute (NIH) to suggest a cancer research question for which the community needed answers. The unit
                        was inspired by a student who lost his father to leukemia.</p>
                    <p>&nbsp;The elementary school students later formed an Alumni Club and continued their local and global
                        service projects throughout high school and beyond, sustaining the commitment through their university
                        studies and professional careers.</p>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                    style={{display: "flex"}}
                >
                    {images1.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images1")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} />
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default ChildrensEnrichment