import React from 'react'
import {
  Row, Col, Container
} from "reactstrap";

const Tonga = () => {
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Tonga</h1>
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p> Tonga sits among the islands in a South Pacific archipelago, a flight away from Fiji, Tuvalu, and
              other islands increasingly submerged by sea rise. On the island of Tonga, however, environmental
              challenges and joblessness were the most challenging threats when Full-Circle Learning arrived. The
              people met these challenges with music and sharing. If mango trees and baked taro root were
              plentiful at one house, everyone would eat. </p>
            <p> Ocean of Light School hosted a weeklong Full-Circle Learning workshop for teachers. Some of the
              teachers discovered their own nobility at the workshop. A math teacher who wondered how to introduce
              integrated learning had an epiphany during his group’s project design experiment. The students would
              conduct an environmental advocacy project, including an installation at the public exhibit space for
              the environment, where tourists and local officials both spent time. While the science teacher, the
              music and writing teachers would all have a role, he would teach geometry to help the learners build
              cubes on which the installation pieces would sit. A light came on in his eyes when he saw the value
              of teacher collaboration for math teachers. </p>
            <p> Watching the dances of the teachers and students later inspired aFull-Circle Learning song about
              shrinking islands, which was performed at schools in North America to raise awareness of climate
              change issues.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Tonga