import React from 'react'
import {
    Row, Col, Card, Container
} from "reactstrap";
const ImprovingTheWorld = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Card>
                        <h1>Improving the World</h1>
                        <p className='mt-3 line_height'><strong>Full-Circle Learning (FCL), an award-winning global 501 (c) 3 charitable organization, based in the US, helps young people aspire to improve the world in which we all live.</strong></p>
                        <p className='line_height'>By integrating life skills, academics, local service, and global wisdom exchanges, they attach greater purpose to learning, to altruism, and to positive change. Their schools contextualize habit-of-heart themes through academic units that tackle poverty, health disparities, elder care, food security, peace, climate change adaptations — or&nbsp;whatever&nbsp;will enhance their local communities, create systemic transformation, strengthen students’ skills and convictions, and uplift the human family.</p>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default ImprovingTheWorld