import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US77 from '../../../assets/images/US77-p-500.png';
import US78 from '../../../assets/images/US78.png';

const images = [
    {
        src: US77,
        title: "Wall mural that reads with head"
    },
    {
        src: US78,
        title: "Children read the mural"
    }
];
const HiddenOak = () => {
    const allImages = {
        images
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = allImages[arrayName];
        if (selectedArray) {
            toggleModal();
            setCurrentIndex(selectedArray[imageId]);
        }
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1>Hidden Oak reading program</h1>

                    <h2><strong>Full-Circle Learning – East US Coast</strong></h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} />
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>The late Nicki Tayler, far left, galvanized her senior community to introduce Full-Circle Learning into
                        kindergartens such as Hidden Oaks and Prairie View, in Gainesville, Florida.</p>
                    <p>Nearby Rawlings Elementary instituted a Full-Circle Learning program for all grade levels, with
                        encouragement from Nicki and board member Dr. Taraneh Darabi.</p>
                    <p>The Venus School, in Jacksonville, also received training.</p>
                    <p>Full-Circle Learning spread to New York, New Jersey and Rhode Island. Below, students presented the grand
                        finale at the Children’s Theater Company, a Full-Circle Learning initiative of board member Margie
                        Maher.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default HiddenOak