import React from 'react'
import {
  Row, Col, Container
} from "reactstrap";
import tablegroup from '../../assets/images/tablegroup-p-1080.png';
import smilinggroup from '../../assets/images/smilinggroup-p-1080.png';
import walkinggroup from '../../assets/images/walkinggroup.png';
import funds4 from '../../assets/images/funds4-p-500.png';
import united4 from '../../assets/images/united4.png';
import united3 from '../../assets/images/united3.png';
import united2 from '../../assets/images/united2-p-500.png';
import united1 from '../../assets/images/united1-p-500.png';
import scholarship2 from '../../assets/images/scholarship2-p-1080.png';
import scholarship1 from '../../assets/images/scholarship1.png';
import funds1 from '../../assets/images/funds1-p-500.png';
import funds from '../../assets/images/11funds.png';
import funds3 from '../../assets/images/funds3-p-500.png';
import d_funds2 from '../../assets/images/d_funds2.png';
import f_need1 from '../../assets/images/f_need1-p-1080.png';
import a_giving1 from '../../assets/images/a_giving1-p-1080.png';
import d_inkind1 from '../../assets/images/d_inkind1-p-1080.png';
import inkind2 from '../../assets/images/inkind2.png';
import StripForm from './StripForm';
const Howyoucanhelp = () => {
  return (
    <Container>
      <Row className='justify-content-center line_height'>
        <Col
          sm="12"
          md={{
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">How You Can Help</h1>
        </Col>

        {/* <div className="section-74">
          <div className="w-container">
            <div className="w-embed w-script">
              <div data-idonate-embed="1e4feb84-ac1a-4b4d-8cb6-abf240863d55">
                <div className="idonate-embed-loader-animation">

                </div>
                <div id="loadingCheck style_inline">done</div>
                
              </div>

            </div>
          </div>
        </div> */}
        <Col
          sm="12"
          md={{
            size: 10
          }}
          className='my-2'
        >
          <StripForm />
          <p className="paragraph"><strong><em>A “Greatest Need” Contribution (preferred) helps FCL respond to immediate needs in any or all categories. Your kindness will help transform lives in the following ways:</em></strong></p>
          <p><strong>Capacity-Building: $1,500 - $2,000</strong> will provide capacity building toolsand training throughout a region (up to twenty schools), for academic educationthat addresses the humanitarian needs of local and global communities.</p>
          <p>In any given year, thousands of teachers in at least a dozen countries lean how tointegrate habits-of-heart, academics, conflict resolution and community serviceinto every learning unit. They learn how to adapt teaching strategies and for arelevant culture of transformation in their locales.</p>
          <div>
            <img src={tablegroup} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px" alt="Teachers consult together at a table working through plans." />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10
          }}
          className='my-2'
        >
          <p className="paragraph"><strong>School Project Grants: $500 or less provides an exemplary school project grant</strong>, to match local in-kind materials and efforts with those few remaining resources needed. Students apply academic skills and a habit-of-heart toward a need for community transformation. (Sample projects address topics such as tribal unification, health disparities, poverty, food security, malnutrition, elder care, clean energy, environment, gender equity, or peace through nonviolent conflict resolution.)&nbsp;</p>
          <p>Teachers and students expand their sense of vision and purpose as they improve the well-being of others.&nbsp;</p>
          <div>
            <img src={smilinggroup} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px" alt="Students gather for a outside on green grass." />
            <img src={walkinggroup} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 649px) 95vw, 617px" alt="A group of students walk together carrying a banner." />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10
          }}
          className='my-2'
        >
          <p className="paragraph"><strong>Girls United Projects: $200-$1,000 helps girls evolve into community leaders</strong>. Girls United Clubs form in regions where gender equity, gender violence and forced childhood marriage have influenced national goals, United Nations Sustainable Development goals, and local educators’ plans for their FCL learning units. Girls from multiple FCL schools gather together and encourage one another to stay in school and to use their voices to encourage transformation. Their interests have turned toward community building projects such as climate change projects, agriculture projects and arts projects. On one region, they are constructing a peace garden in Liberia for national use. In another area, they have developed a chicken and goat project, to learn business skills as they raise funds to assist the elders in their community. The increasing complexity of their community-building projects is enhanced by FCL funding support.&nbsp;</p>
          <div style={{ "columnCount": "2" }}>
            <img src={funds4} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px" alt="A group of students at a service project." />
            <img src={united4} loading="lazy" alt="A woman presents a painting while talking." />
            <img src={united3} loading="lazy" alt="Two students in a vehicle looking out." />
            <img src={united2} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px" alt="A group gathers for." />
            <img src={united1} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px" alt="A student begins to climb a ladder assisted by her Teacher." />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10
          }}
          className='my-2'
        >
          <p className="paragraph"><strong>Scholarships: $100-$300 will partially fund a scholarship </strong>for a student living in a vulnerable community. In some regions, public, private or village schools all must charge tuition, inhibiting the education of students who want to contribute to the transformation of society. In the US and abroad, FCL does not charge tuition for its services but, rather, offers limited school scholarships to highly motivated students who lead transformational community initiatives based on their sense of purpose and apply their skills to improve life for others, despite personal hardship. See the home page for information about our scholarship partners and requirements.</p>
          <div>
            <img src={scholarship2} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px" alt="A student is sharing knowledge with the rest of the className seated outside." />
            <img src={scholarship1} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 788px" alt="A student presents a paper." />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10
          }}
          className='my-2'
        >
          <p className="paragraph"><strong>Humanitarian Funds help when needed. </strong>During emergencies such as the Covid-19 pandemic or the Ebola outbreak, schools were locked down and families who depended on school lunches suffered hunger. The elderly in many regions also could not access food. Special funds, in these cases, enable teachers to distribute assistance to the most vulnerable members of their community. Opportunities to give in this category are announced when needed.&nbsp;</p>
          <div style={{ "columnCount": "2" }}>
            <img src={funds1} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px" alt="A group stands behind piles of dry food." />
            <img src={funds} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px" alt="A group of students at a service project." />
            <img src={funds3} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px" alt="A boy stands outside his home." />
            <img src={d_funds2} loading="lazy" alt="Two students sharing a project." />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10
          }}
          className='my-2'
        >
          <p className="paragraph"><strong>Greatest Need: Yes, donations of any amount </strong>generate great appreciation from learners and their communities, as you see in the waving hands of children in an orphan school who learned biology by growing food to feed their whole community. (FCL gave seed money.) While earmarked funds may occasionally divert resources into one area and away from another line item in which funds are more urgently needed, <strong><em>Greatest Need </em></strong>contributions ensure that we can meet the worthy requests of every country, teacher and child who shares their vision of a better worlds.&nbsp;</p>
          <div>
            <img src={f_need1} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px" alt="Students greet the camera from afar surrounded by green farm fields." />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10
          }}
          className='my-2'
        >
          <p className="paragraph"><strong>Legacy Giving: Consider passing the balloon. </strong>Just as these children used helium balloons to represent their wisdom exchanges to brothers and sisters around the world, your gifts can keep uplifting the next generation. Feel free to download a <a href="https://fullcirclelearningassets.s3.amazonaws.com/Confidential-Legacy-Circle-Enrollment-Form.docx" target="_blank" rel="noreferrer" className="link-11"><strong>Legacy Circle Form</strong></a><strong> </strong>and talk with your legal advisor about how to implement it.</p>
          <div>
            <img src={a_giving1} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px" alt="Two children play with a ball." />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10
          }}
          className='my-2'
        >
          <p className="paragraph"><strong>In-kind professional service can also generate support. </strong>Over time, FCL will expand its development (fundraising) staff. If you have a strong track record as an Executive Director or Director of Development and are in a position to join the team on a <em>pro-bono, long-term basis </em>to offer grant writing or donor relations support (not in an advisory capacity but as a part of the team), please send your resume to <a href="mailto:info@fullcirclelearning.org?subject=In-Kind%20Professional%20Services" className="link-12">info@fullcirclelearining.org</a>; write <em>In-Kind Professional Services </em>in the inbox.&nbsp;</p>
          <div>
            <img src={d_inkind1} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px" alt="Teresa meets with two teachers at a table outside." />
            <img src={inkind2} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px" alt="Five women gather." />
          </div>

        </Col>
      </Row>
    </Container>
  )
}

export default Howyoucanhelp
