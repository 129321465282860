import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import e_image6 from '../../assets/images/e_image6-41.jpeg';
import gambia_image7 from '../../assets/images/gambia_image7-43.jpeg';
import c9_image8 from '../../assets/images/c9_image8-45.jpeg';
import gambia1 from '../../assets/images/gambia1.png';
import gambia2 from '../../assets/images/gambia2.png';
import youngtreeplanters from '../../assets/images/youngtreeplanters.png';
import twocountriesexchange from '../../assets/images/twocountriesexchange.png';
import Gambia4 from '../../assets/images/Gambia4.png';
import c_Gambia2 from '../../assets/images/c_Gambia2.png';
import d_Gambia3 from '../../assets/images/d_Gambia3.png';
import f_Gambia4 from '../../assets/images/f_Gambia4.png';
import aa_Gambia5 from '../../assets/images/aa_Gambia5.png';
import Gambia7 from '../../assets/images/Gambia7.png';
import Gambia6 from '../../assets/images/Gambia6.png';


const images = [
  {
    src: Gambia4,
    title: 'Students laying brick in a formation in a garden'
  },
  {
    src: c_Gambia2,
    title: "Students gathered holding a banner with a drawing of a building"
  },
  {
    src: d_Gambia3,
    title: 'Students planting a new tree'
  },
  {
    src: f_Gambia4,
    title: 'Students in class talking'
  }
];
const images1 = [
  {
    src: aa_Gambia5,
    title: 'Students at work on a long table lit above by woven straw covered lights'
  },
  {
    src: Gambia7,
    title: "A teacher posing with a student"
  },
  {
    src: Gambia6,
    title: 'A teacher and her class of young children'
  }
];
const TheGambia = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">The Gambia</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="w-richtext">
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                src={e_image6}
                loading="lazy" alt="Students gathered around a plate, smiling " /></div>
            </figure>
            <h2><strong>The Most Delicious Thing Under the Sun: Unity</strong></h2>
            <p>Science, engineering and unity blended as key ingredients this month when climate concerns inspired
              Gambian youth to act. They set out to construct solid solar ovens, to promote energy resilience.
              Next, they prepared tasty recipes to test the capacity of the ovens. One participant said, “The
              unity each person showed throughout the whole process made me very happy.”&nbsp;</p>
            <p>Their connections will soon multiply as the students teach their community to use the ovens and then
              engage in a wisdom exchange beyond borders, sharing common goals and best practices with global
              peers. The Gambia’s facilitator, Alagie NDow, wrote:</p>
            <blockquote>“This unique education model uses customized… flexible tools that can be integrated into any
              learning format to guide global education and provide pathways to opening up the road map of peace
              as the habits of hearts are being nurtured and uniting with self-mastery to reach a solution for
              human destiny. FCL, through its Girls United project, also equips girls to achieve academic growth,
              [and] motivates good virtues, which link academic skills and community service projects, to address
              relevant goals such as economic and gender issues, public health, the environment, arts and conflict
              resolution.”</blockquote>
            <h2><strong>Gambian students bring renewable energy to town</strong></h2>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                alt="my alt"
                src={gambia_image7}
                loading="lazy" /></div>
            </figure>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                alt="my alt"
                src={c9_image8}
                loading="lazy" /></div>
            </figure>
            <p>In just one hour, enough sunlight strikes the Earth to fuel all human energy needs for a year. In the
              same amount of time, a young person in the Gambia can now use a hand-built solar-powered oven to
              pasteurize water and cook a healthy meal to share.</p>
            <p>Students from three secondary schools, along with members of the local Girls United club, created a
              project to build solar ovens, feeding their communities with the renewable energy of the sun. The
              youth will help five schools convert to solar cooking to feed their communities,&nbsp;eliminating
              the need for girls to remain out of school collecting kindling, and reducing both deforestation and
              carbon emissions.</p>
            <p>With a grant of $650 from Full-Circle Learning, the students will learn the geoscience of energy use,
              the engineering and carpentry to build the ovens, and the nutrition behind home-cooked meals.
              Addressing four different United Nations Sustainable Development Goals, the students have centered
              their project around the habit-of-heart Cooperation, with students from diverse backgrounds working
              together.</p>
            <p>In the coming weeks, two more schools will join this joyful cooperative effort. In addition to
              sharing this knowledge with fellow students, the original group will also work together with inmates
              at the local prison, educating and training the prisoners in these skills to prepare them to rejoin
              the community.</p>
            <p>&zwj;</p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                alt="my alt"
                src={gambia1}
                loading="lazy" /></div>
            </figure>
            <h2>April 2022:&nbsp;A World Without Famine</h2>
            <p>As the growing season begins, Full-Circle Learning welcomes the news of two promising agricultural
              projects that are already feeding communities in the Gambia and Chad.</p>
            <p>At the Gambia’s Jalambang Lower Basic School, students embraced the habit-of-heart of cooperation to
              revitalize the school’s garden plot. Students secured the fence to keep animals from destroying the
              plants, sowed seed beds, and fed the soil with compost.</p>
            <p>The next phase of the project will take place not in the garden, but in the kitchen. Cooks will
              transform the produce into lunches for the schoolchildren and the community elders—two groups who
              would otherwise have nothing to eat. The school looks forward to holding a nutrition fair to teach
              the community about healthy foods and backyard gardening.</p>
            <p>One boy, Muhammad, said, “I am happy to participate in the garden because when we harvest from here
              it will be to cook for us, since our parents do not have money to give us bread every day. This
              garden will help us to eat healthy food. Last week we harvested our first tomatoes. We will also
              share with people in our community when all these plants are ready for harvest.”</p>
            <p>In Chad, students at the Lumiere, New Danmadji, and Shining Stars Schools planted vegetables to share
              with the community members most in need. 45 students from the Shining Stars School came together to
              offer a harvest of vegetables to the school system’s chief administrator, Layla Bride. The students
              humbly requested that Mrs. Bride deliver the produce to the hungry inmates of the local corrections
              center, which she did.</p>
            <p>Schools in both countries now share their best farming lessons in an international exchange of
              agricultural wisdom. Full-Circle Learning teacher trainer Pacifique NDouba, based in Chad, sees the
              seeds of a global revolution in the students’ gardens. When he learned of the Gambian children’s
              impacts, he commended them.</p>
            <p>“With the help of the necessary means that FCL offers us,” he said, “we will have a world without
              famine tomorrow.”</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                alt="my alt"
                src={gambia2}
                loading="lazy" /></div>
            </figure>
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth">
              <div><img
                src={youngtreeplanters}
                loading="lazy" alt="A young student digs at the earth while others look on" /></div>
            </figure>
            <h1>Planting a vision for tomorrow</h1>
            <h2>How do we grow the seeds of change?</h2>
            <h3>Today’s Ideas, Tomorrow’s Trees</h3>
            <p>What comes first – planting trees or planting ideas?&nbsp;<br /></p>
            <p>Children at the Gambia’s Sajuka Lower Basic School felt excited to take on the identity of Climate
              Change Agents this semester. They considered local environmental challenges before determining how
              their skills and actions could help reduce climate change impacts in the future.&nbsp;<br /></p>
            <p>They appealed to Full-Circle Learning (FCL) for help in creating a more sustainable environment with
              a tree planting project. The collaboration enabled the students to extend their learning from the
              classroom to the community and expand the carbon sink. To prevent erosion and flooding, students
              planted over 50 seedlings along the riverbanks, including coconut palms and endangered mahogany
              trees. In the image below, the team gather under the insect net to tend the new trees.<br /></p>
            <p>Gambia’s lead facilitator, Alagie NDow, said, “Parents, teachers and students are all part of the
              project, and it’s a great moment to see the joy in the students as they begin their first service
              project in their own community.”<br /></p>
            <p>FCL’s school grants nurture each generation to address climate change, poverty, hunger, health
              disparities, gender inequities and conflict—and to become well-schooled in the understanding that
              “to serve is to lead and to lead is to serve.”&nbsp;<br /></p>
            <p>Many thanks for following our summer series about the various ways schools use their FCL grants to
              improve lives in vulnerable communities.<br /></p>
            <p>As always, we extend a special thanks to those who contribute.<br /></p>
            <p><em>Thank you for supporting the leaders of the future. </em></p>
            <p><a href="/" className='inquires'>Please click below to donate
              today.</a></p>
            <p>&zwj;</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth">
              <div><img
                src={twocountriesexchange}
                loading="lazy" alt="Students walk carrying a banner" /></div>
            </figure>
            <h1>How to Unify A Divided Nation</h1>
            <h2>Students spark global change as they dance their way to world peace</h2>
            <h3>Two Countries Exchange Wisdom for Togetherness</h3>
            <p>World peace begins with the leaders of the future. This spring, a determined group of students in
              Zambia and the Gambia worked together across borders to bring peaceful change to their
              countries.<br /></p>
            <p>In both countries, political leaders have been using tribalist language to pit ethnic groups against
              each other. In Zambia, this rhetoric amplifies the tensions between the nation's 72 tribes, and
              similar divisions are growing among the 8 tribes that call the Gambia home.<br /></p>
            <p>Students at FCL schools in the two countries independently recognized the growing problem, setting a
              course towards peace by organizing marches, conflict resolution demonstrations, and celebrations of
              cultural diversity.<br /></p>
            <p>When the two groups of students realized their goals were the same, their schools began to study the
              habit-of-heart “Appreciation of Diversity.” They connected internationally, sharing global ideas to
              create local change.<br /></p>
            <p>In both nations, students walked in the streets and gave bold speeches, advocating for unity. In the
              Gambia, an open mic allowed passersby to give their own speeches in the marketplace, which meant the
              whole community could contribute to the spirit of togetherness illuminating the gathering of
              hundreds. In Zambia, some said they would take the messages back to their villages.<br /></p>
            <p>The schools in both countries held cultural festivals to celebrate the diverse heritage of the many
              tribal groups living side by side. While enjoying the poetry, drama, songs, and traditional dances,
              students and guests expressed gratitude that they finally felt they had a place where they all
              belonged. In Zambia, the 50 students’ performances reached a wide TV and radio audience, in addition
              to bringing joy to the guests physically in attendance.&nbsp;<br /></p>
            <p>One Zambian grandmother, Mrs. Museteka, said in praise of the students’ work:<br /></p>
            <p>“We are one people. One nation. One human family.”</p>
            <p><em>Help FCL’s students bring peace to our human family. </em></p>
            <p><a href="/" className='inquires'>Please click below to donate
              today.</a></p>
            <h2><strong>2021 - Clean Energy for a Sustainable Community</strong></h2>
            <p>Open flames present constant fire hazards for families who rely on charcoal or gas cookstoves.
              Students collaborating across borders recognized the triple benefits of combating climate change and
              air pollution while helping their communities convert to safer fuel sources.&nbsp;</p>
            <p>Knowing it may not be easy to overturn familiar cooking methods, Liberia’s Central Academy and the
              Gambia’s ABC Secondary School centered their project on the habit-of-heart of open-mindedness. After
              touring home kitchens and commercial bakeries, they used Zoom to discuss potential solutions and
              finally determined they would construct solar ovens from wood, glass, and foil.&nbsp;</p>
            <p>The ovens proved successful in baking bread, so the students took their knowledge to other schools,
              helping to install solar ovens in cafeterias and teaching other students how to build solar ovens
              for use at home. They created posters and songs about the importance of solar energy, encouraging
              their global community to replace harmful charcoal fuels with the inexhaustible power of the sun.
            </p>
            <h2><strong>2021 - Health as the Fruit of Love&nbsp;</strong></h2>
            <p>Mothers and children served by a convent in the Gambia suffered from starvation, malnutrition, and
              kwashiorkor. A lifetime of malnutrition leads to a sickly old age, and elders in the community bore
              these scars of poverty since childhood due to poor nutritional education.&nbsp;</p>
            <p>Teachers at Royal Seeds Academy began Operation Every Stage of Life Matters with their elementary
              students, practicing the habit-of-heart love to improve life for malnourished children. The students
              expanded their own learning about nutrition and then trained the convent’s young mothers. They
              provided food directly to struggling families—groundnuts, sorghum, millet, and fish. In addition,
              they offered training in conflict resolution, to help mothers make the case to their spouses for the
              importance of nutrient- rich food.&nbsp;</p>
            <p>Mr. Manjang Jammeh, from the malnutrition ward at the local hospital, came to the school and coached
              the children to integrate math percentages, art, and biology as they made nutrition charts. The
              students brought food supplies, soap and toys to the mothers and babies, singing songs of harmony
              and love. Participants included 92 children, teachers and community members.&nbsp;</p>
            <p>FCL Facilitator Alagie NDow commented, “The malnourished children are doing great, and the mothers
              are very overwhelmed and full of gratitude for the cache of food and information they have received
              from the project.” The hospital representative, Mr. Jallow, took on the project with vigor,
              appealing to the PTA for help and training the students as nutritional experts on how to provide
              balanced meals. One mother exclaimed that at last she would know how to improve the health of her
              baby.&nbsp;</p>
            <p>&zwj;</p>
          </div>

        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Schools in the Gambia have taken their Full-Circle Learning training to heart. In 2020-2021, Bakotech
              School students planned a tire recycling project. Wellingara learners began building a veranda at a
              marketplace, where children could find a clean place to eat. Sjauka School began planting trees
              along the riverbank. Royal Seeds students set out to teach nutrition to mothers and malnourished
              children at a convent. </p>
            <p>At least twenty schools, from preschool through college, had received Full-Circle Learning training
              by 2020. Resulting projects left a transformational impact on community life. </p>
            <p>For example, keyhole farming helped children learn to feed their whole neighborhood beginning in
              2015, inspiring wisdom exchanges with two other countries. This project was planned by Alagie Ndow
              at an NGO called MyFarm. Students at SolidFoundation School also took field trips to learn from the
              older students. </p>
            <p>In 2018, the <em>Our Place in Time</em> project brought tree
              plantings and climate change discussions at Bakotech, while Inspiring Stars sponsored a health
              clinic where 350 people attended to receive glasses from physicians. </p>
            <p> Another school, by 2019, had electrified its library with lampshades made by students. They were the
              first to adapt the region’s reed-basket making traditions for this purpose. </p>
            <p> Gambia’s Inspiring Stars sponsored a health fair in 2018, inviting eye specialists from major
              hospitals, who gave 85 exams and free eyeglasses to community members. At least 320 people attended
              the fair. The Kanifing Health Clinic, said, “Today there has been significant increase in attendance
              as a result of the intervention oft he service project completed by Inspiring Young Stars, an
              organization that builds most of its pillars from the concepts ofFull-Circle Learning."</p>
            <p> Another school, by 2019, had electrified its library and decorated it with lamp shades made by
              students. They were the first to adapt the region’s reed-basket making traditions for this purpose.
            </p>
            <p> When the pandemic ensued, Gambian students contributed to and participated in global distance
              learning programs. Public schools, as well as MyFarm, Solid Foundation Academy,
              andStarfish International, collaborated on joint projects during the pandemic. </p>
            <p> On the following pages: </p>
            <ol >
              <li>MyFarm students construct a keyhole farm in 2015. </li>
              <li>Inspiring Stars gave the gift of sight when they sponsored a health clinic. </li>
              <li>Students from two schools engaged in tree plantings and climate change discussions. </li>
              <li>Students studied under the light of their new invention. </li>
              <li>Solid Foundation students prepared for a field trip and participated in a mastery ceremony.
              </li>
            </ol>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>


      </Row>
    </Container>
  )
}

export default TheGambia