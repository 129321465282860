import React from "react";
import groupsun from "../../assets/images/groupsun-p-1080.png";
import { Row, Col, Container } from "reactstrap";

const HowWeOperate = () => {
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <h1 className="mt-4 mb-4 fs-1 fw-bold">How We Operate</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <div className="d-flex justify-content-center">
            <img
              src={groupsun}
              loading="lazy"
              sizes="(max-width: 767px) 94vw, (max-width: 991px) 698px, 910px"
              alt="A group of students pose for a group."
            />
          </div>
          <p>
            Full-Circle Learning (FCL), a 501-c-3 nonprofit organization,
            operates its headquarters in the Sierra Foothills of California, in
            the USA, but serves a global community.
          </p>
          <p>
            Philanthropic (corporate and foundation) grants and individual
            contributions fund our work.
          </p>
          <p>
            In-kind goods and services help stretch the budget (in most years by
            at least 30%).
          </p>
          <p>
            The effective streamlining of these funds enables FCL to serve more
            teachers, students and communities through direct service (typically
            at least 1,000,000 constituents per year).
          </p>
          <p>
            Full-Circle Learning’s non-profit board, currently with 12 members,
            has served the global community for almost three decades at this
            writing. All US members serve without pay.
          </p>
          <p>
            Guidestar, the research resource for grant providers, has accredited
            Full-Circle Learning with the platinum star, the highest rating for
            transparency and financial fitness.
          </p>
          <p>
            Great Nonprofits (#GreatNP2023) gives Full-Circle Learning its
            highest rating for participant reviews.
          </p>
          <p>
            Contributions may be received via check at the address here or
            through our donation button.
          </p>
          <p>
            Full-Circle Learning
            <br />
            17512 Brewer Road
            <br />
            Grass Valley CA 95949 USA
            <br />
            1-530-575-4500
            <br />
            <a href="/friends/how-you-can-help" className="inquires">
              /friends/how-you-can-help
            </a>
          </p>
          <h2>Partners</h2>
          <p>
            <a href="https://www.moxieboxart.com" className="inquires">
              www.moxieboxart.com
            </a>
            <br />
            <a href="https://www.monafoundation.org" className="inquires">
              www.monafoundation.org
            </a>
            <br />
            <a href="https://www.ncclimateacctionnow.org" className="inquires">
              www.ncclimateacctionnow.org
            </a>
            <br />
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default HowWeOperate;
