import React from 'react'
import {
  Row, Col, Container
} from "reactstrap";

import a_image1 from '../../assets/images/a_image1-31.jpeg';
import teachers from '../../assets/images/teachers with certificates in front of Ridvan School.jpg';



const ElSalvador = () => {
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">El Salvador</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="w-richtext">
            <figure className="w-richtext-align-center w-richtext-figure-type-image">
              <div><img
                alt="my alt"
                src={a_image1}
                loading="lazy" /></div>
            </figure>
            <h2><strong>What Does Transformation Look Like?</strong></h2>
            <p>Picture how conditions can change when children become the center of communities, not as
              mere&nbsp;<em>recipients</em>&nbsp;of service but as&nbsp;<em>those who serve</em>.<br /></p>
            <p>A Salvadoran neighborhood with a history of crime and poverty is changing. The Ridvan School is
              creating a community hub--a center of learning, culture and caring—with children leading the
              way.&nbsp;Ever since they began to study Full-Circle Learning, teachers have wrapped their
              integrated education projects around community development.<br /></p>
            <p>Each class owns a part of the plan. The fourth graders now prepare for an in-school community health
              center. The eighth graders designed an outdoor mural bursting with fields of flowers. Some classes
              beautify the courtyard with plants and fill the halls with song. Even the kindergarteners splash the
              walls with painted roses. Parents have rushed in to help as never before, inspired by the common
              vision now growing in their midst.<br /></p>
            <p>This year, Full-Circle Learning provided hurricane relief and emergency funds, but most important of
              all, capacity building. Now the children are transforming not only their learning space but the
              unity within their community. Step into their classrooms in the morning, when they introduce
              themselves to one another as members of the same human family. Watch them embrace. You too will feel
              their contagious love and envision a new reality, wherever you live.</p>
            <p>&zwj;</p>
            <h2><strong>Ridvan School Children Share Their Hearts</strong></h2>
            <figure className="w-richtext-figure-type-video w-richtext-align-center inline_style_elSalvador" style={{
              "padding-bottom": "109.23076923076923%",
              "position": "relative"
            }}>
              <div><iframe allowFullScreen
                src="https://player.vimeo.com/video/794367201"
                title="VIDEO-2023-01-30-10-14-02.mp4" style={{
                  "height": "100%",
                  "width": "100%",
                  "position": "absolute"
                }}></iframe></div>
            </figure>
            <p>“Hola. This is my name, and I am a member of the human family.”</p>
            <p>“Oh! This is my name, and I am also a member of the human family.”</p>
            <p>“So we’re brothers! Let’s hug!”</p>
            <p>This scene transpired on the day students first entered the classroom for the new schoolyear at the
              Ridván School, in Colon, El Salvador. The school had just received a weeklong capacity building
              training in January 2023, and immediately, joy began to light up the faces of children and teachers.
              Exotic birds held choral fiestas each morning, seemingly in celebration of the pure hearts engaged
              in this cause.</p>
            <p>School directors Beatriz and René Lemus encouraged the staff to adapt their instructional design and
              classroom management approaches to the relevant aspects of the training.&nbsp; In the first of their
              inspired integrated education projects, the teachers thus planned to not only help learners work in
              unity to beautify their school but also to practice the habit of sacrifice as public health
              advocates. They set a goal of installing a health clinic inside the school and building the capacity
              of parents and children to play a role in improving the wellbeing of the community.&nbsp;</p>
            <p>In a neighborhood fraught with economic and social issues over the decades, and in a country at risk
              of water-borne and vector-borne disease, with only 2.8 physicians per thousand, the Ridván School
              plans to deputize students to bring about greater health, safety, social cohesion, and concern for
              the good of all. They hope their Full-Circle Learning strategies will help them implement an
              integrated academic program inspired by loving service to humanity.&nbsp; &nbsp;</p>
            <p>The teachers expressed hope that the students will grow up learning that our connectedness in
              developing habits-of-heart also helps us unleash the potential of our minds. In turn, the
              learners—starting with the littlest preschoolers—can apply their unique and collective gifts to
              transform life for their human family.</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                src={teachers}
                loading="lazy" alt="Teachers with certificates in front of Ridvan school." /></div>
            </figure>
          </div>
        </Col>
        <div className="section-6">
          <div className="w-container">
            <div className="rich-text-block-2 color-link w-dyn-bind-empty w-richtext"></div>
            <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
          </div>
        </div>





      </Row>
    </Container>
  )
}

export default ElSalvador