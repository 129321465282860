import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Panama1 from '../../assets/images/Panama1.png';
import Panama2 from '../../assets/images/Panama2.png';
import Panama3 from '../../assets/images/Panama3.png';

const images = [
  {
    src: Panama1,
    title: 'A group of children playing together building an overhang of branchest'
  },
  {
    src: Panama2,
    title: 'A class gathers in front of their building'
  },
  {
    src: Panama3,
    title: 'A group of students sitting in the grass listening'
  }
];

const Panama = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Panama</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className=' my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p> Molejon sits high in the mountains of Panama, in Central America. Its residents are peaceful
              indigenous people with their own culture and native language. They can happily live without
              toothbrushes, shoes, bicycles, or car. Their village is a nine-mile walk from Soloy. Roberto, the
              director of the school, brought horses for Niaz and Nathan, the young visitors from theUS, who came
              bearing gifts during Full-Circle Learning’s Year of Love(2017). </p>
            <p> The volunteers had come with gifts from the students in Tarzana California.These students themselves
              originate from 44 nations and attend a public school on the outskirts of Los Angeles. They had
              studied various ways to build a roof, so they could exchange gifts of knowledge with their friends
              in Molejon, who are building a school. The Tarzana students first practiced ways to help their
              families “keep the love in and the rain out,” learning to build a treehouse that would weather
              storms. When the wisdom exchange arrived, in return, they were amazed to see the wisdom exchange
              that </p>
            <p> volunteer Niaz Khadem brought back to them from Molejon. The indigenous children had gathered
              sticks, leaves and vines to show their traditional process for building a roof adapted to their
              climate. Back home, the students could not believe the ingenuity of the brothers and sisters so far
              away. </p>
            <p> In the city of Badi, a long walk away, the school told of other love projects they had also
              completed, to share their ideas with children around the world. </p>
            <p> The Tarzana students, meanwhile, held a hikeathon and readathon, climbing a mountain to experience
              the challenge of collecting raw materials to build a school. </p>
            <p> They made a sacrifice basket for the students in Molejon, donating building funds earned through
              their pledges, along with items they had personally sacrificed to offer as friendship gifts. Some
              students reported that this project initiated their love of reading, nature and culture, while
              expanding the ways they showed love.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Panama