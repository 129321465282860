import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Input,
  Label,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../helpers/CheckoutForm";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const StripForm = () => {
  const [amount, setAmount] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState("");

  const handleAmount = (e) => {
    const parsedAmount = parseFloat(e.target.value);
    setAmount(isNaN(parsedAmount) ? "" : parsedAmount);
  };
  const payOneTime = () => {
    setMode("oneTime");
    setShowForm(true);
  };
  const paySubscribe = () => {
    setMode("on_subscription");
    setShowForm(true);
  };
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 2,
            size: 8,
          }}
          className="my-5"
        >
          {showForm ? (
            <Col sm="12">
              <Elements stripe={stripePromise}>
                <CheckoutForm amount={amount} mode={mode} />
              </Elements>
            </Col>
          ) : (
            <Col sm="12">
              <Form>
                <Label>Amount</Label>
                <InputGroup>
                  <InputGroupText
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "inherit",
                      padding: 0,
                    }}
                  >
                    $
                  </InputGroupText>
                  <Input
                    type="number"
                    className="m-2 pl-2"
                    value={amount}
                    onChange={handleAmount}
                    name="amount"
                    addOnBefore="$"
                  />
                </InputGroup>
                <Button className="m-2 donate-btn" onClick={payOneTime}>
                  One time donation
                </Button>
                <Button className="m-2 donate-btn" onClick={paySubscribe}>
                  Monthly subscription donation
                </Button>
              </Form>
            </Col>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default StripForm;
