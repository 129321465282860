import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Israel1 from '../../assets/images/Israel1.png';
const images = [
  {
    src: Israel1,
    title: "Three boys playing in class and smiling at the camera"
  }
];

const Israel = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Israel</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>A wisdom exchange in 2018 helped American children showed gratitude for gardeners who had come from
              diverse countries to tend the gardens in Haifa, Israel. The children wanted them to feel comforted
              during their first trip away from home. The gardeners sent greetings and stories of their journeys
              and their humble work for the students and received comfort pillowcases in return.</p>
            <p>The pillowcase project had become a trademark for these Tarzana Elementary Habits-of-Heart club
              students beginning in2013, the year before the West African Ebola outbreak.Spiraling numbers of flu
              patients had lost their lives that year in their own city, inspiring the class to use their writing
              and art skills to conduct an influenza awareness program emphasizing hand washing. For their wisdom
              exchange, they made health-reminder flags from pillowcases and sent them to Liberia with board
              member Fariba Mahjour, who requested a wisdom exchange to learn what the Liberian students were
              doing to prevent illness in their community. Two months later, Ebola erupted in Liberia. As a result
              of their learning, the ten schools in Liberia who received pillowcases initiated a widespread
              handwashing campaign among 31 schools. Teachers and parents reported that lives at 31 schools were
              saved because of the handwashing campaign.</p>
            <p>The pillowcase project lived on. The Tarzana school students decorated more cases with words of
              comfort in 2019 and sent them abroad with travelers, to distribute comfort or gratitude to those far
              away from home, to offer a sense of home. Other pillowcases were sent as gifts of comfort for
              refugee children living in temporary housing in Mexico in 2019. (See
              Mexico.)</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>


      </Row>
    </Container>
  )
}

export default Israel