import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../components/home";
import Educator from "../components/Educators/index";
import Inquirers from "../components/Inquirers/index";
import About from "../components/AboutUs/index";
import Howyoucanhelp from "../components/Friends/Howyoucanhelp";
import Annualreports from "../components/Friends/Annualreports";
import Header from "../components/Header";
import Fclonline from "../components/Educators/Fclonline";
import TeacherResources from "../components/Educators/TeacherResources";
import Curricula from "../components/Educators/Curricula";
import Songs from "../components/Educators/Songs";
import StaffDevelopment from "../components/Educators/StaffDevelopment";
import ResiliencyUnit from "../components/Educators/ResiliencyUnit";
import EmpathyUnit from "../components/Educators/EmpathyUnit";
import HonorUnit from "../components/Educators/HonorUnit";
import HelpfulnessUnit from "../components/Educators/HelpfulnessUnit";
import ForgivenessUnit from "../components/Educators/ForgivenessUnit";
import UnityUnit from "../components/Educators/UnityUnit";
import LoveUnit from "../components/Educators/LoveUnit";
import CareSkillsCourse from "../components/Educators/CareSkillsCourse";
import WhoWeAre from "../components/Inquirers/WhoWeAre";
import WhereWeServe from "../components/Inquirers/WhereWeServe";
import WhatWeDo from "../components/Inquirers/WhatWeDo";
import HowWeOperate from "../components/Inquirers/HowWeOperate";
import History from "../components/Inquirers/History";
import Testimonials from "../components/Inquirers/Testimonials";
import MissionAndVision from "../components/AboutUs/MissionAndVision";
import ImprovingTheWorld from "../components/AboutUs/ImprovingTheWorld";
import CommunityImpact from "../components/AboutUs/CommunityImpact";

import Norway from "../components/Locations/Norway";
import UnitedStates from "../components/Locations/UnitedStates/index";
import AlumniClubs from "../components/Locations/UnitedStates/AlumniClubs";
import ChildrensEnrichment from "../components/Locations/UnitedStates/ChildrensEnrichment";
import HuntingtonHotel from "../components/Locations/UnitedStates/HuntingtonHotel";
import FullCircleLearningAcademy from "../components/Locations/UnitedStates/FullCircleLearningAcademy";
import PiruFullCircleLearningPreschool from "../components/Locations/UnitedStates/PiruFullCircleLearningPreschool";
import RanchoSespePiru from "../components/Locations/UnitedStates/RanchoSespePiru";
import SpurgeonSchoolFclProgram from "../components/Locations/UnitedStates/SpurgeonSchoolFclProgram";
import TarzanaElementary from "../components/Locations/UnitedStates/TarzanaElementary";
import ClimateChange from "../components/Locations/UnitedStates/ClimateChange";
import NorthernCalifornia from "../components/Locations/UnitedStates/NorthernCalifornia";
import DiabetesAwareness from "../components/Locations/UnitedStates/DiabetesAwareness";
import GrassValleyEducation from "../components/Locations/UnitedStates/GrassValleyEducation";
import HiddenOak from "../components/Locations/UnitedStates/HiddenOak";
import NavajoNation from "../components/Locations/NavajoNation";
import Mexico from "../components/Locations/Mexico";
import Guatemala from "../components/Locations/Guatemala";
import ElSalvador from "../components/Locations/ElSalvador";
import Haiti from "../components/Locations/Haiti";
import Panama from "../components/Locations/Panama";
import Colombia from "../components/Locations/Colombia";
import Ecuador from "../components/Locations/Ecuador";
import Guyana from "../components/Locations/Guyana";
import Bolivia from "../components/Locations/Bolivia";
import Brazil from "../components/Locations/Brazil";
import Senegal from "../components/Locations/Senegal";
import TheGambia from "../components/Locations/TheGambia";
import Liberia from "../components/Locations/Liberia";
import Ghana from "../components/Locations/Ghana";
import Cameroon from "../components/Locations/Cameroon";
import Nigeria from "../components/Locations/Nigeria";
import Chad from "../components/Locations/Chad";
import Ethiopia from "../components/Locations/Ethiopia";
import SouthSudan from "../components/Locations/SouthSudan";
import Uganda from "../components/Locations/Uganda";
import Kenya from "../components/Locations/Kenya";
import Rwanda from "../components/Locations/Rwanda";
import Tanzania from "../components/Locations/Tanzania";
import Congo from "../components/Locations/Congo";
import Zambia from "../components/Locations/Zambia";
import Lesotho from "../components/Locations/Lesotho";
import SouthAfrica from "../components/Locations/SouthAfrica";
import Israel from "../components/Locations/Israel";
import Afghanistan from "../components/Locations/Afghanistan";

import India from "../components/Locations/India";
import China from "../components/Locations/China";
import Japan from "../components/Locations/Japan";
import PapuaNewGuinea from "../components/Locations/PapuaNewGuinea";
import Australia from "../components/Locations/Australia";
import Vanuatu from "../components/Locations/Vanuatu";
import Tonga from "../components/Locations/Tonga";
import SearchResult from "../components/helpers/SearchResult";
import { SuccessProvider } from "../components/helpers/SuccessContext";
import SuccessPage from "../components/SuccessPage";

const MyRouter = () => {
  return (
    <SuccessProvider>
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/educator" element={<Educator />} />
            <Route path="/inquirers" element={<Inquirers />} />
            <Route path="/about-us" element={<About />} />
            <Route
              path="/friends/how-you-can-help"
              element={<Howyoucanhelp />}
            />
            <Route path="/friends/annual-reports" element={<Annualreports />} />
            <Route
              path="/educator/fcl-online-course-link"
              element={<Fclonline />}
            />
            <Route
              path="/educator/teacher-resources"
              element={<TeacherResources />}
            />
            <Route path="/educator/curricula" element={<Curricula />} />
            <Route path="/educator/songs" element={<Songs />} />
            <Route
              path="/educator/staff-development-outlines"
              element={<StaffDevelopment />}
            />
            <Route
              path="/learner/resiliency-unit"
              element={<ResiliencyUnit />}
            />
            <Route path="/learner/empathy-unit" element={<EmpathyUnit />} />
            <Route path="/learner/honor-unit" element={<HonorUnit />} />
            <Route
              path="/learner/helpfulness-unit"
              element={<HelpfulnessUnit />}
            />
            <Route
              path="/learner/forgiveness-unit"
              element={<ForgivenessUnit />}
            />
            <Route path="/learner/unity-unit" element={<UnityUnit />} />
            <Route path="/learner/love-unit" element={<LoveUnit />} />
            <Route
              path="/learner/care-skills-course"
              element={<CareSkillsCourse />}
            />
            <Route path="/friends/who-we-are" element={<WhoWeAre />} />
            <Route path="/friends/where-we-serve" element={<WhereWeServe />} />
            <Route path="/friends/what-we-do" element={<WhatWeDo />} />
            <Route path="/friends/how-we-operate" element={<HowWeOperate />} />
            <Route path="/friends/history" element={<History />} />
            <Route path="/friends/testimonials" element={<Testimonials />} />
            <Route path="/mission-and-vision" element={<MissionAndVision />} />
            <Route
              path="/improving-the-world"
              element={<ImprovingTheWorld />}
            />
            <Route path="/community-impact" element={<CommunityImpact />} />
            <Route path="/locations/norway" element={<Norway />} />
            <Route path="/locations/united-states" element={<UnitedStates />} />
            <Route path="/alumni-clubs" element={<AlumniClubs />} />
            <Route
              path="/childrens-enrichment-summer-school-and-after-school-programs"
              element={<ChildrensEnrichment />}
            />
            <Route
              path="/huntington-hotel-fcl-school"
              element={<HuntingtonHotel />}
            />
            <Route
              path="/full-circle-learning-academy"
              element={<FullCircleLearningAcademy />}
            />
            <Route
              path="/piru-full-circle-learning-preschool"
              element={<PiruFullCircleLearningPreschool />}
            />
            <Route
              path="/rancho-sespe-piru-summer-school-and-year-round-enrichment-program"
              element={<RanchoSespePiru />}
            />
            <Route
              path="/spurgeon-school-fcl-program"
              element={<SpurgeonSchoolFclProgram />}
            />
            <Route
              path="/tarzana-elementary-schoolwide-professional-development-and-habits-of-heart-club"
              element={<TarzanaElementary />}
            />
            <Route
              path="/climate-change-agents-camp-nevada-county"
              element={<ClimateChange />}
            />
            <Route
              path="/northern-california-community-education-initiative-cei"
              element={<NorthernCalifornia />}
            />
            <Route
              path="/diabetes-awareness-camp-co-hosted-by-levitate"
              element={<DiabetesAwareness />}
            />
            <Route
              path="/grass-valley-education-as-transformation-statewide-training"
              element={<GrassValleyEducation />}
            />
            <Route path="/hidden-oak-reading-program" element={<HiddenOak />} />
            <Route path="/locations/navajo-nation" element={<NavajoNation />} />
            <Route path="/locations/mexico" element={<Mexico />} />
            <Route path="/locations/guatemala" element={<Guatemala />} />
            <Route path="/locations/el-salvador" element={<ElSalvador />} />
            <Route path="/locations/haiti" element={<Haiti />} />
            <Route path="/locations/panama" element={<Panama />} />
            <Route path="/locations/colombia" element={<Colombia />} />
            <Route path="/locations/ecuador" element={<Ecuador />} />
            <Route path="/locations/guyana" element={<Guyana />} />
            <Route path="/locations/bolivia" element={<Bolivia />} />
            <Route path="/locations/brazil" element={<Brazil />} />
            <Route path="/locations/senegal" element={<Senegal />} />
            <Route path="/locations/the-gambia" element={<TheGambia />} />
            <Route path="/locations/liberia" element={<Liberia />} />
            <Route path="/locations/cameroon" element={<Cameroon />} />
            <Route path="/locations/chad" element={<Chad />} />
            <Route path="/locations/nigeria" element={<Nigeria />} />
            <Route path="/locations/ethiopia" element={<Ethiopia />} />
            <Route path="/locations/ghana" element={<Ghana />} />
            <Route path="/locations/south-sudan" element={<SouthSudan />} />
            <Route path="/locations/uganda" element={<Uganda />} />
            <Route path="/locations/kenya" element={<Kenya />} />
            <Route path="/locations/rwanda" element={<Rwanda />} />
            <Route path="/locations/tanzania" element={<Tanzania />} />
            <Route path="/locations/congo" element={<Congo />} />
            <Route path="/locations/zambia" element={<Zambia />} />
            <Route path="/locations/lesotho" element={<Lesotho />} />
            <Route path="/locations/south-africa" element={<SouthAfrica />} />
            <Route path="/locations/israel" element={<Israel />} />
            <Route path="/locations/afghanistan" element={<Afghanistan />} />
            <Route path="/locations/india" element={<India />} />
            <Route path="/locations/china" element={<China />} />
            <Route path="/locations/japan" element={<Japan />} />
            <Route
              path="/locations/papua-new-guinea"
              element={<PapuaNewGuinea />}
            />
            <Route path="/locations/australia" element={<Australia />} />
            <Route path="/locations/vanuatu" element={<Vanuatu />} />
            <Route path="/locations/tonga" element={<Tonga />} />
            <Route path="/search" element={<SearchResult />} />
            <Route path="/success" element={<SuccessPage />} />
          </Routes>
        </div>
      </Router>
    </SuccessProvider>
  );
};

export default MyRouter;
