import React from 'react'
import {
  Row, Col, Container
} from "reactstrap";

import happy from '../../assets/images/happy-teachers.jpg';


const Rwanda = () => {
  return (
    <Container>

      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Rwanda</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="w-richtext">
            <h1>Rwandan Teachers Foster the Next Generation of Peacemakers</h1>
            <p>Can education influence epigenetic trauma? The teachers of Rwanda replied with a resounding “yes”
              after three Kigali districts embarked on a Full-Circle Learning (FCL) training that benefited 1978
              students.</p>
            <p>Twenty-eight years - a full generation - after the 1994 genocide, Madam Uwera Gakumba, the deputy
              principal at Kariyeri Primary and Secondary School, commended the FCL model, saying the “holistic
              approach to education is exactly what the Rwanda’s education [system] and general community needs.”
            </p>
            <p>Madam Alphonsine, of Gatsata School, added, “this program will have a huge positive impact on the
              teachers, learners, and the community towards peace building, especially with Rwanda’s history of
              the genocide.”</p>
            <p>The Rwandan training program launched in 2023, to enhance the nation’s competence-based curriculum
              with a common vision and with peacemaking strategies that enhance altruistic identities, according
              FCL’s Eric Muleya, who traveled there from Zambia to conduct the training. He could see a positive
              impact within days. Some schools requested international wisdom exchanges, while others from
              outlying provinces asked that the pro bono service be expanded, for the sake of education and
              community development in their regions.</p>
            <p>The FCL capacity-building program was presented in collaboration with Dr. Eric NDushabandi’s Center
              for Research, Human Rights, and Democracy for Peace (CRHRD-4 PEACE), along with UYISENGANMANZI
              (UNM), These two Rwandan organizations work toward universal education and peace building to reverse
              the effects of the genocide.</p>
            <p>In the photo, Eric Muleya and FCL board member Sana Moussavi flank teachers who received their
              certificates in February 2023.</p>
            <figure className="w-richtext-figure-type-image w-richtext-align-center">
              <div><img
                src={happy}
                loading="lazy" alt="Happy teachers with their certificates" /></div>
            </figure>
            <p>&zwj;</p>
          </div>
        </Col>
        <div className="section-6">
          <div className="w-container">
            <div className="rich-text-block-2 color-link w-dyn-bind-empty w-richtext"></div>
            <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
          </div>
        </div>




      </Row>
    </Container>
  )
}

export default Rwanda