import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';
import Colombia1 from '../../assets/images/Colombia1.png';
import Colombia2 from '../../assets/images/Colombia2.png';

const images = [
  {
    src: Colombia1,
    title: 'A woman in a yellow skirt and wide brimmed hat'

  },
  {
    src: Colombia2,
    title: 'A young girl in a yellow dress smiling'
  }
];
const Colombia = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Colombia</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} style={{ height:"400px",objectFit:"cover" }}/>
            </Card>
          ))}
        </Col>
        
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
            <div className="rich-text-block-2 color-link w-richtext">
              <p>A trip to Colombia inspired a request from Ana Maria Castro. She wanted to identify the philosophy
                for a school she would build near her hometown, with Full-Circle Learning as her guiding vision.</p>
              <p>Ana Maria’s training process took her to America, where she toured Full-Circle Learning projects and
                worked as a teacher at a Climate Change Agents camp. There, she shared wisdom exchanges with the
                learners about the impact of flooding on the indigenous people near her village. The exchange
                expanded the understanding of<br />those whose lives she touched. Ana Maria took new ideas back to her
                own students in Bogota. We anticipate the day when her own school will be completed and ready to
                showcase the capacities of children living in the ruralarea near her hometown. </p>
              <p>Coraima, a small girl from Colombia, emigrated to the US anxious about the future having just escaped
                the dangers in her homeland. </p>
              <p>Upon graduating from the Full-Circle Learning program at Tarzana Elementary school, she gave an
                inspiring speech about her class’s goal to give homeless people a face. This habits-of-heart project
                had helped her act on her empathy, having struggled, with her mother, to leave their home in
                Colombia. She identified the program as an impetus for her achievements and for her strong
                motivation to learn and to give back to society.</p>
            </div>
            <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
          </Col>



      </Row>
    </Container>
  )
}

export default Colombia