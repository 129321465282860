const locations = [{
  "name": "Rwanda",
  "url": "/locations/rwanda",
  "marginTop": "23.3em",
  "marginLeft": "36.5em" 
},
{
  "name": "El Salvador",
  "url": "/locations/el-salvador",
  "marginTop": "17.8em",
  "marginLeft": "14.9em" 
},
{
  "name": "Uganda",
  "url": "/locations/uganda",
  "marginTop": "22.3em",
  "marginLeft": "36.5em" 
},
{
  "name": "Spain",
  "url": "/locations/spain",
  "marginTop": "90em",
  "marginLeft": "100em" 
},
{
  "name": "Senegal",
  "url": "/locations/senegal",
  "marginTop": "17em",
  "marginLeft": "28.5em" 
},
{
  "name": "Navajo Nation",
  "url": "/locations/navajo-nation",
  "marginTop": "13.7em",
  "marginLeft": "11.8em"
},
{
  "name": "Mexico",
  "url": "/locations/mexico",
  "marginTop": "16.7em",
  "marginLeft": "12.4em" 
},
{
  "name": "Israel",
  "url": "/locations/israel",
  "marginTop": "14.2em",
  "marginLeft": "37.9em" 
},
{
  "name": "Guyana",
  "url": "/locations/guyana",
  "marginTop": "19.2em",
  "marginLeft": "19.1em" 
},
{
  "name": "Guatemala",
  "url": "/locations/guatemala",
  "marginTop": "17.7em",
  "marginLeft": "14em" 
},
{
  "name": "The Gambia",
  "url": "/locations/the-gambia",
  "marginTop": "18.2em",
  "marginLeft": "28.6em"
},
{
  "name": "Congo",
  "url": "/locations/congo",
  "marginTop": "24.8em",
  "marginLeft": "36em"
},
{
  "name": "Colombia",
  "url": "/locations/colombia",
  "marginTop": "19.8em",
  "marginLeft": "17.5em"
},
{
  "name": "Cameroon",
  "url": "/locations/cameroon",
  "marginTop": "20.7em",
  "marginLeft": "33.2em" 
},
{
  "name": "Bolivia",
  "url": "/locations/bolivia",
  "marginTop": "25.7em",
  "marginLeft": "19.2em" 
},
{
  "name": "Australia",
  "url": "/locations/australia",
  "marginTop": "26.5em",
  "marginLeft": "57.5em" 
},
{
  "name": "Vanuatu",
  "url": "/locations/vanuatu",
  "marginTop": "26.5em",
  "marginLeft": "61.5em" 
},
{
  "name": "Lesotho",
  "url": "/locations/lesotho",
  "marginTop": "27.4em",
  "marginLeft": "36.2em" 
},
{
  "name": "South Africa",
  "url": "/locations/south-africa",
  "marginTop": "28em",
  "marginLeft": "34.5em" 
},
{
  "name": "Tanzania",
  "url": "/locations/tanzania",
  "marginTop": "23.7em",
  "marginLeft": "37.5em" 
},
{
  "name": "Zambia",
  "url": "/locations/zambia",
  "marginTop": "26em",
  "marginLeft": "36em" 
},
{
  "name": "Kenya",
  "url": "/locations/kenya",
  "marginTop": "22.3em",
  "marginLeft": "38em" 
},
{
  "name": "South Sudan",
  "url": "/locations/south-sudan",
  "marginTop": "20.8em",
  "marginLeft": "36em" 
},
{
  "name": "Ethiopia",
  "url": "/locations/ethiopia",
  "marginTop": "18.8em",
  "marginLeft": "37.5em" 
},
{
  "name": "Chad",
  "url": "/locations/chad",
  "marginTop": "18.8em",
  "marginLeft": "35em" 
},
{
  "name": "Nigeria",
  "url": "/locations/nigeria",
  "marginTop": "17.8em",
  "marginLeft": "33em" 
},
{
  "name": "Ghana",
  "url": "/locations/ghana",
  "marginTop": "19.5em",
  "marginLeft": "31em" 
},
{
  "name": "Liberia",
  "url": "/locations/liberia",
  "marginTop": "19.2em",
  "marginLeft": "29.2em" 
},
{
  "name": "Afghanistan",
  "url": "/locations/afghanistan",
  "marginTop": "13.7em",
  "marginLeft": "42.2em" 
},
{
  "name": "India",
  "url": "/locations/india",
  "marginTop": "16.7em",
  "marginLeft": "45.2em"
},
{
  "name": "Tonga",
  "url": "/locations/tonga",
  "marginTop": "25.2em",
  "marginLeft": "64.4em" 
},
{
  "name": "Papua New Guinea",
  "url": "/locations/papua-new-guinea",
  "marginTop": "22.5em",
  "marginLeft": "57.5em" 
},
{
  "name": "Japan",
  "url": "/locations/japan",
  "marginTop": "12.7em",
  "marginLeft": "56.6em" 
},
{
  "name": "Brazil",
  "url": "/locations/brazil",
  "marginTop": "23.7em",
  "marginLeft": "22.2em" 
},
{
  "name": "Ecuador",
  "url": "/locations/ecuador",
  "marginTop": "21em",
  "marginLeft": "17.3em" 
},
{
  "name": "Panama",
  "url": "/locations/panama",
  "marginTop": "19.2em",
  "marginLeft": "16.3em"
},
{
  "name": "Haiti",
  "url": "/locations/haiti",
  "marginTop": "16.7em",
  "marginLeft": "17.3em" 
},
{
  "name": "Norway",
  "url": "/locations/norway",
  "marginTop": "7.3em",
  "marginLeft": "32.5em" 
},
{
  "name": "China",
  "url": "/locations/china",
  "marginTop": "14.7em",
  "marginLeft": "51.7em"
},
{
  "name": "United States",
  "url": "/locations/united-states",
  "marginTop": "12.3em",
  "marginLeft": "9.4em"
}];
const inquirelists = [{
  id: 1,
  name: "Who We Are",
  url: "/friends/who-we-are"
},
{
  id: 2,
  name: "Where We Serve",
  url: "/friends/where-we-serve"
},
{
  id: 3,
  name: "What We Do",
  url: "/friends/what-we-do"
},
{
  id: 4,
  name: "How We Operate",
  url: "/friends/how-we-operate"
},
{
  id: 5,
  name: "History",
  url: "/friends/history"
},
{
  id: 6,
  name: "Annual Reports",
  url: "/friends/annual-reports"
},
{
  id: 7,
  name: "Testimonials",
  url: "/friends/testimonials"
},
{
  id: 8,
  name: "How You Can Help",
  url: "/friends/how-you-can-help"
},
]

const curriculaList = [{
  "heading1": "Why We Learn - Course Sessions",
  "para_1": "",
  "para_2":"Student-teachers explore new pedagogy and implementation practices. They build a coherent system of innovative instructional design and assessment techniques with positive underlying classroom management strategies to lay the foundation for altruistic identities, based on Full-Circle Learning research. Six programs, guided by slides, offer the instructor ready-made presentations or offer the self-guided learner tools for assignments and peer-reviewed assessment. The course can be taken in small cohorts, with results reported to an advisor. It comes complete with suggested schedules and an adaptable grading rubric.", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/Why-we-learn-session-content.pdf"
},
{
  "heading1": "Why We Learn: Reading Supplement",
  "para_1": "",
  "para_2":"In regions where printed books present cost impediments, small working groups or a whole class may read the chapter in a computer lab. The course is designed for online/onsite intentions, with primary session content occurring throughout the course and discussions of the optional reading content scheduled in interstitial sessions.", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/Why-we-learn-reader.pdf"
},{
  "heading1": "Our Place in Time",
  "para_1": "A Global Student Poetry Anthology", 
  "para_2":"Full-Circle Learners around the world compared local and global environments and explored ways to improve the world. The poems they wrote in 2019 helped them share their learning with one another. The schools depicted in the photos represent a range of Full-Circle Learning locations, not necessarily just the school of the poet. We offer this anthology for general use in literacy, science or social science classrooms.", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/OurPlaceInTime.pdf"
},{ 
  "heading1": "The Sky Belongs to Everyone",
  "para_1":"A Set of Sample Guided Imagery Stories", 
  "para_2":"The first set of stories in The Sky Belongs to Everyone is a gift for parents and teachers who realize the value of nurturing altruistic identities in the very young. (Fables from around the world have done so for centuries, but now neurologists and sociologists have confirmed that the practice leads to a life of greater tendencies toward social wellbeing, educational fulfillment, and service to society.) To purchase the full edition, click the button below.", 
  "url":"https://www.amazon.com/Sky-Belongs-Everyone-Stories-Resilience/dp/4909757082/ref=sr_1_1?crid=1FQTZBZ4AOJYM&keywords=the+sky+belongs+to+everyone&qid=1687199604&sprefix=the+sky+belongs+to+everyone%2Caps%2C158&sr=8-1"
},{ 
  "heading1": "Habits of Heroes",
  "para_1":"Plan Units Based on Vision, Advocacy, Selflessness and Dedication", 
  "para_2":"Curricular concepts teachers can customize to regional goals, adaptable for middle and upper grades.", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/HeroesManualv3.pdf"
},{ 
  "heading1": "Habits of Helpers",
  "para_1":"Twelve Habits for Early Learners", 
  "para_2":"Curriculum with springboard lesson plan units for 3-7 year-olds. Integrated character, academic and arts lesson plan units can be expanded by the creative teacher, who uses this book for a one- or two-year pacing guide.", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/HabitsOfHelpers.pdf"
},{ 
  "heading1": "Habits of Humanitarians",
  "para_1":"Habits Expand into Plans and Skills Needed in Today's World", 
  "para_2":"Each unit focuses on one of five habits-of-heart with practical approaches that integrate various content areas that springboard into relevant lesson plans for teachers from varying regions. Lesson plan units adaptable for middle and upper grades.", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/HabitsOfHumanitarians.pdf"
},{ 
  "heading1": "Habits of Oneness",
  "para_1":"Habits Help Students Unite with Nature and Humanity",
  "para_2":"How does the habit of awareness help us protect the oceans? How do farsightedness and teamwork influence our care of forests and communities? The framework is designed to provide ideas for the teacher's own 13-S unit, adapted to regional issues. This curriculum has been used for all ages but is most popular in the middle to upper elementary grades.", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/TheHabitOfOneness.pdf"
},{ 
  "heading1": "Climate Change Agents, Part 1",
  "para_1":"Turning Interdiscipliary Ideas into Action in Secondary Classrooms", 
  "para_2":"This five-unit secondary curriculum geared to US Standards for Sustainable Development, with a foreward by the UN Environment Programme Representative. Color photos, experiments, literary and arts assignments bring the year-long plan to life, as the teacher is expected to introduce the immediacy of trending issues upon which students act, as change agents.", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/ClimateChangeAgentsPart1.pdf"
},{ 
  "heading1": "Climate Change Agents, Part 2",
  "para_1":"", 
  "para_2":"Yearlong secondary curriculum geared to US Standards for Sustainable Development", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/ClimateChangeAgentsPart2.pdf"
},{ 
  "heading1": "Climate Change Agents, Part 3",
  "para_1":"", 
  "para_2":"Yearlong secondary curriculum geared to US Standards for Sustainable Development",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/ClimateChangeAgentsPart3.pdf"
},{ 
  "heading1": "Climate Change Agents, Part 4",
  "para_1":"",
  "para_2":"This link connects to Part 4, starting on page 202 , of the yearlong secondary curriculum geared to US Standards for Sustainable Development",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/ClimateChangeAgentsPart4.pdf"
},{ 
  "heading1": "Change Agent Songbook",
  "para_1":"Songs and Lesson Plan Ideas", 
  "para_2":"Songs and lesson plan ideas support the integrated learning in Climate Change Agents or can be used independently. Climate change, homelessness, love of humanity and aspiration can relate to social studies, science, literature and habit-of-heart themes. Originally for secondary students, but these songs have been popular among all ages.", 
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/ChangeAgentSongBook.pdf"
},{ 
  "heading1": "Full-Circle Learning Songbook",
  "para_1":"Song Lyrics and Guitar Chords",
  "para_2":"Full- Circle Learning artists have recorded five CDs, donating their time to help benefit education-as-community transformation around the world. The CDs appear on the website. The lyrics and guitar chords for all but one CD appear in this book. (The Change Agents CD has its own songbook.) Preview the songs with vocals on the CDs. For performance purposes, students may choose sing to the instrumental tracks on the CDs or a guitar accompanist can refer to the chords in this book.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/Student+Songbook.pdf"
},{ 
  "heading1": "Habits-of-Heart Music",
  "para_1":"Song Lyrics, Charts and Performing Arts Program Suggestions",
  "para_2":"Synchronize your planning units with specific songs. Plan holiday programs that integrate many cultural backgrounds. Help your students consider ways to use music as a gift to uplift, to advocate or to inspire.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/HabitsOfHeartMusic.pdf"
},{ 
  "heading1": "Making Peace",
  "para_1":"A Conflict Resolution Curriculum",
  "para_2":"This book features one sample learning unit, on peace, allowing teachers to add it to any other curriculum to introduce peacemaking skills. (The 13-S steps include peacemaking in the Show It Step. The books Habits of Helpers, Habits of Oneness, Habits of Humanitarians, Change Agents and Habits of Leaders incorporate examples of relevant role plays within each learning unit).",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/MakingPeace.pdf"
},{ 
  "heading1": "Full-Circle Learning, Vol. 1",
  "para_1":"The Archival Habits",
  "para_2":"Foundational chapters and unit plans for streamlined after-school or summer enrichment programs. This book provides a plethora of integrated unit plans, while the more thematically driven books outline the strategic steps of a learning unit for the teacher.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/FullCircleLearningVol1.pdf"
},{ 
  "heading1": "Change Agents in Africa",
  "para_1":"Secondary Curriculum for Humanities Classes",
  "para_2":"Students in Africa and around the world may be required to read Chinua Achebe's Things Fall Apart. How do they contextualize the changing roles of each generation, the effect of education and modernity on animism, on tradition and on familial relationships? How will they retain respect for the original thinking behind each tradition while examining their current convictions in the light of the age in which they live? We suggest adapting the strategies for teaching this novel to the reading capacities, region and living conditions of your students. This curriculum offers a starting point.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/ChangeAgentsInAfrica.pdf"
},{ 
  "heading1": "Habits of Leaders",
  "para_1":"A Humanities Curriculum for Secondary Schools",
  "para_2":"For international locations whose reading requirements include George Orwell's Animal Farm, this guide helps teacher integrate the study of science, history, and literature, incorporating an approach to self-mastery.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/HabitsOfLeaders.pdf"
},{ 
  "heading1": "Habits of African Sages",
  "para_1":"A Curriculum for Secondary Schools",
  "para_2":"Designed for Humanities teachers in Africa, this curriculum examines the literature, culture and context of Africa in relation to the habits-of-heart, through Camara Laye's novel, The Dark Child.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/HabitsOfSages.pdf"
},{ 
  "heading1": "Habits of Sages - Latin America",
  "para_1":"A Humanities Course Integrating Case Studies in Literature, Sociology and World Religion",
  "para_2":"What did Paulo Freire do to change the thinking of his compatriots? How do religion, history and literature shape the philosophy of ideals and idealism? In countries where students study the integration of these processes, a teacher may augument the curriculum with habit-of-heart goals from this brief curriculum.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/HabitsOfSagesLatinAmerica.pdf"
},{ 
  "heading1": "Fostering Future Leaders - Full-Circle Learning Facilitators' Guide",
  "para_1":"A Training Workshop Facilitators' Guide for English-Speaking Teachers",
  "para_2":"The facilitator who has been preauthorized may find the key concepts for refresher courses here.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/FosteringFutureLeaders.pdf"
},
{
  "heading1":"Animal Aspirations",
  "para_1":"From the Early Reader Series to Support Habits of Helpers (English/Spanish)",
  "para_2":"Early learners develop phonemic awareness in a bilingual book (Spanish and English), to encourage parents to reinforce the habit-of-heart associated with the learning unit. Print in color for classroom use or in reduced size for student take-home mini-libraries. (This book was illustrated by middle-school Full-Circle Learning student Melissa Douglas, who later became a professional animator.)",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/AnimalAspirations.pdf"
},{ 
  "heading1": "Beauty Is What You Do",
  "para_1":"From the Early Reader Series to Support Habits of Helpers (English/Spanish)",
  "para_2":"Early learners develop phonemic awareness in a bilingual book (Spanish and English), to encourage parents to reinforce the habit-of-heart associated with the learning unit. This version appears in reduced size for student take-home mini-libraries. Students may read the book as they listen to the song by the same title.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/BeautyIsWhatYouDo.pdf"
},{
  "heading1": "Empathy",
  "para_1":"From the Early Reader Series to Support Habits of Helpers (English/Spanish)",
  "para_2":"Early learners develop phonemic awareness in a bilingual book (Spanish and English), to encourage parents to reinforce the habit-of-heart associated with the learning unit. You may print the series full-size for classroom use or as part of a mini-library for student take-home use.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/Empathy.pdf"
},{ 
  "heading1": "Have You Said Hello Today?",
  "para_1":"From the Early Learner Series to Support Habits of Helpers (English/Spanish)",
  "para_2":"Early learners develop phonemic awareness in a bilingual book (Spanish and English), to encourage parents to reinforce the habit-of-heart associated with the learning unit. You may print the series full-size for classroom use or as part of a mini-library for students' take-home use. This story introduces geography concepts and the value of friendliness in connecting students around the world.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/HaveYouSaidHelloToday.pdf"
},{ 
  "heading1": "Kindness Kingdom",
  "para_1":"From the Early Learning Series to Support Habits of Helpers (English/Spanish)",
  "para_2":"Early learners develop phonemic awareness in a bilingual book (Spanish and English), to encourage parents to reinforce the habit-of-heart associated with the learning unit. You may print the series full-size for classroom use or as part of a mini-library for students' take-home use. This book introduces the school year by helping the children practice positive habits together. Photography shows them using recycled materials to build their Kindness Kingdom. Download Document",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/TheKindnessKingdom.pdf"
},{ 
  "heading1": "Patience",
  "para_1":"From the Early Learning Series to Support Habits of Helpers (English/Spanish)",
  "para_2":"Early learners develop phonemic awareness in a bilingual book (Spanish and English), to encourage parents to reinforce the habit-of-heart associated with the learning unit. You may print the series full-size for classroom use or as part of a mini-library for students' take-home use.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/Patience.pdf"
},{ 
  "heading1": "We Are Different",
  "para_1":"From the Early Learning Series to Support Habits of Helpers (English/Spanish)",
  "para_2":"Early learners develop phonemic awareness in a bilingual book (Spanish and English), to encourage parents to reinforce the habit-of-heart associated with the learning unit. You may print the series full-size for classroom use or as part of a mini-library for students' take-home use. In this book, a child discovers diverse capacities that make a family complete and realizes the unique gift he can offer.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/WeAreDifferent.pdf"
},{ 
  "heading1": "When I Create",
  "para_1":"From the Early Learning Series for Habits of Helpers (English/Spanish)",
  "para_2":"Early learners develop phonemic awareness in a bilingual book (Spanish and English), to encourage parents to reinforce the habit-of-heart associated with the learning unit. You may print the series full-size for classroom use or as part of a mini-library for students' take-home use. This book celebrates the creative impulse of children to sing, draw and dance. Illustrated by children, its text reinforces the song by the same title. Young children enjoy reading the colorful book, but older children as well have beautifully choreographed the song. ",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/WhenICreate.pdf"
},{ 
  "heading1": "Fostering Future Leaders -Spanish Translation",
  "para_1":"A Training Workshop Facilitators' Guide for the Spanish-Speaking Workshop",
  "para_2":"Forward this document to a Spanish-speaking community if the facilitator has first been trained himself or herself.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/FosteringFutureLeadersSpanish.pdf"
}, 
{
  "heading1":"Habits of Oneness, Spanish Translation (El Habito de Unidad)",
  "para_1":"A Curriculum for Spanish-Speaking Learning Communities",
  "para_2":"For a pre-trained Spanish-speaking Full-Circle Learning, especially in the primary school grades and especially living near an ocean.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/TheHabitOfOnenessSpanish.pdf"
},{
  "heading1":"Habits of Humanitarians, Spanish Version (Habitos de Humanitarios)",
  "para_1":"A Mexican translation of a Full-Circle Learning curriculum",
  "para_2":"A curriculum for Spanish speaking teachers to deliver to students aged 8-18.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/Habitos-de-Humanitarios.pdf"
},{ 
  "heading1": " Habits of Helpers, Spanish Version (Habitos de los Ayudantes)",
  "para_1":"A Full-Circle Learning Curriculum for Early Learners",
  "para_2":"Using this outline, teachers can plan a monthly habit-of-heart unit for a year or flesh out fuller units for two years. Forward to Spanish speaking teachers of students from 3-7.",
  "url":"https://fullcirclelearningassets.s3.amazonaws.com/HabitsOfHelpersSpanish.pdf"
}];

export { locations, inquirelists, curriculaList }