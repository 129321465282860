import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';
import Australia1 from '../../assets/images/Australia1.png';
import Australia2 from '../../assets/images/Australia2.png';


const images = [
  {
    src: Australia1,
    alt: 'Chelsea Lee Smith with a student',
    class: 'img_class_inline',
    key: 'Australia1'

  },
  {
    src: Australia2,
    alt: 'Johanna with her two daughters homeschooling',
    class: '',
    key: 'Australia2'
  }
];

const Australia = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Australia</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} style={{ width: "fit-content" }} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >

          <div className="rich-text-block-2 color-link w-richtext">
            <p>Chelsea Lee Smith, above right, trained teachers in and around Melbourne and Brisbane in 2012 while
              living in Australia.The nation’s education policy emphasized parent choice and multiple learning
              options, encouraging life skills development for its learners. </p>
            <p>Chelsea’s Full-Circle Learning workshops held special appeal for home schoolers. One participant,
              Johanna Holmes, developed her own home-based school after attending. Johanna commented,“This
              training has forever changed my children’s lives.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>

      </Row>
    </Container>
  )
}

export default Australia