import React from 'react'
import {
    Row, Col, Container, Button
} from "reactstrap";

const LoveUnit = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1 fw-bold">Love</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Row className="">
                        <Col sm="12" className="">
                            <h3>Introduction</h3>
                            <p>Enjoy relevant, purposeful learning. Follow the steps of the Full-Circle Learning model through a series of activities applicable for school or home use. Lesson plans include links for broadcast or online classrooms as well as print options for classrooms or homes without technology.</p>
                            <Button className="download-button w-button">Download</Button>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Outline</h3>
                            <p>Teachers, parents or self-guided students can use this document to preview the elements and assignments to include based on available technologies.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-outline.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 1: Sensing Love</h3><p>Maureen Mungai introduces the nature of love in a video. Games and drawings help learners experiences it through all the senses.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-one.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FDXJ-cmqvx8U%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DDXJ-cmqvx8U&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FDXJ-cmqvx8U%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 2: "Why Turtles Don't Sing"</h3>
                            <p>Pulitzer prize-winning author Charles McNair tells a Cherokee folktale.Dr. Adela Castro translates it into Spanish. Students and families tell their own stories.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-two.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FuPgB6wzDjOA&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DuPgB6wzDjOA&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FuPgB6wzDjOA%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 3: Showing Love through Music</h3>
                            <p>Make this yours. Add images, text and links, or connect data from your collection.Songs strengthen literacy while helping learners share love with families.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-three.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 4: Animal Home Life</h3>
                            <p>Learners aged two to seven learn about animals in their own environments.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-four.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 5: The Plant that Carries its Home on its Back</h3>
                            <p>Learners aged 8 – 18 learn about agriculture, history and invention by studying nuts. They follow the life of George Washington Carver and create their own inventions.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-five.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FsEfil-ZeX8A&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DsEfil-ZeX8A&amp;image=http%3A%2F%2Fi.ytimg.com%2Fvi%2FsEfil-ZeX8A%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 6: Rewriting History</h3>
                            <p>Learn about the Cherokee culture that inspired the storytellers. Compare indigenous cultures on three continents. Rewrite history and change future outcomes by stepping onto the conflict resolution bridge.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-six.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 7: Nutty Recipes from Four Parts of the World</h3>
                            <p>Follow up the study of nuts with a family cooking adventure. Learn how different cultures use variations of the same ingredients. Practice math while cooking.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-seven.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 8: The Shape of Love</h3>
                            <p>Practice skills in geometry and build a village to help create new homes for humans in need. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-eight.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 9: Send Love to Grandpa</h3>
                            <p>Read about a family’s loving experience and practice writing skills to create one of your own. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-nine.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 10: Love for All</h3>
                            <p>See how far you’ve come—and how far you can go—with a creative art and music activity about love.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-ten.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Glossary</h3>
                            <p>Preview and review words as needed.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-glossary.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Unit Translations</h3>
                            <p>This document provides Spanish translation for all elements in this unit.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/love/love-element-spanish.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-6">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default LoveUnit