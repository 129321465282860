import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";

import Change_Agents from '../../assets/images/Change_Agents.png';
import True_Heroes from '../../assets/images/True_Heroes.png';
import Habits from '../../assets/images/Habits-Gratitude.png';
import beauty from '../../assets/images/beauty-what-you-do.png';
import story from '../../assets/images/story-songs-p-800.png';

const songs_block_1 = [
    {
        title: 'Cool Summer Day',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/01_Anna-Barnes_Kristin-Barnes_Kevin-Clayton_Darrell-Metcalf_Alison-Trahela_Cool-Summer-Day.mp3'
    },
    {
        title: 'Polished Stones',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/02+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Polished+Stones.mp3'
    },
    {
        title: 'See Me',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/03+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+See+Me.mp3'
    },
    {
        title: 'What You Gonna Do',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/04+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+What+You+Gonna+Do.mp3'
    },
    {
        title: 'Changing Winds',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/05+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Changing+Winds.mp3'
    },
    {
        title: 'I Aspire',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/06+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+I+Aspire.mp3'
    },
    {
        title: 'Sages Of The Ages',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/07+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Sages+of+the+Ages.mp3'
    },
    {
        title: 'Island Call',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/08+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Island+Call.mp3'
    },
    {
        title: 'Honor',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/09+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Honor.mp3'
    },
    {
        title: 'Move Those Mountains',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/10+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Move+Those+Mountains.mp3'
    },
    {
        title: 'Polished Stones (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/11+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Polished+Stones+Instrumental.mp3'
    },
    {
        title: 'See Me (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/12+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+See+Me+Instrumental.mp3'
    },
    {
        title: 'What You Gonna Do (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/13+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+What+You+Gonna+Do+(Instrumental).mp3'
    },
    {
        title: 'Changing Winds (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/14+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Changing+Winds+Instrumental.mp3'
    },
    {
        title: 'I Aspire (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/15+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+I+Aspire+Instrumental.mp3'
    },
    {
        title: 'Sages Of The Ages (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/16+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Sages+of+the+Ages+Instrumental.mp3'
    },
    {
        title: 'Honor (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/17+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Honor+Instrumental.mp3'
    },
    {
        title: 'Move Those Mountains (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/18+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf%2C+Alison+Trahela+-+Move+Those+Mountains+Instrumental.mp3'
    }
];

const songs_block_2 = [
    {
        title: 'True Heroes',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/01+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+True+Heroes.mp3'
    },
    {
        title: 'Who Can Say',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/02+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Who+Can+Say.mp3'
    },
    {
        title: 'When Somebody Wrongs You',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/03+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+When+Somebody+Wrongs+You.mp3'
    },
    {
        title: 'Integrity Doo-Wop',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/04+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Integrity+Doo-Wop.mp3'
    },
    {
        title: 'You Can Count On Me',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/05+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+You+Can+Count+on+Me.mp3'
    },
    {
        title: 'Harmony',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/06+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Harmony.mp3'
    },
    {
        title: 'Sacrifice',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/07+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Sacrifice.mp3'
    },
    {
        title: 'Givers',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/08+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Givers.mp3'
    },
    {
        title: 'True Heroes (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/09+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+True+Heroes+Instrumental.mp3'
    },
    {
        title: 'Who Can Say (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/10+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Who+Can+Say+Instrumental.mp3'
    },
    {
        title: 'When Somebody Wrongs You (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/11+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+When+Somebody+Wrongs+You+Instrumental.mp3'
    },
    {
        title: 'Integrity Doo-Wop (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/12+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Integrity+Doo-Wop+Instrumental.mp3'
    },
    {
        title: 'You Can Count On Me (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/13+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+You+Can+Count+on+Me+Instrumental.mp3'
    },
    {
        title: 'Harmony (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/14+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Harmony+Instrumental.mp3'
    },
    {
        title: 'Sacrifice (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/15+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Sacrifice+Instrumental.mp3'
    },
    {
        title: 'Givers (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/16+-+Anna+Barnes%2C+Kristin+Barnes%2C+Kevin+Clayton%2C+Darrell+Metcalf+..-+-+Givers+Instrumental.mp3'
    }
];
const songs_block_3 = [
    {
        title: 'Working Together',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/01+-+Working+Together.mp3'
    },
    {
        title: 'Gratitude',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/02+-+Gratitude.mp3'
    },
    {
        title: 'Questions',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/03+-+Questions.mp3'
    },
    {
        title: 'Consider This',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/04+-+Consider+This.mp3'
    },
    {
        title: 'Look Ahead',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/05+-+Look+Ahead.mp3'
    },
    {
        title: 'Empathy',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/06+-+Empathy.mp3'
    },
    {
        title: 'Mastery',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/07+-+Mastery.mp3'
    },
    {
        title: 'Yes I Care',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/08+-+Yes+I+Care.mp3'
    },
    {
        title: 'I Am A Twig',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/09+-+I+Am+a+Twig.mp3'
    },
    {
        title: 'Rise Above',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/10+-+Rise+Above.mp3'
    },
    {
        title: 'Working Together (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/11+-+Working+Together+(instrumental).mp3'
    },
    {
        title: 'Gratitude (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/12+-+Gratitude+(instrumental).mp3'
    },
    {
        title: 'Questions (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/13+-+Questions+(instrumental).mp3'
    },
    {
        title: 'Consider This (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/14+-+Consider+This+(instrumental).mp3'
    },
    {
        title: 'Look Ahead (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/15+-+Look+Ahead+(Instrumental).mp3'
    },
    {
        title: 'Empathy (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/16+-+Empathy+(Instrumental).mp3'
    },
    {
        title: 'Mastery (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/17+-+Mastery+(Instrumental).mp3'
    },
    {
        title: 'Yes I Care (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/18+-+Yes+I+Care+(Instrumental).mp3'
    },
    {
        title: 'I Am A Twig (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/19+-+I+Am+a+Twig+(Instrumental).mp3'
    },
    {
        title: 'Rise Above (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/gratitude/20+-+Rise+Above+(Instrumental).mp3'
    }
];
const songs_block_4 = [
    {
        title: 'Beauty Is What You Do',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/01+-+Beauty+Is+What+You+Do.mp3'
    },
    {
        title: "I'll Be There For You",
        link: "https://fullcirclelearningassets.s3.amazonaws.com/beauty/02+-+I'll+Be+There+for+You.mp3"
    },
    {
        title: 'Be Kind',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/03+-+Be+Kind.mp3'
    },
    {
        title: 'When I Create',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/04+-+When+I+Create.mp3'
    },
    {
        title: 'The Unity Circle',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/05+-+The+Unity+Circle.mp3'
    },
    {
        title: 'Rising Tall',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/06+-+Rising+Tall.mp3'
    },
    {
        title: 'We Build Bridges',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/07+-+We+Build+Bridges.mp3'
    },
    {
        title: 'The Willow Bows',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/08+-+The+Willow+Bows.mp3'
    },
    {
        title: 'The Giving Tree',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/09+-+The+Giving+Tree.mp3'
    },
    {
        title: 'Words You May Have Heard',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/10+-+Words+You+May+Have+Heard.mp3'
    },
    {
        title: 'Tenderness',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/11+-+Tenderness.mp3'
    },
    {
        title: 'Beauty Is What You Do (Drum)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/12+-+Beauty+Is+What+You+Do+(drum+track).mp3'
    },
    {
        title: "I'll Be There For You (Instrumental)",
        link: "https://fullcirclelearningassets.s3.amazonaws.com/beauty/13+-+I'll+Be+There+for+You+(instrumental+track).mp3"
    },
    {
        title: 'Be Kind (Drum)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/14+-+Be+Kind+(drum+track).mp3'
    },
    {
        title: 'When I Create (Subdued vocals)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/15+-+When+I+Create+(subdued+vocals).mp3'
    },
    {
        title: 'The Unity Circle (Teacher prompts only)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/16+-+The+Unity+Circle+(teacher+prompts+only).mp3'
    },
    {
        title: 'Rising Tall (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/17+-+Rising+Tall+(instrumental+track).mp3'
    },
    {
        title: 'We Build Bridges (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/18+-+We+Build+Bridges+(instrumental+track).mp3'
    },
    {
        title: 'The Willow Bows (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/19+-+The+Willow+Bows+(instrumental+track).mp3'
    }
    ,
    {
        title: 'The Giving Tree (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/20+-+The+Giving+Tree+(instrumental+track).mp3'
    }
    ,
    {
        title: 'Words You Made Have Heard (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/21+-+Words+You+May+Have+Heard+(instrumental+track).mp3'
    }
    ,
    {
        title: 'Tenderness (Instrumental)',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/beauty/22+-+Tenderness+(instrumental+track).mp3'
    }
];
const songs_block_5 = [
    {
        title: 'We Are Children Of Earth',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/We-are-Children-of-Earth.mp3'
    },
    {
        title: 'We Are Children Of Earth 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/We-are-Children-of-Earth-2.mp3'
    },
    {
        title: 'When I Can Share',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/When-I-Can-Share.mp3'
    },
    {
        title: 'When I Can Share 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/When-I-Can-Share-2.mp3'
    },
    {
        title: 'Johnny Appleseed',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Johnny-Appleseed.mp3'
    },
    {
        title: 'Johnny Appleseed 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Johnny-Appleseed-2.mp3'
    },
    {
        title: 'Our Loving House',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Our-Loving-House.mp3'
    },
    {
        title: 'Our Loving House 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Our-Loving-House-2.mp3'
    },
    {
        title: 'Friends Of The World',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Friends-of-the-World.mp3'
    },
    {
        title: 'Friends Of The World 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Friends-of-the-World-2.mp3'
    },
    {
        title: 'My Best Friend',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/My-Best-Friend.mp3'
    },
    {
        title: 'My Best Friend 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/My-Best-Friend-2.mp3'
    },
    {
        title: 'World Full Of Lessons',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/World-Full-of-Lessons.mp3'
    },
    {
        title: 'World Full Of Lessons 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/World-Full-of-Lessons-2.mp3'
    },
    {
        title: 'The Ants Come Marching',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/The-Ants-Come-Marching.mp3'
    },
    {
        title: 'The Ants Come Marching 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/The-Ants-Come-Marching-2.mp3'
    },
    {
        title: 'Patience',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Patience.mp3'
    },
    {
        title: 'Patience 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Patience-2.mp3'
    },
    {
        title: 'Together We Will Stand',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Together-We-Will-Stand.mp3'
    },
    {
        title: 'Together We Will Stand 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Together-We-Will-Stand-2.mp3'
    },
    {
        title: 'Hijos de la Tierra',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Hijos-de-la-Tierra.mp3'
    },
    {
        title: 'Hijos de la Tierra 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Hijos-de-la-Tierra-2.mp3'
    },
    {
        title: 'Poder Compartir',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Poder-Compartir.mp3'
    },
    {
        title: 'Poder Compartir 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Poder-Compartir-2.mp3'
    },
    {
        title: 'Johnny Appleseed Espanol',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Johnny-AppleSeed-en-Espanol.mp3'
    },
    {
        title: 'Johnny Appleseed Espanol 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Johnny-Appleseed-en-Espanol-2.mp3'
    },
    {
        title: 'Nuestra Casita',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Nuestra-Casita.mp3'
    },
    {
        title: 'Nuestra Casita 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Nuestra-Casita-2.mp3'
    },
    {
        title: 'Todos a Saludar',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Todos-a-Saludar.mp3'
    },
    {
        title: 'Todos a Saludar 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Todos-a-Saludar-2.mp3'
    },
    {
        title: 'Mi Amigo',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Mi-Amigo.mp3'
    },
    {
        title: 'Mi Amigo 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Mi-Amigo-2.mp3'
    },
    {
        title: 'Un Mundo de Lecciones',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Un-Mundo-de-Lecciones.mp3'
    },
    {
        title: 'Un Mundo de Lecciones 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Un-Mundo-de-Lecciones-2.mp3'
    },
    {
        title: 'Las Hormigas Marchando',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Las-Hormigas-Marchando.mp3'
    },
    {
        title: 'Las Hormigas Marchando 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/The-Ants-Come-Marching-2.mp3'
    },
    {
        title: 'Paciencia',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Paciencia.mp3'
    },
    {
        title: 'Paciencia 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Paciencia-2.mp3'
    },
    {
        title: 'Solo No se Quede',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Solo-No-se-Quede.mp3'
    },
    {
        title: 'Solo No se Quede 2',
        link: 'https://fullcirclelearningassets.s3.amazonaws.com/storysongs/Solo-No-se-Quede-2.mp3'
    }

];
const Songs = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1 fw-bold">Songs</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 3,
                        size: 6
                    }}
                >
                    <div className="my-1">
                        <img src={Change_Agents} loading="lazy" width="506" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, 506px" alt="A face of a woman whose eyes contain the earth in each iris. Album cover for Change Agents." className="image-20" />
                    </div>
                    <div className="songs-divs">
                        <div className="collection-list-wrapper">
                            <Row>

                                {songs_block_1.map((song, index) => (
                                    <Col sm="12" className="collection-item-song" key={song.title}>
                                        <div className="pr-4">{song.title}</div>
                                        <a href={song.link} className="download-button play-combo w-button">Play / Download</a>
                                    </Col>
                                ))}

                            </Row>
                        </div>
                    </div>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 3,
                        size: 6
                    }}
                >
                    <div className="">
                    <div className="my-1">
                        <img src={True_Heroes} loading="lazy" width="506" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, 506px" alt="A group of students sing to their elders." className="image-20" />
                    </div>
                        <div className="songs-divs">
                            <div className="collection-list-wrapper">
                                <Row>
                                    {songs_block_2.map((song, index) => (
                                        <Col sm="12" className="collection-item-song" key={song.title}>
                                            <div className="pr-4">{song.title}</div>
                                            <a href={song.link} className="download-button play-combo w-button">Play / Download</a>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 3,
                        size: 6
                    }}
                >
                    <div className="">
                        <div className="my-1">
                            <img src={Habits} loading="lazy" width="506" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, 506px" alt="A road to a house in the distance is flanked by wide grassy fields." className="image-20" />
                        </div>
                        <div className="songs-divs">
                            <div className="collection-list-wrapper">
                                <Row>
                                    {songs_block_3.map((song, index) => (
                                        <Col sm="12" className="collection-item-song"  key={song.title}>
                                            <div className="pr-4">{song.title}</div>
                                            <a href={song.link} className="download-button play-combo w-button">Play / Download</a>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 3,
                        size: 6
                    }}
                >
                    <div className="">
                        <div className="my-1">
                            <img src={beauty} loading="lazy" width="506" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, 506px" alt="A child smiles with a bird on their shoulder." className="image-20" />
                        </div>
                        <div className="songs-divs">
                            <div className="collection-list-wrapper">
                                <Row>
                                    {songs_block_4.map((song, index) => (
                                        <Col sm="12" className="collection-item-song" key={song.title}>
                                            <div className="pr-4">{song.title}</div>
                                            <a href={song.link} className="download-button play-combo w-button">Play / Download</a>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 3,
                        size: 6
                    }}
                >
                    <div className="">
                        <div className="my-1">
                            <img src={story} loading="lazy" width="506" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, 506px" alt="Two children sit on a bench, one reading a large book to the other." className="image-20" />
                        </div>
                        <div className="songs-divs">
                            <div className="collection-list-wrapper">
                                <Row>
                                    {songs_block_5.map((song, index) => (
                                        <Col sm="12" className="collection-item-song"  key={song.title}>
                                            <div className="pr-4">{song.title}</div>
                                            <a href={song.link} className="download-button play-combo w-button">Play / Download</a>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Songs