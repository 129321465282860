import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';
import China1 from '../../assets/images/China1.png';
import China2 from '../../assets/images/China2.png';
import China3 from '../../assets/images/China3.png';
import China4 from '../../assets/images/China4.png';
import China5 from '../../assets/images/China5.png';
import China6 from '../../assets/images/China6.png';
import China7 from '../../assets/images/China7.png';
import China8 from '../../assets/images/China8.png';
import China9 from '../../assets/images/China9.png';
import China10 from '../../assets/images/china10.png';
import China11 from '../../assets/images/China11.png';


const images = [
  {
    src: China1,
    alt: 'A portrait of Nishanth Selvakumar behind a ceremonial dish.',
    class: 'img_class_inline',
    key: 'China1'

  },
  {
    src: China2,
    alt: 'A class photo with the word Love written on the wall behind them',
    class: '',
    key: 'China2'
  },
  {
    src: China3,
    alt: 'A group of teachers holding a banner',
    class: '',
    key: 'China3'
  },
  {
    src: China4,
    alt: 'Dr. Jin-mei Gan with Theresa Langness',
    class: 'img_class_inline',
    key: 'China4'
  }
];

const images1 = [
  {
    src: China5,
    alt: 'Three students in costume',
    key: 'China5'
  },
  {
    src: China6,
    alt: "Two children doing a project in a garden",
    key: 'China6'
  },
  {
    src: China7,
    alt: 'Several children and adults working in the garden',
    key: 'China7'
  },
  {
    src: China8,
    alt: 'A child smiles with his drawing',
    key: 'China8'
  },
  {
    src: China9,
    alt: 'A student appreciates his teacher',
    key: 'China9'
  },
  {
    src: China10,
    alt: "A teacher gets to the child's level to hear what she is saying",
    key: 'China10'
  },
  {
    src: China11,
    alt: 'Aerial view of the planned Full-circle learning-based school',
    key: 'China11'
  }
];

const China = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">China</h1>
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>A university dean dubbed 2007 the Year of Love. One day as he considered new options for wisdom
              exchanges, Professor Qin Jinliang suggested that all the Full-Circle Learning schools come to a
              Full-Circle Learning conference hosted atZhejiang Normal University. We explained that the plan may
              be cost-prohibitive for teachers, so he said, “Let’s have a ‘conference without walls’, and compile
              the projects of each community as they conduct a unit plan on the habit-of-heart Love.” His face lit
              upas he added, “Won’t it be wonderful when <em>love of family</em>
              extends to love of the <em>human</em> family, for all the children
              in the world?”</p>
            <p>Eight countries participated in the Year of Love challenge as a result. Their videos were compiled in
              one DVD, which each school received in order to host their own school conferences. </p>
            <p>FCL’s relationship with China began with an invitation to tour eight universities to discuss the
              concepts in the first Chinese translation of a Full-Circle Learning (FCL) book,
              <em>Habits of Humanitarians</em>. On one stop, the dean of Zhejiang
              Normal University, Qin Jinliang, recognized the potential of the FCL model to set the tone for all
              early childhood teacher education at the university classroom and at the affiliated school of 1,000
              learners.</p>
            <p>At the teachers’ college, a three-year study ensued. The university’s curriculum department (pictured
              here) worked withFull-Circle Learning to adapt and translate <em>Habits of
                Helpers</em> as the focal point for moral development in the decades that
              followed. </p>
            <p> The dean of moral education,Dr. Jin-mei Gan, worked withFCL’s leadership to present continual
              classes focused on the Full-Circle Learning model. Newly trained teachers conducted amazing projects
              and grew into mature educators, writing books for teachers and also opening up new schools as they
              rose to the rank of administrators.</p>
            <p> FCL took part in conferences at the university and in the first international conference of
              Greentown Education, a system that had come to see the model as integral to its mission. The
              teachers wrote songs about the love of the human family. </p>
            <p> The Greentown system of schools designed a new school to be completed in 2021, with FCL’s input on
              architecture, community engagement options and school mission. </p>
            <p> Meanwhile, the love between the Chinese educators and the FCL staff testified to the love envisioned
              for students in those early meetings. Even with language barriers, the team had modeled the love of
              the human family for students. </p>
            <p> Below, children resolve a conflict between indigenous people and pilgrims while studying the
              holidays of their wisdom exchange partner class in America. </p>
            <p> Growing food became a highlight for children engaged in environmental gardens, and
              parents supported their efforts. </p>
            <p>Children demonstrated what distance learning looks like during a pandemic.</p>
            <p>Children gave speeches and performances describing love at one school. </p>
            <p> In 2018, a testimonial fromFull-Circle Learning of ZhejiangProvince related that now the teachers do
              not consider their role trivial but realize their potential to “positively influence and change the
              world...They are participating in the construction of the human family."</p>
            <p> Below, the Greentown Education System announced plans in 2020 to erect a new Full-Circle
              Learning-based school in Zhoushan, an urbanized archipelago in the eastern ZhejiangProvince, two and
              a half hours from Hangzhou City. </p>
            <p>&zwj;</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>



      </Row>
    </Container>
  )
}

export default China