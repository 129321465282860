import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Papua1 from '../../assets/images/Papua1.png';
import Papua2 from '../../assets/images/Papua2.png';
import Papua3 from '../../assets/images/Papua3.png';
import Papua4 from '../../assets/images/Papua4.png';


const images = [
  {
    src: Papua1,
    title: 'Teachers at a long table studying together'
  },
  {
    src: Papua2,
    title: 'A breakout group of three teachers studying together'
  },
  {
    src: Papua3,
    title: 'Teachers in their traditional dress of Papua New Guinea'
  },
  {
    src: Papua4,
    title: 'A large group of Full-circle learning teachers'
  }
];

const PapuaNewGuinea = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Papua New Guinea</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p> Leaders of the VirtuesCountry Day School reached out to Full-CircleLearning requesting a2014 teacher
              training inPort Moresby, Papua NewGuinea, an island known for its diversity. </p>
            <p>After being greeted at the airport by children celebrating their many different cultural identities
              together, the teachers gathered at a vocational college. There, educators from20 schools, from
              preschool to college level, gathered to study Full-Circle Learning approaches to their unique
              conditions. The goal of their first co-planned learning project was to stop the spitting of beetle
              nut juice (used to quell hunger). A retinue of environmental projects were among the reports
              submitted in the following years.</p>
            <p> At the time of the 2014 visit, the teachers said they had not received global collaborators for a
              professional development program to enhance their community-wide teaching tools since the 1980s.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default PapuaNewGuinea