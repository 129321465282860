import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';
import Brazil1 from '../../assets/images/Brazil1.png';
import Brazil2 from '../../assets/images/Brazil2.png';
import Brazil3 from '../../assets/images/Brazil3.png';
const images = [
  {
    src: Brazil2,
    title: "An older student helps a younger student on stage"
  },{
    src: Brazil1,
    title: "Students holding a sign about water"
  }, {
    src: Brazil3,
    title: "Three young students at their desks smiling together"
  }
];

const Brazil = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Brazil</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p> School of the Nations, in Brasilia, a highly accredited private school, welcomed students from
              varying cultural backgrounds and social classes. Many diplomats sent their children there. </p>
            <p> Full-Circle Learning-trained teacher Denali Weiler offered the teachers of the School of the Nations
              professional development workshops to learn how to use the model to improve the relevancy of
              learning. In her own Ethics class of 8t h graders, she demonstrated
              “best practices” of Full-Circle Learning in action. </p>
            <p> Through units that each integrated a habit-of-heart with a challenging issue, resolved in
              service-learning projects, Denali taught students to recognize the value of service to humanity as a
              springboard for learning. She also paired them in teams with children from younger grade levels. Her
              students thrived, offering statements such as these: </p>
            <p> “We help others because we are all part of the same world and we must care about everybody.” - Tomas
              Bath </p>
            <p> “I think ‘Reading Partners’ is important because while we are helping the young children, we are
              also helping ourselves to be patient...” – Mariana Barros. </p>
            <p> “Each project reinforces the current Habit-of-Heart and culminates in a local or international
              service project,” explained principal Lisa Perskie. </p>
            <p> A few 8t h grade students known for classroom disruptions improved
              their attitude and aptitude so much, they became the school’s highest achievers. On graduation day,
              their principal asked what had made the difference for them—why they had suddenly strengthened their
              leadership capacity. The students responded that with altruistic projects as focal points, they
              finally understood the <em>purpose</em> of learning.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>


      </Row>
    </Container>
  )
}

export default Brazil