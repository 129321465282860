import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Norway1 from '../../assets/images/Norway1.png';
import Norway2 from '../../assets/images/Norway2.png';

const images = [
  {
    src: Norway1,
    title: 'A student stands within her artwork as she works on it.'
  },
  {
    src: Norway2,
    title: "Students show their work on a cut out of a polar bears"
  }
];

const Norway = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Norway</h1>
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>The Oslo Peace Prize museum featured an exhibit linking peace and the environment. Full-Circle Learning students were asked to submit artwork to express this relationship for museum patrons. Below, a Norwegian girl entered a portion of the resulting exhibit.														</p>
            <p>One of the largest pieces was created by Full-Circle Learning students in middle school, who had joined the alumni club. Artist John Paul Thornton helped them create a cardboard polar bear. Using wood block prints, each student drew a Nobel Peace Prize winner and turned it into a woodblock print. The resulting pattern symbolized the impact of peace leaders in ensuring sustainability on the planet.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Norway