import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Vanuatu1 from '../../assets/images/Vanuatu1.png';
import Vanuatu2 from '../../assets/images/Vanuatu2.png';


const images = [
  {
    src: Vanuatu1,
    title: 'People doing traditional performance with students'
  },
  {
    src: Vanuatu2,
    title: 'Two friends in embrace'
  }
];

const Vanuatu = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Vanuatu</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Wisdom exchanges help young people in disparate parts of the world collaborate to address the
              challenges of our time. </p>
            <p>The Climate Change Ministry of Vanuatu learned about theClimate Change Agents camp in northern
              California, cosponsored by the Nevada County Climate Change Coalition and Full-CircleLearning. In
              early 2017, the officials in Vanuatu wrote to request a scholarship for a student from their country
              to attend the camp. A parent chaperone would be needed, and this meant candidates had to fly to
              another island for a visa. The process involved sacrifice on the part of the family, plus
              scholarship funding and international airfare from the US side. </p>
            <p>Rossana Kalulu qualified for the grant and attended the camp, along with her mother, Fredlynn
              (joining the dancers in the bottom row of the picture.) </p>
            <p>These two Vanuatuans taught the campers about their own experiences with increasing cyclones, as the
              group studied the impact of climate change on weather and on water. The two danced in an island song
              spreading awareness about shrinking islands. </p>
            <p> Service-learning projects involved restoring a flood plain, learning about water catchment and
              permeable sidewalks, teaching through dance, photography, spoken word and science exhibits, and
              helping a city pass its renewable energy policy. </p>
            <p>The students also taught the community about freshwater needs around the world and designed improved
              tidal, solar and wind technologies.The annual Climate Change Agents have convened a week-long
              overnight camp in Nevada County since 2014, co-sponsored by Climate Action Now and Full-Circle
              Learning.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Vanuatu