import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Navajo1 from '../../assets/images/Navajo1.png';
import Navajo2 from '../../assets/images/Navajo2.png';
import Navajo3 from '../../assets/images/Navajo3.png';


const images = [
  {
    src: Navajo1,
    title: 'Exterior view of the Little Red Schoolhouse'
  },
  {
    src: Navajo2,
    title: 'Jon Morales working with the Basuto children of Lesotho'
  },
  {
    src: Navajo3,
    title: 'A large group of Basuto children'
  }
];

const NavajoNation = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Navajo Nation</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Teachers at&ZeroWidthSpace; &ZeroWidthSpace;Kin Dah Lichii Olta' Charter School Kindalichi Oha (the
              Little Red Schoolhouse), in Ganado, Arizona, on the Diné reservation, received a government grant
              for Full-CircleLearning training in 2005. </p>
            <p>The teachers explored examples of cultural relevance, including character-building demonstrations
              involving the traditional Navajo wedding basket. One literacy project unit compared variations of
              the book &ZeroWidthSpace;<em>Cinderella</em>&ZeroWidthSpace;, including the local indigenous
              version. </p>
            <p>In 2008, the Louis Gregory preschool in Lesotho, a country in SouthernAfrica, offered a wisdom
              exchange project involving the planting and uses of corn in both countries. </p>
            <p>Volunteer JoniMorales, after working for FCL Lesotho for two years, traveled to visit the Navajo
              school, bringing this photo of the Basuto children. </p>
            <p>Amy Kahn also worked to introduce learning concepts into her teaching practice on the reservation.
            </p>
            <p>&zwj;</p>
            <p>&zwj;</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default NavajoNation