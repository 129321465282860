import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Guatemala1 from '../../assets/images/Guatemala1.png';

const images = [
  {
    src: Guatemala1,
    title: "A teacher and student"
  }
];
const Guatemala = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Guatemala</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Maureen Mungai, a long-time Full-Circle Learning trainer and aficionado, traveled with Colette
              Ankenman to the Lake Atitlanarea of Guatemala in 2019. </p>
            <p>Collette, a scholar in the field of service-oriented education and founder of Baragwanath Blessings,
              arranged for the multi-school collaboration. At a Full-Circle Learning training, she became inspired
              to strike up a collaboration. She saw the FCL team as an ideal collaborator to go to
              Guatemala, to help inspire teachers and to facilitate the creation and distribution
              of hygiene kits with three elementary schools (nearly 1,000 students in total)in the Solala area of
              Guatemala. </p>
            <p>“We have worked with all of the schools for years and they all have a strong sense of social justice
              and engagement,” reportedCollette. “In fact, the hygiene kits were the kids’ and teachers’ idea, and
              this will be our second year of the program on GlobalYouth Service Day.” </p>
            <p>&zwj;</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>

  )
}

export default Guatemala