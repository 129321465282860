import React from 'react'
import unitedsate1 from '../../../assets/images/unitedsate1.png';
import unitedstate2 from '../../../assets/images/unitedstate2.png';
import {
    Row, Col, Container
} from "reactstrap";
const index = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1">United States</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <div className="rich-text-u mx-auto">
                        <figure className="mx-auto">
                            <div><img src={unitedsate1} loading="lazy" alt="my alt" /></div>
                        </figure>
                        <h2>April 2022:&nbsp;Global Harvests that Harmonize Hearts</h2>
                        <p>“Let them stare. Let them see. We will show the world harmony,” sang school children from the Osun
                            State of Nigeria to immigrant children in Los Angeles County, in response to a recent Full-Circle
                            Learning wisdom exchange.&nbsp;</p>
                        <p>Earlier in the school year, Tarzana Elementary School’s Habits-of-Heart students set out to plant
                            seeds of kindness. Their teacher helped them integrate academic and arts projects and send a wisdom
                            exchange package, including hand-made gifts of tie-dyed shirts, to classmates across the ocean. Each
                            child penned a list of inquiries to their new brothers and sisters, sharing their own upcoming plans
                            to grow <em>real</em> seeds of kindness in a community garden.&nbsp;</p>
                        <p>They sent their package at a time when shipping delays plagued the US Postal Service. (For a time, we
                            wondered whether the large box would <em>ever </em>get as far as Lagos, let alone to the village.)
                            After many calls to the customs office, the Nigerian wisdom exchange liaison finally reported its
                            arrival.&nbsp; Her delighted students wrote replies about their own farming projects and their
                            process of making dye from vegetables. Their Unity Class (grades 2-3) had grown local plants as
                            gifts for teachers. Their Patience Class (Preschoolers) had grown maize and cassava to feed the
                            community. Their first-grade Cooperation Class had honored local orphanage founders for
                            kindness.&nbsp;</p>
                        <p>Cherishing the letters and wearing the shirts, the Nigerian children sang the Harmony song and
                            videotaped it for their faraway family in the town of Tarzana. Meanwhile, Tarzana’s principal shared
                            the story with joyous parents, many of them immigrants themselves. The Full-Circle Learners enjoyed
                            knowing they could sow seeds of kindness abroad and watch them sprout.&nbsp;</p>
                        <figure className="mx-auto">
                            <div><img src={unitedstate2} loading="lazy" alt="my alt" /></div>
                        </figure>
                    </div>

                </Col>

                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <div className="rich-text-u">
                        <p>Full-Circle Learning has provided teacher training or direct student-service programs at each of
                            these 43 learning institutions in the United States between 1992 and 2020 (photo essay attached):
                        </p>
                        <p><strong>Arizona</strong></p>
                        <ol start="">
                            <li>Professional Development, Nurtured Heart School, Tucson</li>
                        </ol>
                        <p>&zwj;<strong>California</strong> </p>
                        <p><strong>(40 Programs)</strong> </p>
                        <p><strong>Southern California</strong></p>
                        <ol start="" className="united_state_li">
                            <li><a href="/alumni-clubs">Alumni Clubs</a></li>
                            <li>Chapman University Course Materials (Social Justice Education)</li>
                            <li><a href="/childrens-enrichment-summer-school-and-after-school-programs">Children’s Enrichment
                                Summer School and After-School programs</a> </li>
                            <li><a href="/huntington-hotel-fcl-school">Huntington Hotel FCL School</a> </li>
                            <li>Encino Elementary After-School Programs; Encino ProfessionalDevelopment Training </li>
                            <li>Full-Circle Learning Alumni Club </li>
                            <li>Cleveland Elementary School, Pasadena </li>
                            <li><a href="/full-circle-learning-academy">Full-Circle Learning Academy (inner-city charter
                                school)</a> </li>
                            <li>King Elementary: FCL-Integrated Science Program </li>
                            <li>Oak Park High Full-Circle Learning Club </li>
                            <li>Phenomenex: Full-Circle Learning and Leadership and LifeSkills workshops </li>
                            <li><a href="/piru-full-circle-learning-preschool">Piru Full-Circle Learning Preschool</a> </li>
                            <li><a href="/rancho-sespe-piru-summer-school-and-year-round-enrichment-program">Rancho Sespe/Piru
                                Summer school and year-round enrichment program</a> </li>
                            <li>SANAD Cultural Preservation School </li>
                            <li>Santa Ana School District professional development </li>
                            <li><a href="/spurgeon-school-fcl-program">Spurgeon School FCL Program</a> </li>
                            <li><a href="/tarzana-elementary-schoolwide-professional-development-and-habits-of-heart-club">Tarzana
                                Elementary: School wide Professional Development and Habits-of-Heart Club</a> </li>
                            <li>Topanga Elementary project </li>
                            <li>Topanga Global Professional Development Workshops</li>
                        </ol>
                        <p><strong>Northern California</strong></p>
                        <ol start="" className="united_state_li">
                            <li><a href="/climate-change-agents-camp-nevada-county">Climate Change Agents Camp, Nevada
                                County</a> </li>
                            <li><a href="/northern-california-community-education-initiative-cei">Community Education Initiative
                                (CEI) Summer School – El Cerritos</a></li>
                            <li><a href="/diabetes-awareness-camp-co-hosted-by-levitate">Diabetes Awareness Camp (co-hosted)</a>
                            </li>
                        </ol>
                        <p>Professional Development Workshops in:</p>
                        <ol start="" className="united_state_li">
                            <li><a href="/grass-valley-education-as-transformation-statewide-training">Plumas County: Teacher
                                Training, Quincy - multiple schools, hosted by local Plumas Audubon Society</a> </li>
                            <li>Oakland – Kaiser Corp. Building – Leadership and Life Skills </li>
                            <li>San Leandro – Public Library – Full-Circle Learning </li>
                            <li>San Francisco – California Community Foundation Building </li>
                            <li>San Rafael – Global Wisdom Exchange Training </li>
                            <li>Nevada County School District Office – Full-Circle Learning </li>
                            <li><a href="/grass-valley-education-as-transformation-statewide-training">Grass Valley (Education
                                as Transformation statewide training) </a> </li>
                            <li>Professional Development: Wisdom Exchange: Nevada City </li>
                            <li>Professional Development: Service-Learning, Nevada City </li>
                            <li>Zeum Museum: Joint Project for Global Schools </li>
                        </ol>
                        <p><strong>Florida</strong></p>
                        <ol start="" className="united_state_li">
                            <li> Gainesville: Regional training workshop<br /> </li>
                            <li> <a href="/hidden-oak-reading-program">Hidden Oak reading program</a><br /> </li>
                            <li> Prairie View in-school program school<br /> </li>
                            <li> Jackson: Venus School professional development<br /> </li>
                            <li> Rawlings Elementary professional development/in-school program</li>
                        </ol>
                        <p> <strong>New Mexico</strong> </p>
                        <ol start="">
                            <li> Albuquerque Professional Development<br /> </li>
                            <li> Anthony New Mexico/Anthony Texas Professional Development </li>
                            <li> Professional Development, Ganado </li>
                        </ol>
                        <p> <strong>New Jersey</strong> </p>
                        <ol start="">
                            <li> Newark School District Professional Development </li>
                        </ol>
                        <p> <strong>New York</strong> </p>
                        <ol start="">
                            <li> New York City School District Professional Development </li>
                            <li> Paul Robeson Middle School </li>
                        </ol>
                        <p> <strong>Rhode Island</strong> </p>
                        <ol start="">
                            <li> Children’s Theatre Company</li>
                        </ol>
                    </div>
                    <div className="rich-text-u"></div>
                </Col>
            </Row>
        </Container>
    )
}

export default index