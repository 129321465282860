import React from 'react'
import './signup-form.css';
import { Row, Col } from "reactstrap";
// Form, Card, Input, Label, Button
const Newsletter = () => {
 /* const [email, setEmail] = useState('')
  const handleEmail = (e) => {
    setEmail(e.target.value)
    console.log(e)
  }*/
  return (
    <Row>
      <Col
        sm="12"
        md={{
          offset: 2,
          size: 8
        }}
      >
        <div class="ctct-inline-form" data-form-id="3f106ab0-5f87-47d5-9f16-c9f2e527588f"></div>
      </Col>
    </Row>
  )
}

export default Newsletter
