import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";
import { curriculaList } from '../../utils/constant'
const Curricula = () => {

    const scrollToSection = () => {
        const sectionElement = document.getElementById("section");
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToSection2 = () => {
        const sectionElement2 = document.getElementById("section1");
        if (sectionElement2) {
            sectionElement2.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1">Curricula</h1>
                    <Col>
                        <button onClick={scrollToSection} className="scrollbtn hover-black">
                            <div><strong>Click here to scroll a series of bilingual storybooks that accompany Habits of Helpers</strong></div>
                        </button>
                    </Col>
                    <Col>
                        <button onClick={scrollToSection2} className="scrollbtn hover-black">
                            <div><strong>Click here to go to Spanish Translations</strong>
                            </div>
                        </button>
                    </Col>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Row className="my-3">
                        {curriculaList.map((listt, index) => {
                            let custom_id = "";
                            if (listt.heading1 === "Habits of Humanitarians, Spanish Version (Habitos de Humanitarios)") {
                                custom_id = "section1"
                            } else if (listt.heading1 === "Animal Aspirations") {
                                custom_id = "section"
                            }
                            return (
                                <Col sm="12" key={listt.heading1} className="" id={custom_id}>
                                    <h2 id="spanish" className="heading-12 c-item">{listt.heading1}</h2>
                                    <p id="start" className="paragraph-5">{listt.para_1}</p>
                                    <p>{listt.para_2}</p>
                                    <a target="_blank" rel="noreferrer" href={listt.url} className="download-button w-button">{listt.heading1 === "The Sky Belongs to Everyone" ? "Purchase" : 'Download'}</a>
                                </Col>
                            )
                        }
                        )}
                    </Row>
                </Col>
                <section id="translations"></section>
                <section id="bilingual" className="section-97"></section>
            </Row>
        </Container>
    )
}
export default Curricula