import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";

const Testimonials = () => {
    return (
        <Container>
            <Row className='line_height'>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1 fw-bold">Testimonials</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h2><strong>Voices of educators in the US </strong></h2>
                    <p>“This was the single most important training I have ever attended. I think every teacher in the world
                        needs to a end...” – Michelle Ogaidi, Middle School Teacher</p>
                    <p>“I appreciate that this curriculum has such a change-agent, altruistic framework – very unique and
                        powerful. The curriculum is highly developed with standards, assessment measures and support materials.
                        I saw a strong connection to my community college instruction.</p>
                    <p>“My fellow-faculty members were actively seeking a theme that was empowering, purposeful in the lives of
                        our students. This is a perfect fit.” - Susan Reddoor, Oregon State Board of Community College
                        Instruction</p>
                    <p>“This material is based on the actual needs of children and family and teachers. It showed me a different
                        way of handling conflicts, assigning responsibility and helping develop new habits.” - Anonymous Teacher
                    </p>
                    <h2><strong>Voices of international educators</strong></h2>
                    <p>“Greentown Emerald City Kindergarten has begun with the FCL project as its most distinctive model of
                        curriculum practice ever since its establishment...” –The Greentown Staff, Hangzhou China</p>
                    <p>“...Liberia can be proud of the generation this program will create... It will be a dream come true to a
                        struggling country...” - Isaac FuFlay, Head of Teacher Training Unit, Ministry of Education</p>
                    <p>“...We have observed...students as young as nine years old selling and resolving conflicts among their
                        parents and community members and...students restoring the country’s civic values that were lost during
                        the many years of war in our country.” - Rev. Ellen Gerring-Varfley, Director of Professional
                        Development, Liberian Ministry of Education</p>
                    <p>“This program will eliminate corruption in high places if kids are carefully guided through it.” –
                        Comfort Lahai, Liberia</p>
                    <p>“I read the Full-Circle Learning materials [after] the workshop we had in my country. It is educative and
                        has changed life here. We love it.” - Bakary Jarju High School and College Math Instructor, The Gambia
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
export default Testimonials