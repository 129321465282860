import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Ecuador1 from '../../assets/images/Ecuador1.png';
import Ecuador2 from '../../assets/images/Ecuador2.png';
import Ecuador3 from '../../assets/images/Ecuador3.png';
import Ecuador4 from '../../assets/images/Ecuador4.png';

const images = [
  {
    src: Ecuador1,
    title: "A teacher receiving an award"
  }, {
    src: Ecuador2,
    title: "A group sorts through materials"
  }, {
    src: Ecuador3,
    title: "A building in disrepair"
  }, {
    src: Ecuador4,
    title: "A teacher guides learners at a parochial school"
  }
];

const Ecuador = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Ecuador</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src}  style={{ height:"550px",objectFit:"cover" }}/>
            </Card>
          ))}
        </Col>

        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p> Hurricanes tested the resiliency of the Ecuadorean preschool operated by Loreno Rojas, the first
              teacher to introduceFull-Circle Learning into a classroom setting in Ecuador.Lorena Rojas helped the
              children and parents become leaders in offering humanitarian aid and was later rewarded by the
              Quevada District with a regional leadership position.</p>
            <p> Lorena had learned aboutFull-Circle Learning fromConsuelo Banderas, an intrepid parent from Tarzana
              Elementary School inGreater Los Angeles.</p>
            <p> In 2011, Consuelo initiated service projects and global wisdom exchanges in the US. She then applied
              her professional skills to introduce Full-Circle Learning in her homeland, Ecuador, through
              government meetings and in-school trainings, including a 2019training in the Queveda District. She
              tirelessly worked to translate texts and concepts for the Ecuadorean context, while also effectively
              setting an example of kindness, thoughtfulness and leadership for her children. </p>
            <p> A teacher guides learners at a parochial school where Consuelo Banderas conducted a training in
              2019.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>



      </Row>
    </Container>
  )
}

export default Ecuador