import React, { useState } from 'react'
import {
 Container, Row, Col, Form, Label, Input, Button
} from "reactstrap";

import bannerImage from '../assets/images/64f4e36775a4071825d166b6_fclweb.jpg';
import Gmap from './helpers/Gmap';
import Newsletter from './helpers/Newsletter';
import Taxdeductible from './helpers/Taxdeductible';
const Home = () => {
  const [value, setValue] = useState('')
  const handleChange = (e) => {
    console.log(e);
    setValue(e.target.value);
  }
  return (
    <>
      <Row className='mx-0 px-0'>
        <Col className='mx-0 px-0'>
          <img
            src={bannerImage}
            alt='A group of students attempt to jump in the air all at once'
            className='image-36'
            loading="lazy"
            sizes="100vw"
          />
        </Col>
      </Row>
      <Container fluid className="section-10">
      <Row className="my-4">
        <Col
          sm="12"
          md={{
            offset: 4,
            size: 4
          }}
        >
          <Form action="/search" className="search" style={{ display: "flex"}}>
            <Label htmlFor="search-2" className="field-label" style={{ display: "none"}}>Search</Label>
            <Input className="search-input w-input rounded-0" maxLength="256" name="query" placeholder="Search…" type="search" id="search" required="" value={value} onChange={handleChange} />
            <Button type="submit" color="primary" className=" download-button search-button search-center w-button" style={{ marginLeft: "15px", width: "10em" }}>Search</Button>
          </Form>
        </Col>
      </Row>
      <Row className="my-2">
        <Col
          sm="12"
          md={{
            offset: 2,
            size: 8
          }}
        >
          <h2><strong>Our Mission</strong></h2>
          <p className="paragraph305">Full-Circle Learning (FCL), a 501-c-3 nonprofit organization and educational model, exists to help young people embrace their role as<br />
            society's humanitarians and change agents. FCL's nonprofit services include:<br />
            1.) Capacity building for teachers<br />
            2.) Purposeful education and global wisdom exchanges for learners<br />
            3.) Community impact grants for school projects that address UN Sustainable Development Goals<br />
            4.) Limited “merit &amp; need” scholarships in communities without access to free education<br />
            5.) Humanitarian aid for communities in crisis</p>
          <p className="paragraph-68">FCL helps more than a million constituents per year.<br />Please join us in offering the next generation the gift of purpose!</p>
        </Col>
      </Row>
      <Row className="my-2">
        <Col
          sm="12"
          md={{
            offset: 2,
            size: 8
          }}
        >
          <h2 className="subheading top-news"><strong>Find stories of Full-Circle Learning across the globe</strong></h2>
          <p className="paragraph-38">(Click on each dot for a story.)</p>
        </Col>
      </Row>
      <Gmap />
      <Newsletter />
      <div className="section-91"><div className="container-45"></div></div>
      <Taxdeductible />
    </Container>
    </>
  )
}

export default Home
