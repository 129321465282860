import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";

const UnityUnit = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 2,
                        size: 8
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1">Unity</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 2,
                        size: 8
                    }}
                >
                    <Row className="">
                        <Col sm="12" className="">
                            <h3>Introduction</h3>
                            <p>Enjoy relevant, purposeful learning. Follow the steps of the Full-Circle Learning model through a series of activities applicable for school or home use. Lesson plans include links for broadcast or online classrooms as well as print options for classrooms or homes without technology.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-one.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FTVi38kV6k0I&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DTVi38kV6k0I&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FTVi38kV6k0I%2Fmaxresdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Outline</h3>
                            <p>Teachers, parents or self-guided students can use this document to preview the elements and assignments to include based on available technologies.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-outline.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 2: Sing It Step</h3>
                            <p>Enjoy and discuss three songs:
                                1) The world-famous band of Rómulo Castro sings about how we carry our stories with us whenever we travel to exchange wisdom with partners across oceans. (also visit his Youtube channel.)
                                2) Young Andrew Barrera, who has participated in many FCL student service projects, decided to unite people through music during the pandemic. He played Ode to Joy online and hopes you will share it to bring others joy.
                                3) The girls who live at My Sisters Orphanage in Tanzania also offered a suggestion for how your household can sing the Unity Circle outside, to inspire neighbors and friends. Through music, unity can occur within our own four walls or all the way around the world. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-two.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FlIsD228bbNM%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DlIsD228bbNM&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FlIsD228bbNM%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FZ3cykb8r01Q%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DZ3cykb8r01Q&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FZ3cykb8r01Q%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F9Wr236KNwc0&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D9Wr236KNwc0&amp;image=http%3A%2F%2Fi.ytimg.com%2Fvi%2F9Wr236KNwc0%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 3: Unity at Home, on the Street &amp; in the Community</h3>
                            <p>Learning math and map skills can be fun, especially when we come together to play games, make art, and help people on our own street and beyond.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-three.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 4: Flowers of the Garden</h3>
                            <p>Even the youngest learners can discover a world of color and beauty as they discover that we need every color in the garden.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-four.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 5: What Do Seeds Do?</h3>
                            <p>Life begins with a seed, and although seeds may look the same, each one creates something quite unique. This hands-on lesson lets students see for themselves. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-five.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 6: Showing Unity on the Farm</h3>
                            <p>From early learners to high schoolers, students of all ages will find special unity challenges while learning about animals, climate change, agriculture and conflict resolution.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-six.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 7: Unity through Puppetry</h3>
                            <p>Put on your own puppet shows while learning about nurses around the world and multicultural art forms.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-seven.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 8: You are Not Alone</h3>
                            <p>Student Mariama Jadama has called people to unite to subdue the coronavirus pandemic. She and her friend Anna used poetry as their calling card. Poems can also unite us when we read them on paper and think about their meaning. People around the world all see the same thing when they look up on a cloudless night—stars! Read a poem by a teacher that celebrates this unit. Poetry can bring both comfort, knowledge and courage to others, just as you feel when you gaze at the night sky. Experience it for yourself.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-eight.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FYcifWcSgq4A%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DYcifWcSgq4A&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FYcifWcSgq4A%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 9: Share It and Send It</h3>
                            <p>List all you’ve done to unite for the common good. Plan a final project to do even more.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-nine.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Element 10: Unite with Your Stars</h3>
                            <p>Celebrate what each family member contributed to your projects. This final step will tell you how to hang your constellation on the wall. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-ten.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Glossary</h3>
                            <p>Read these words before you begin. Review them after you finish the unit. Share new words with your family. If you use a word three times in a day, you will likely to remember it.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/unity/unity-element-glossary.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h3>Unity Outline in Spanish</h3>
                            <p>This document provides Spanish translation of the unit outline.</p>
                            <a target="_blank" rel="noreferrer" href="https://s3.console.aws.amazon.com/s3/object/fullcirclelearningassets?region=us-east-1&amp;prefix=unity/unity-outline-spanish.pdf" className="download-button w-button">Download</a>
                            <div className="div-block-4">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                    </Row>
                </Col>



            </Row>
        </Container>
    )
}
export default UnityUnit