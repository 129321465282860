import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Haiti1 from '../../assets/images/Haiti1.png';
import Haiti2 from '../../assets/images/Haiti2.png';
import Haiti4 from '../../assets/images/Haiti4.png';
import Haiti5 from '../../assets/images/Haiti5.png';
import Haiti6 from '../../assets/images/Haiti6.png';
import Haiti7 from '../../assets/images/Haiti7.png';
import Haiti8 from '../../assets/images/Haiti8.png';

const images = [
  {
    src: Haiti1,
    title: '',
    class: '',
    key: 'Haiti1'

  },
  {
    src: Haiti2,
    title: 'A girl in Liberia holding up her book',
    class: 'img_class_inline',
    key: 'Haiti2'
  },
  {
    src: Haiti4,
    title: 'Students gathered outside in study',
    class: 'img_class_inline',
    key: 'Haiti4'
  },
  {
    src: Haiti5,
    title: 'Students gathered outside in the sun',
    class: 'img_class_inline',
    key: 'Haiti5'
  }
];

const images1 = [
  {
    src: Haiti6,
    title: 'A young girl holding up her book surrounded by her classmates',
    key: 'Haiti6'
  },
  {
    src: Haiti7,
    title: "Children in class learning with their hands reached out connecting to each other in a circle",
    key: 'Haiti7'
  },
  {
    src: Haiti8,
    title: 'Two children smile up to the camera outside',
    key: 'Haiti8'
  }
];

const Haiti = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Haiti</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} style={{ height:"450px",objectFit:"cover" }}/>
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>The Haiti earthquake of 2010 highlighted the need to unite for the sake of young people traumatized
              in its aftermath. Full-Circle Learning provided art therapy for young children in its wake.Soon
              afterward, theMeridian Foundation andNational Geographic invited Full-Circle Learning to design a
              project for girls abused in refugee camps. The girls learned to comfort one another and to practice
              listening skills when there were no psychologists available. They used writing skills, visual arts
              and photography to tell their stories to one another.</p>
            <p>Here, a Liberian girl holds a copy of the anthology that resulted from the Haitian girls’ efforts,
              <em>Girls United: Haiti Through OurEyes</em>. The book inspired
              students in<br />multiple countries. The Girls United Clubs continue to offer girls a chance to
              counsel one another to stay in school, to learn skills that benefit society, and to become resilient
              to challenges.</p>
            <p>Girls in some countries eventually saw their Girls United poems on the UnitedNations Foundation website. </p>
            <p> The Girls United was one of threeFull-Circle Learning programs in Haiti. </p>
            <p> Teacher training in Port au Prince also brought awareness of new strategies to teachers from
              multiple schools. When we emailed teacher educator Linda Gershuny after the earthquake, she
              explained she was huddled in a tent in a park, with children hovering around her. Amazingly, her
              computer still worked. </p>
            <p> FCL put out the call for comfort. Preschoolers from China’s Greentown School system immediately sent
              pictures of their songs and “blessings for Haiti” to reassure the children in the park that others
              far away cared about them. </p>
            <p> On a later trip, FCL representatives traveled far up the mountain to the town of Avril du’Bois,
              where the Curelly family had opened a school for the children of subsistence farmers. The town had
              not had a local school for a generation. Combating illiteracy, the Curellys sought to change that.
            </p>
            <p>After a Full-Circle Learning training workshop, the teachers used their skills to inspire the parents
              and children to work together for the common good.Here, teacher Christelle children taught their
              parents to plant trees on the edge of crop rows, to prevent erosion.</p>
            <p> The teachers and learners in the village school sent valuable wisdom exchanges to other schools
              around the world in the years that followed. One project came as a challenge from the teachers in
              Piru, California as they thought about how to prepare their community for natural disaster. They
              taught earthquake prevention to their families, guests and neighbors. In Haiti, a hurricane had just
              victimized relatives and countrymen in nearby towns, so the Haitian school responded with a project
              to demonstrate how to put hurricane clips on rooftops. Below children work on a deforestation
              project as others conduct their“humanity circle” in the classroom.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>




      </Row>
    </Container>
  )
}

export default Haiti