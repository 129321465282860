import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';
import cameroon from '../../assets/images/cameroon.png';
import cameroon2 from '../../assets/images/Cameroon2.png';
import cameroon3 from '../../assets/images/Cameroon3.png';

const images = [
  {
    src: cameroon2,
    title: "A group of Teachers together for teacher training"
  }, {
    src: cameroon3,
    title: "Teachers sitting in chairs in a circle consulting"
  }
];
const Cameroon = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Cameroon</h1>
        
          <div className="w-richtext">
            <figure className="w-richtext-align-fullwidth w-richtext-figure-type-image">
              <div>
                <img
                  alt="my alt"
                  src={cameroon}
                  loading="lazy" /></div>
            </figure>
            <h1>Saving Lives Through Song</h1>
            <p>&zwj;</p>
            <p>Cancer knows no borders. Still, we don’t often hear it mentioned by the smallest voices in
              society.&nbsp;</p>
            <p>During Breast Cancer Awareness Week, children at one of the newest Full-Circle Learning schools
              decided to give a gift of love and health, saving lives through song.</p>
            <p>They hope that by sending their voices around the world, they can save millions of sisters’
              lives—maybe even yours. Watch a video of their performance below.<br />&zwj;<a
                href="https://www.youtube.com/watch?v=EjIEljQBRyk" className='inquires'>See Performance</a></p>
            <p>&zwj;</p>
          </div>

          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Children in Full-Circle Learning’s original pilot program conducted wisdom exchanges with a school
              inCameroon as early as the mid-1990s. Later, in 2018, after a broader teacher training held at
              Heritage International School, high school students planned their own Advocacy project. </p>
            <p>Rising temperatures had increased the incidence of crop-ravaging pests, resulting in dire food
              insecurity in the farming community. The students reasoned that the skills learned in their
              chemistry class could benefit the community. They and students in other schools developed organic
              pest control methods and introduced them at the farms, experimenting with the solutions. The farmers
              experienced a bumper crop. Their next goal was to invent an even simple, more cost-effective organic
              pest control solutions. They saw their studies bear fruit, along<br />with the plants!</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>


      </Row>
    </Container>
  )
}

export default Cameroon