import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";

const ResiliencyUnit = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1 fw-bold">Resiliency</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Row className="">
                        <Col sm="12" className="">
                            <h2>Introduction</h2>
                            <p>Enjoy relevant, purposeful learning. Follow the steps of the Full-Circle Learning model through a series of activities applicable for school or home use Lesson plans include links for broadcast or online classrooms as well as print options for classrooms or homes without technology.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+1+Introducing+Resiliency+-+Suppo.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-dyn-bind-empty w-video w-embed">
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Outline</h2>
                            <p>Teachers, parents or self-guided students can use this document to preview the elements and assignments to include based on available technologies.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Elements+-+Outline+of+Resiliency+Unit.do.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 1 Introducing Resiliency – Support Materials</h2>
                            <p>Experience the Sense It, See It, and Shape It steps to learn more about resiliency and rivers. Click on the video and use the printed materials to make a drawing.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+1+Introducing+Resiliency+-+Suppo.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fx6i9TBABNbs&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dx6i9TBABNbs&amp;image=http%3A%2F%2Fi.ytimg.com%2Fvi%2Fx6i9TBABNbs%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 2 Show It – On the Conflict Bridge</h2>
                            <p>Read about these water challenges. Have fun making a backyard bridge to solve the dilemmas.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+2+-+Conflict+Resolution+-+Resili.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 3 Storytell It</h2>
                            <p>Click on the audio to listen to the story about the fish. Memorize it in your mind, so you can imagine yourself in the story whenever you like. Click on the document to read the story and see the picture. Name the names of new fish you meet in the river. Listen to the story every day while practicing resiliency.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+3+Storytell+It+-+Resiliency+copy.docx" className="download-button w-button">Element 3 Storytell It</a>
                            <div className="div-block resource-video">
                                <div className="video w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 4 Gift of Rivers</h2>
                            <p>Learn why rivers are so important to plants, animals and people. Learn what we can give back to the river. Learn how to help families keep our water supplies safe from disease. Watch, listen and read.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+4+Gift+of+Rivers+Reading+Supplme.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FKgC0Cxchc6A%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DKgC0Cxchc6A&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FKgC0Cxchc6A%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 5 Singing River Songs</h2>
                            <p>Appreciate the difference between a dry riverbed and a river when rain has just fallen. Start a home water conservation project. Practice the song you heard at the beginning of this unit. Learn the words of the song here.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+5+Singing+River+Songs.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F63c38YWm8fw%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D63c38YWm8fw&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F63c38YWm8fw%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FwuO4QxCtb50%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DwuO4QxCtb50&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FwuO4QxCtb50%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'> Element 5-B Singing River Songs</h2>
                            <p>Talented multinational musicians tell a message of resiliency centuries old as students identify the habits needed then and now to meet the challenge.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+5-B+(Resiliency).docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FLJsg-0uUSp4%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DLJsg-0uUSp4&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FLJsg-0uUSp4%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 6 River Poems</h2>
                            <p>Travel around the world to visit ten famous rivers with actors reading poems about them. Download the poems to see pictures, to read along and to answer the challenge questions.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+6+River+Poems+-+Readings+and+Act.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FSZ2aM7x-Rdk&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DSZ2aM7x-Rdk&amp;image=http%3A%2F%2Fi.ytimg.com%2Fvi%2FSZ2aM7x-Rdk%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 7 Share It - Ribbons of Rivers</h2>
                            <p>Sharing information serves the people around you. It’s time to teach others all you’ve learned! Download here to find out how!</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+7+Share+It+-+Ribbons+of+Rivers.d.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video"><div className="video w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 8 Send It – Clay Pot Project</h2>
                            <p>Honor those who store or carry water in your community — and who have done so throughout history. Read about this fun idea, and then watch a video.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+8+Send+It+-+Clay+Pot+Project.doc.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FxZg58jr4XQk&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DxZg58jr4XQk&amp;image=http%3A%2F%2Fi.ytimg.com%2Fvi%2FxZg58jr4XQk%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed">
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 9 Sustain It</h2>
                            <p>Click here to meet the challenges of the future with resiliency. We offer appreciation for this music video to composer Rómulo Castro, to the group Grupo Tuira, and to guest musicians Luke Wilson, Jeana Paul and Virgil Shaw, for the song "Invictos", from the album, "Multiverso" (2019).</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+9+Sustain+It+Step+for+Resiliency.docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FLJsg-0uUSp4%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DLJsg-0uUSp4&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FLJsg-0uUSp4%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className='mt-3'>Element 10 Glossary for Resiliency</h2>
                            <p>Read these words before you begin. Review them after you finish the unit. Share new words with your family. If you use a word three times in a day, you will likely to remember it.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/resiliency/Element+10+Glossary+for+Resiliency+Unit..docx" className="download-button w-button">Download</a>
                            <div className="div-block resource-video">
                                <div className="video w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>

                    </Row>
                </Col>


            </Row>
        </Container>
    )
}
export default ResiliencyUnit