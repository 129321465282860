import React, { useState, useEffect  } from 'react'
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
} from "reactstrap";
import logo from '../assets/images/logo.png';
import guidestar from '../assets/images/guidestar.png';
import toprate from '../assets/images/2023-top-rated-awards-badge-embed.png';
import menuIcon from '../assets/images/604af88c7373be692ef9b359_menu-icon.png'

const Header = () => {
  const [open, setOpen] = useState(false);
  const showCollpse = () => {
    setOpen(!open);
  };
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    // Retrieve active link from localStorage on component mount
    const storedActiveLink = localStorage.getItem('activeLink');
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, []);
  const handleLinkClick = (link) => {
    // Add or remove class based on activeLink state
    setActiveLink(link === activeLink ? '' : link);

    // Store active link in localStorage
    localStorage.setItem('activeLink', link === activeLink ? '' : link);
  };
  return (
    <Navbar color="light" expand="md" light>
      <Row className='mobile-row'>
        <Col xs="2" className='menu-buttons px-0'>
          <NavbarBrand href="/" className={`${activeLink === '/' ? '' : ''}`} onClick={() => handleLinkClick('/')}>
            <img width="75" alt="Brand for Full-Circle Learning" src={logo} className="logo-image" />
          </NavbarBrand>
          <a href="/friends/how-you-can-help" className="button button-3">Donate</a>
          <NavbarToggler onClick={showCollpse} >
            <img width="22" alt="" src={menuIcon} className="menu-icon" />
          </NavbarToggler>
        </Col>
        <Col xs="9" className="menu-items">
          <Collapse isOpen={open} navbar>
            <Nav className="" navbar>
              <NavItem className={`${activeLink === 'educator' ? 'active' : ''}`} >
                <NavLink 
                  className="fw-bold"
                  href="/educator"
                  onClick={() => handleLinkClick('educator')}
                >
                  Educators
                </NavLink>
              </NavItem>
              <NavItem className={`${activeLink === 'inquirers' ? 'active' : ''}`} >
                <NavLink 
                  className="fw-bold"
                  href="/inquirers"
                  onClick={() => handleLinkClick('inquirers')}
                >
                  Inquirers
                </NavLink>
              </NavItem>
              <NavItem className={`${activeLink === 'about-us' ? 'active' : ''}`} >
                <NavLink 
                  className="fw-bold"
                  href="/about-us"
                  onClick={() => handleLinkClick('about-us')}
                >
                  About Us
                </NavLink>
              </NavItem>
              <NavItem className='guidstar d-flex align-items-center justify-content-center p-0'>
                <NavLink href="/friends/annual-reports" className={`px-0 ${activeLink === '/friends/annual-reports' ? '' : ''}`} onClick={() => handleLinkClick('/friends/annual-reports')}>
                  <img src={guidestar} alt="Guidestar Platinum Seal of Transparency 2020" className="image" />
                </NavLink>
                <div className="text-block">
                  <em className="italic-text">Guidestar's highest certification of transparency for nonprofit organizations.</em>
                </div>
              </NavItem>
              <NavItem className="p-0">
                <div className="w-embed w-script">
                  <div className="gnp_trb" id="gnp_trb">
                    <a href="https://greatnonprofits.org/org/full-circle-learning-inc">
                      <img src={toprate} alt="Full-Circle Learning Inc Nonprofit Overview and Reviews on GreatNonprofits" title="2022 Top-rated nonprofits and charities" />
                    </a>
                    <br />
                  </div>
                </div>
              </NavItem>
              <NavItem className="mb-nav-link">
                <a href="/friends/how-you-can-help" className="mobile-btn mb-nav-link">
                  DONATE
                </a>
              </NavItem>
            </Nav>
          </Collapse>
        </Col>
        <Col xs="1" className={`right-button ${activeLink === '/friends/how-you-can-help' ? '' : ''}`} onClick={() => handleLinkClick('/friends/how-you-can-help')}>
          <a href="/friends/how-you-can-help" className="button donate-btn w-inline-block" >
            DONATE
          </a>
        </Col>
      </Row>
    </Navbar>
  )
}

export default Header
