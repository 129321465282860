import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US27 from '../../../assets/images/US27-p-500.png';
import US28 from '../../../assets/images/US28-p-500.png';
import US42 from '../../../assets/images/US42-p-500.png';
import US44 from '../../../assets/images/US44-p-500.png';
import US43 from '../../../assets/images/US43-p-500.png';
import US45 from '../../../assets/images/US45.png';
import US46 from '../../../assets/images/US46-p-500.png';
const images = [
    {
        src: US27,
        title: "Wall mural that reads with head 22"
    },
    {
        src: US28,
        title: "Children read the mural 22"
    }
];
const images1 = [
    {
        src: US42,
        title: "Wall mural that reads with head 42"
    },
    {
        src: US44,
        title: "Children read the mural 33"
    },
    {
        src: US43,
        title: "Wall mural that reads with head 43"
    },
    {
        src: US45,
        title: "Children read the mural 45"
    },
    {
        src: US46,
        title: "Children read the mural 46"
    }
];

const PiruFullCircleLearningPreschool = () => {
    const allImages = {
        images,
        images1
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = allImages[arrayName];
        if (selectedArray) {
            toggleModal();
            setCurrentIndex(selectedArray[imageId]);
        }
    };
    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1>Piru Full-Circle Learning Preschool</h1>

                    <h2><strong>Leadership Sprouts when Watered</strong></h2>

                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} style={{ height:"500px", objectFit:"cover" }} />
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>&nbsp;Jessica was one of the first graduates of the Full-Circle Learning preschool, a project in Piru,
                        California supported by tobacco tax funds.&nbsp;</p>
                    <p>&nbsp;By the time her class ended middle school, the children had attracted the attention of community
                        leaders for their record of community service and academic excellence. The school district was inspired
                        to open up its first official preschool and hire away its director Sugey Lopez.</p>
                    <p>&nbsp;Meanwhile, Jessica and her friend, explained that&nbsp; Full-Circle Learning programs had helped
                        them understand the purpose of life and the many opportunities for service.</p>
                    <p>&nbsp;Pictured here, they engaged in the annual beach clean up, during which learners tallied types of
                        trash to take a next step toward reducing pollution.&nbsp;</p>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images1.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images1")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} />
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h2><strong>&nbsp;The Science of Habits-of-Heart</strong></h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>&nbsp;Preschool at Rancho Sespe included hands-on methods of learning about the water cycle during a
                        drought year. Children not only taught older siblings the concepts but incorporated their study of the
                        habit of Patience. They learned fractions by measuring ingredients to make pies, inviting farmers to a
                        Thank You Banquet for practicing patience during the drought. (Photo below by Aimee Porter)&nbsp;</p>
                    <p>An earlier class created a Kindness Kingdom.</p>
                    <p>&nbsp;Preschool pictures on these pages were taken at Rancho Sespe Village, Piru Community Center and at
                        Piru’s most recent FCL location, San Salvador Mission.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default PiruFullCircleLearningPreschool