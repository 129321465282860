import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import SouthAfrica1 from '../../assets/images/SouthAfrica1.png';

const images = [
  {
    src: SouthAfrica1,
    title: 'A large group of students gathered in the foreground with rolling hills in the background.'

  }
];

const SouthAfrica = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">South Africa</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Gabazi lies in Eastern Cape, near Nelson Mandela’s birthplace. In this photo, a thousand students
              sang a call-and-response song about Full-Circle Learning after their school adopted the model.</p>
            <p>Maureen Mungai took Full-Circle Learning to schools and community-based organizations between 2010
              and 2015. More recent projects in South Africa include Cyril Turton’s Read to Lead
              program near Soweto in Johannesburg, where collaboration began in 2020. </p>
            <p> </p>
            <p>&zwj;</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default SouthAfrica