import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";

const StaffDevelopment = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1 fw-bold">Staff Development Outlines</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Row>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-39">What Can I Expect in a Full-Circle Learning Workshop?</h2>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/What-Can-I-Expect-in-a-Full-Circle-Learning-Workshop.pdf" className="download-button w-button">Download</a>
                        </Col>
                        <Col sm="12" className="my-2">
                            <h2 className="heading-39">Outline of Suggested Staff Meeting Agendas for Learning Leaders</h2>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/Outline-of-Suggested-Staff-Meeting-Agendas-for-Learning-Leaders.pdf" className="download-button w-button">Download</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default StaffDevelopment