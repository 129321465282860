import React, {
    useState
} from 'react';
import {
    Row, Col, Container, CardImg, Card
} from "reactstrap";
import MyModal from '../../helpers/MyModal';

import US56 from '../../../assets/images/US56-p-500.png';
import US58 from '../../../assets/images/US58.png';
import US57 from '../../../assets/images/US57.png';

const images = [
    {
        src: US56,
        title: "Young students playing a game with a ribbon grouping them together"
    },
    {
        src: US58,
        title: "Students outside passing cups behind them while standing in a line"
    }, {
        src: US57,
        title: "Students holding hands in a large group game"
    }
];

const DiabetesAwareness = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModalImage = (imageId, arrayName) => {
        const selectedArray = arrayName === 'images' ? images : '';
        toggleModal();
        setCurrentIndex(selectedArray[imageId]);
    };

    return (
        <Container>
            <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1>Diabetes Awareness Camp (co-hosted by Levitate)</h1>

                    <h2><strong>Health Initiatives and Full-Circle Learning</strong></h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                    className='muli-colmn-tw'
                >
                    {images.map((image, index) => (
                        <Card
                            className="image-card"
                            key={image.title}
                            onClick={() => showModalImage(index, "images")}
                            style={{ padding: "6px 0px" }}
                        >
                            <CardImg src={image.src} />
                        </Card>
                    ))}
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <p>Like schools around the world, US students have conducted many projects centered on public health.</p>
                    <p>In 2011, teachers in a diabetes awareness program cosponsored by Levitate, Meridian and FCL infused
                        community education into the summer school program. Among many projects, students learned healthy
                        shopping and cooking tips from the doctor above, who founded the Farmers Markets at Kaiser Permanente
                        hospitals in the San Francisco Bay area.</p>
                    <p>The children tracked the charts of a diabetic program cofounder, to observe the impact of sugar on heath.
                        Parents in this San Leandro neighborhood, where diabetes rate stood at 19%, claimed their children had
                        saved the lives of their family members.</p>
                    <p>&nbsp;A dozen schools in Arizona and Southern California have also conducted diabetes awareness projects.
                        At left, FCL alumni Kathy Rosales received a fellowship as a nursing student to help Tarzana students
                        spread awareness and prepare for a health fare, where they checked parents for signs of pre-diabetes
                        through blood pressure and body mass index.</p>
                </Col>

            </Row>
        </Container>
    )
}

export default DiabetesAwareness