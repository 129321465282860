import React, { useState, useMemo, useEffect, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Row, Col, Form, Input, Button } from "reactstrap";
import axios from "axios";
import countryList from "react-select-country-list";
import { SuccessContext } from "./SuccessContext";

const CheckoutForm = ({ amount, mode }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const { setSuccess } = useContext(SuccessContext);
  const [loading, setLoading] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address1: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });
  const [touchedFields, setTouchedFields] = useState({});

  const handleFormValue = (ev, name) => {
    const value = ev.target.value;
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setFormData({ ...formData, [name]: value });
      if (!emailRegex.test(value)) {
        console.log("Valide Check");
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleBlur = (name) => {
    setTouchedFields({ ...touchedFields, [name]: true });
  };
  const isEmptyAndTouched = (fieldName) => {
    return formData[fieldName] === "" && touchedFields[fieldName];
  };

  const handleInput = (event) => {
    // Allow only numeric characters or backspace/delete
    const inputChar = event.data;
    if (inputChar && !/^\d$/.test(inputChar)) {
      event.preventDefault();
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    for (const key in formData) {
      if (formData[key] === "") {
        handleBlur(key);
        return;
      } else if (key === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData[key])) {
          handleBlur(key);
          return;
        }
      }
    }
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setLoading(false);
      setError(error.message);
    } else {
      console.log("PaymentMethod:", paymentMethod);
      // Handle successful payment
      try {
        let data = JSON.stringify({
          paymentMethodId: paymentMethod.id,
          amount,
          mode,
          formData,
        });

        let config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: "/.netlify/functions/stripeFunction",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then(async (response) => {
            const data = response.data;
            console.log(data);
            const confirmPayment = await stripe?.confirmCardPayment(
              data.clientSecret
            );
            if (confirmPayment?.error) {
              console.log(confirmPayment.error.message);
            } else {
              window.location.href = "/friends/how-you-can-help";
              setLoading(false);
              setSuccess(true);
              window.location.href = "/success";
              console.log("Success! alert should show");
            }
          })
          .catch((error) => {
            console.log(error);
            setError("Error processing payment");
          });
      } catch (error) {
        console.error("Error processing payment:", error);
        setError("Error processing payment");
      }
    }
  };

  useEffect(() => {
    const getUserCountry = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setFormData({ ...formData, country: data.country });
      } catch (error) {
        console.error("Error fetching user country:", error);
      }
    };

    getUserCountry();
    // eslint-disable-next-line
  }, []);
  return (
    <Form className="payment-form" onSubmit={handleSubmit}>
      <Row>
        <Col sm="6">
          <Input
            type="text"
            placeholder="First Name*"
            name="firstName"
            autoComplete="given-name"
            value={formData.firstName}
            onBlur={() => handleBlur("firstName")}
            className={isEmptyAndTouched("firstName") ? "red-border" : ""}
            onChange={(ev) => handleFormValue(ev, "firstName")}
          />
        </Col>
        <Col sm="6">
          <Input
            type="text"
            placeholder="Last Name*"
            name="lastName"
            autoComplete="family-name"
            value={formData.lastName}
            onBlur={() => handleBlur("lastName")}
            className={isEmptyAndTouched("lastName") ? "red-border" : ""}
            onChange={(ev) => handleFormValue(ev, "lastName")}
          />
        </Col>
        <Col sm="6">
          <Input
            type="email"
            placeholder="Email Address*"
            name="email"
            autoComplete="email"
            value={formData.email}
            onBlur={() => handleBlur("email")}
            className={isEmptyAndTouched("email") ? "red-border" : ""}
            onChange={(ev) => handleFormValue(ev, "email")}
          />
        </Col>
        <Col sm="6">
          <Input
            type="tel"
            placeholder="Phone Number*"
            name="phoneNumber"
            autoComplete="tel"
            value={formData.phoneNumber}
            pattern="[0-9]*"
            maxLength={20}
            onBlur={() => handleBlur("phoneNumber")}
            onBeforeInput={handleInput}
            className={isEmptyAndTouched("phoneNumber") ? "red-border" : ""}
            onChange={(ev) => handleFormValue(ev, "phoneNumber")}
          />
        </Col>
        <Col sm="12">
          <Input
            type="select"
            placeholder="Country*"
            name="country"
            autoComplete="country"
            value={formData.country}
            onBlur={() => handleBlur("country")}
            className={isEmptyAndTouched("country") ? "red-border" : ""}
            onChange={(ev) => handleFormValue(ev, "country")}
          >
            {options.map((list) => (
              <option key={list.value} value={list.value}>
                {list.label}
              </option>
            ))}
          </Input>
        </Col>
        <Col sm="6">
          <Input
            type="text"
            placeholder="Address*"
            name="address1"
            autoComplete="address-line1"
            value={formData.address1}
            onBlur={() => handleBlur("address1")}
            className={isEmptyAndTouched("address1") ? "red-border" : ""}
            onChange={(ev) => handleFormValue(ev, "address1")}
          />
        </Col>
        <Col sm="6">
          <Input
            type="text"
            placeholder="City*"
            name="city"
            autoComplete="address-level2"
            value={formData.city}
            onBlur={() => handleBlur("city")}
            className={isEmptyAndTouched("city") ? "red-border" : ""}
            onChange={(ev) => handleFormValue(ev, "city")}
          />
        </Col>
        <Col sm="6">
          <Input
            type="text"
            placeholder="State*"
            name="state"
            autoComplete="address-level1"
            onBlur={() => handleBlur("state")}
            className={
              isEmptyAndTouched("state") ? "red-border lastfield" : "lastfield"
            }
            value={formData.state}
            onChange={(ev) => handleFormValue(ev, "state")}
          />
        </Col>
        <Col sm="6">
          <Input
            type="text"
            placeholder="Postal Code*"
            name="postalCode"
            autoComplete="postal-code"
            onBlur={() => handleBlur("postalCode")}
            className={
              isEmptyAndTouched("postalCode")
                ? "red-border lastfield"
                : "lastfield"
            }
            value={formData.postalCode}
            onChange={(ev) => handleFormValue(ev, "postalCode")}
          />
        </Col>
        <CardElement
          className="CardElement my-4"
          options={{
            style: {
              base: {
                fontSize: "16px",
                fontFamily: "Arial, sans-serif",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
            },
            hidePostalCode: true,
          }}
        />
        <Button type="submit" disabled={!stripe || loading}>
          {loading
            ? "Processing..."
            : `Pay $${amount} ${
                mode === "on_subscription"
                  ? "per month donation"
                  : "one time donation"
              }`}
        </Button>
      </Row>
      {error && <div>{error}</div>}
    </Form>
  );
};

export default CheckoutForm;
