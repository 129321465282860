import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import c181_c5 from '../../assets/images/c181_c5-p-1080.png';
import Ghana2 from '../../assets/images/Ghana2.png';
import Ghana3 from '../../assets/images/Ghana3.png';

const images = [
  {
    src: c181_c5,
    title: "A group of Teachers in Ghana."
  }, {
    src: Ghana2,
    title: "Four student farmers are honored."
  }
];
const images1 = [
  {
    src: Ghana3,
    title: "A class of students greeted guests."
  }
];

const Ghana = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Ghana</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
            <div className="rich-text-block-2 color-link w-richtext">
              <p> Full-Circle Learning teachers in Ghana first received Full-CircleLearning training in 2017. By 2020,
                the students had become leaders in humanitarian service, showing their strengths especially during
                the 2020 pandemic. Inspired by their drive to help the most vulnerable, one teacher gave the shirt
                off his back to provide material for the students to wash in order to offer masks to the neediest in
                the community. Other outcomes centered on food security in relation to specific habits-of-heart.
              </p>
              <p> Through a Full-Circle Learning grant, students collaborated with experienced farmers to make a
                garden, so as to help provide food for the community. On Farmers’ Day, the community awarded the
                youth six cutlasses and some seedlings to continue their good work. Teachers from three more schools
                received training shortly afterward. </p>
              <p> One head teacher Joseph Adzaglo, I think this is how
                we should train our children.” Ghana’s Regional Facilitator, Henry Quarshie, added: </p>
              <p> “The communities have recognized how the students are trying their best to exert change and to make
                the human family great in their localities...everyone in the communities helps to make
                sure[students’] activities succeed...Each and every one of us has a role to play.” </p>
            </div>
            <div className="rich-text-block w-richtext">
              <p> Above: The Department of Agriculture’s “Farmers Day” officialshonored four Full-Circle Learners in
                2020, as the best studentfarmers. </p>
              <p> Below: In 2018, Cedar Hill’s class of Love welcomed guests—withlove. </p>
              <p>&zwj;</p>
            </div>
          </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>


      </Row>
    </Container>
  )
}

export default Ghana