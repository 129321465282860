import React from 'react'
import {
    Row, Col, List, Container
} from "reactstrap";

import whatwedo from '../../assets/images/whatwedo.png';
import ForgingFuture from '../../assets/images/ForgingFuture-p-500.png';
import c181_c5 from '../../assets/images/c181_c5-p-1080.png';
import lian_and_globe from '../../assets/images/lian_and_globe.png';
import cc_croppedwomaninfield from '../../assets/images/cc_croppedwomaninfield.png';
import TeacherAndStudent from '../../assets/images/TeacherAndStudent-p-500.png';
import image1 from '../../assets/images/1_image1-31.jpeg';
import image2 from '../../assets/images/image2-33.jpeg';
import image3 from '../../assets/images/image3-35.jpeg';
import image4 from '../../assets/images/image4-37.jpeg';
import image5 from '../../assets/images/image5-39.jpeg';
import image6 from '../../assets/images/image6-41.jpeg';
import image7 from '../../assets/images/image7-43.jpeg';
import image8 from '../../assets/images/image8-44.jpeg';
import image9 from '../../assets/images/image9-45.jpeg';
import rightfacetoface from '../../assets/images/rightfacetoface-p-500.jpeg';
import fullgroupbanner from '../../assets/images/fullgroupbanner-p-1080.jpeg';
import kidwithdrawing from '../../assets/images/kidwithdrawing-p-500.jpeg';
import c1_coronadrawing from '../../assets/images/c1_coronadrawing.jpeg';
import happykidwithdrawing from '../../assets/images/happykidwithdrawing-p-500.jpeg';

const imagesList = [{
    id: 1,
    src: image1,
    alt: "Full circle learning student"
},
{
    id: 2,
    src: image2,
    alt: "Full circle learning student"
},
{
    id: 3,
    src: image3,
    alt: "Full circle learning student"
},
{
    id: 4,
    src: image4,
    alt: "Full circle learning student"
},
{
    id: 5,
    src: image5,
    alt: "Full circle learning student"
},
{
    id: 6,
    src: image6,
    alt: "Full circle learning student"
},
{
    id: 7,
    src: image7,
    alt: "Full circle learning student"
},
{
    id: 8,
    src: image8,
    alt: "Full circle learning student"
},
{
    id: 9,
    src: image9,
    alt: "Full circle learning student"
}]
const WhatWeDo = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1 fw-bold">What We Do</h1>
                    <h2 className="subheading secondary">Mission and&nbsp;Vision</h2>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Row>
                        <Col sm="6" style={{ display: "flex", justifyContent: "center" }}>
                            <img
                                src={whatwedo}
                                loading="lazy"
                                alt="A chart describing a journey from Character-Education/Self-Master, Academics, Art/Music, Peacemaking Skills and ending at Local and Global Service."
                                style={{width: "275px"}}
                            />
                        </Col>
                        <Col sm="5">
                            <p>Full-Circle Learning (FCL) exists to help young people embrace their role as society’s humanitarians
                                and change agents. </p>
                            <p>The Full-Circle Learning family holds a central belief that meaningful participation in society and
                                purposeful learning stem from commitment to one human family. </p>
                        </Col>
                    </Row>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 2,
                        size: 8
                    }}
                >
                    <p>Some educational models details what, when or how to learn. Full-Circle Learning emphasizes the <em>why
                    </em>of our motivation to learn: to find peak experiences as we tap our potential to improve the wellbeing and destiny of others. </p>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 2,
                        size: 8
                    }}
                >
                    <Row>
                        <Col sm="6" style={{ display: "flex", justifyContent: "center" }}>
                            <img
                                src={ForgingFuture}
                                loading="lazy" width="320" sizes="(max-width: 479px) 90vw, 320px"
                                alt="Three young women facing an audience."
                            />
                        </Col>
                        <Col sm="5" >
                            <p className="paragraph-20">This integrated, project-based learning model motivates learners toward self-mastery while addressing sustainable development goals and community needs such as hunger, poverty, public health, gender equity, eldercare, environment and agriculture.&nbsp;</p>
                        </Col>
                    </Row>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 2,
                        size: 8
                    }}
                >
                    <p>The organizational philosophy honors the nobility of teachers and learners and promotes true “education as transformation.” Our motto for teachers and learners is “to lead is to serve and to serve is to lead.”&nbsp;</p>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 2,
                        size: 8
                    }}
                >
                    <Row>
                        <Col
                            sm="12"
                        >
                            <h2 className="subheading my-3">Five Aspects of Our Work</h2>
                            <p className="paragraph-17">Full-Circle Learning (FCL) fulfills its mission by offering five basic services:
                                Educator capacity building, wisdom exchanges, school scholarships, direct student service and emergency
                                relief. Read about each of these services here.</p>
                            <img
                                src={c181_c5}
                                loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 95vw, (max-width: 991px) 698px, 910px"
                                alt="A large group of Teachers in Africa at a gathering. "
                            />
                            <List tag="ol" className='my-3'>
                                <li>
                                    <p><strong>Educator Capacity Building</strong>: The Full-Circle Learning nonprofit organization
                                        trains new and veteran teachers in the Full-Circle Learning model of education.<br />Through this
                                        training process, our primary line of activity, teachers evaluate their common vision and the
                                        noble role they play. They expand their understanding of transformational learning theories,
                                        strategies, and practices to develop altruistic identities and collaborative school culture,
                                        based on the ultimate outcome of service to an expanding network of humanity.<br />Customized
                                        training and support materials help educators to infuse the following features into every
                                        learning unit:</p>
                                    <List type="unstyled">
                                        <li>Character education based on a habit-of-heart theme;</li>
                                        <li>Academic interdisciplinary content based on regional standards;</li>
                                        <li>Conflict resolution applied to the project goals;<br /></li>
                                        <li>Thematically linked arts that uplift or advocate;<br /></li>
                                        <li>Resulting projects that employ all the above, in service to the transformative impacts on
                                            the local and global community.</li>
                                    </List>
                                    <div className="d-flex justify-content-center">
                                        <img
                                            src={lian_and_globe}
                                            loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 696px) 89vw, 620px"
                                            alt="A student takes of a globe on a stand with"
                                            className="image-23" />
                                    </div>
                                </li>
                                <li><strong>Wisdom Exchange</strong>: FCL assists in the coordination of wisdom exchanges among schools
                                    in disparate regions or countries. The process helps learners of all ages honor culturally relevant
                                    challenges, regional education standards and local community needs, while also helping them see
                                    through the broader lens of collaborative problem solving with diverse groups.<br />A reflection of
                                    the Full-Circle Learning model, the wisdom exchange projects encourage purposeful learning in
                                    pursuit of service to the human family. A typical project addresses locally identified needs
                                    pertaining to public health, agriculture, climate change, elder care, gender equity, peacemaking.
                                    the UN development goals.
                                </li>
                                <li><strong>School Scholarships</strong>: FCL selectively offers opportunities for small project grants
                                    and scholarships. Available by invitation only, this assistance is offered to encourage exemplary
                                    planning among schools and students engaged in Full-Circle Learning projects.
                                </li>
                                <li><strong>Direct Service</strong>: Full-Circle Learning manages programs with direct student support
                                    through legacy projects such as summer schools.
                                </li>
                                <li><strong>Emergency Relief</strong>: When Full-Circle Learning schools request assistance with dire
                                    temporary needs, we strive to make special help available. Examples have included Ebola Relief and
                                    Pandemic-related Food Aid for closed schools. These internal grants are managed by regional
                                    facilitators, based on available funds.
                                </li>
                            </List>
                            <div className="d-flex flex-wrap justify-content-around">
                                <img
                                    src={cc_croppedwomaninfield}
                                    loading="lazy"
                                    alt="A woman smiling in the field"
                                    className="image-25"
                                />
                                <img
                                    src={TeacherAndStudent}
                                    loading="lazy"
                                    width="320"
                                    height="200"
                                    alt="A Student and his Teacher are holding a water bottle."
                                    sizes="(max-width: 479px) 93vw, 400px"
                                    className="image-24"
                                />
                            </div>
                            <h1 className="subheading my-3">Platforms for Teacher Training</h1>
                            <p>(Onsite Workshops; Online Courses, University Collaborations)</p>
                            <p><strong>Onsite Workshops</strong>: When schools, districts, agencies or CBOs in a region request
                                training, FCL provides free professional development workshops. This service is available based on the
                                accessibility of regional facilitators.</p>
                            <p>The workshop agenda includes: planning with a common vision; transformational pedagogy; integrated
                                curriculum design and assessment; effective strategies in classroom management and building a positive
                                school culture.</p>
                            <p><strong>Online Courses</strong>: The distance learning resources on in the Resource section of this
                                website include an education course called Why We Learn. Small cohorts can learn together or instructors
                                can apply the material as the basis for a 9-week course, with practicum opportunities. Course sessions
                                appear as slides. An ancillary text and a book called Planning Tools and Templates are included.</p>
                            <p><strong>University Collaborations</strong>: University representatives are free to apply Full-Circle
                                Learning content in teachers’ college courses. China’s Zhejiang Normal University began collaborating
                                with FCL to provide mandatory courses for its teachers college students in the 2000s. Chapman University
                                included a course based on Habits of Humanitarians. The Teachers College of Lesotho received training in
                                2020. For arrangements, inquire at info@fullcirclelearning.org.</p>
                            <h1 className="subheading my-3">Our Success Stories</h1>
                            <h4 className="subheading my-2">Full-Circle Learners Act for Justice</h4>

                            <Row>
                                <Col
                                    sm="12"
                                    md={{
                                        offset: 1,
                                        size: 10
                                    }}
                                >
                                    <Row style={{ margin: "auto 10px", display: "flex", justifyContent: "center" }}>
                                        {imagesList.map((image) => (
                                            <Col sm="3" key={image.id}>
                                                <img src={image.src} loading="lazy" alt={image.alt} style={{ width: "100%" }} />
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                            <p>2023 Mahmoudi Justice Scholarship Recipients from Full-Circle Learning Countries (left to
                                right):&nbsp;Akitowa, Navalia, Oumie, Muhammad, Anna, Isharam, Fazil, Mijamanji, and Moise.</p>
                            <h2 className="subheading my-3">Social Justice Partnership</h2>
                            <p>The Mahmoudi Scholarship Panel was pleased to announce the 2023 awardees of thescholarship in justice on
                                December 27, 2023. Full-Circle Learning students from The Gambia, Uganda, and Kenya (listed by first
                                name) were among the winning candidates. This year’s applicants included students and refugees who, in
                                addition to maintaining an excellent academic record, promoted justice in their schools and communities
                                in a variety of ways. These include offering education to children, tribal unification, reduction in
                                teenage pregnancy, deforestation, girls’ education, community development, waste management, and
                                conflict resolution. &nbsp;</p>
                            <h2 className="subheading my-3">Background</h2>
                            <p>Established in 2021 by the Cambridge-based The Ward &amp;amp; Brown Foundation and supported by The
                                Zambian Gems Foundation in Oxford, UK and by Full Circle Learning in theUnited States, the Mahmoudi
                                Scholarship benefits 15-18-year-old African or Asian youth from diverse backgrounds. The scholarship
                                supports those who demonstrate academic excellence coupled with an exceptional approach to promoting
                                access to justice on a community level.</p>
                            <p>The Mahmoudi Scholarship for Justice was established in honour of one of the most impactful and
                                multi-talented couples in contemporary Iran, &nbsp;Zhinus and Houshang Mahmoudi. Zhinus (Ne’mat)
                                Mahmoudi was the first female Iranian meteorologist and led the country’s National Meteorological
                                Organization. Houshang Mahmoudi was a documentary filmmaker, lawyer, educator and writer. He started
                                Iran’s first children’s edutainment television program, which promoted the dignity of children and
                                youth. After the establishment of the Islamic Republic of Iran, Houshang disappeared on 21 August 1980,
                                and Zhinous was executed on 27 December 1981.</p>
                            <p className="paragraph-67">For more information about the Mahmoudi Social Justice scholarships, email <a
                                href="mailto:barnet@thewbf.org" className="link-22 inquires">barnet@thewbf.org</a>. </p>
                            <h1 className="subheading my-3">Sample Stories</h1>
                            <p className="paragraph-66">For many more stories from around the world, click on the&nbsp;<a href="/"
                                className="link-23 inquires">country locations on the map</a> or click on the most recent community impact
                                report, on the home page.</p>
                            <h1 className="subheading my-3">Sample Videos</h1>
                            <p><a href="https://www.youtube.com/@officialfull-circlelearnin6087" className='inquires'>Official Full-Circle Learning Youtube
                                channel here</a></p>
                            <div>
                                <div className="w-video w-embed whatwedo_inline_style">
                                    <iframe
                                        className="embedly-embed"
                                        src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fmw8-hEpFgZM&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dmw8-hEpFgZM&amp;image=http%3A%2F%2Fi.ytimg.com%2Fvi%2Fmw8-hEpFgZM%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                                        title="YouTube embed" allow="autoplay; fullscreen"
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap">
                                <img
                                    src={rightfacetoface}
                                    loading="lazy" width="320" height="250" alt="Students face each other on a project mat."
                                    sizes="(max-width: 479px) 90vw, 320px" className="image-14"
                                />
                            </div>
                            <img
                                src={fullgroupbanner}
                                loading="lazy"
                                sizes="(max-width: 479px) 94vw, (max-width: 767px) 95vw, (max-width: 991px) 698px, 910px"
                                alt="A group of teachers hold up a full circle learning banner."
                            />
                            <p>Pictured here, 28 Liberian schools worked together on school garden projects to reduce hunger. Another
                                project in the Gambia taught carpentry to create sanitary eating areas in the marketplace. Students at
                                yet another school planned to teach nutrition to mothers of food-insecure children.</p>
                            <p>Across a dozen nations, FCL teachers received assistance as they applied the principles of Full-Circle
                                Learning to distance learning outcomes throughout the pandemic. Some teachers and students contributed
                                content to share with other countries for their distance learning units. The countries continue to
                                receive community impact grants, scholarships and free professional development for teachers.</p>
                            <div className="w-video w-embed whatwedo_inline_style">
                                <iframe
                                    className="embedly-embed"
                                    src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FanwBuTgUw-E%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DanwBuTgUw-E&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FanwBuTgUw-E%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                                    title="YouTube embed" allow="autoplay; fullscreen"
                                    allowFullScreen>
                                </iframe>
                            </div>
                            <p>A major highlight occurred in early 2020, when Liberian students remembered their experience with Ebola
                                and reached out in a wisdom exchange with China, whose children had been schooling at home for months
                                already. The secondary students sang a song they wrote, based on the habit of empathy. The young Chinese
                                students, in turn, sent pictures depicting how to complete home learning units.</p>
                            <div className="d-flex justify-content-around mt-3">
                                <img
                                    src={kidwithdrawing}
                                    loading="lazy"
                                    height="320"
                                    sizes="(max-width: 479px) 88vw, 240px"
                                    alt="A student with their drawing."
                                />
                                <img
                                    src={c1_coronadrawing}
                                    loading="lazy" alt="A child's drawing of the virus with medicine and a person with a sign."
                                    height="320"
                                />
                                <img
                                    src={happykidwithdrawing}
                                    loading="lazy" sizes="(max-width: 479px) 88vw, 214.2890625px"
                                    height="320"
                                    alt="A child is beaming with happiness holding their drawing."
                                />
                            </div>
                            <h1 className="subheading">Impact Analysis</h1>
                            <p>Joy, purpose and other-directedness transform learners, learning environments and society.
                                Community-building benefits occur as teachers prioritize motivational integrated-education projects and
                                deliberately apply Full-Circle Learning teaching strategies.</p>
                            <p>An independent study in 2022 measured the relationship between humanitarian education systems and
                                resilient identities, based on decades of Full-Circle Learning data.<br /><a
                                    href="https://fullcirclelearningassets.s3.amazonaws.com/Independent+Assessment+at+Year+30+-+Humanitarian+Education+Systems+and+Resilient+Identities+(2023_01_30+04_09_02+UTC).pdf" className='inquires'>Click
                                    here to view the summary.</a></p>
                            <p>Research at Full-Circle Learning, conducted through teacher, parent and student surveys as well as
                                through community impact studies, shows the power of altruistic peak experiences in relation to
                                learning. Serving the human family gives purpose to new academic skills, innate creative talents,
                                collaborative capacities and socio-emotional strengths.</p>
                            <h1 className="subheading">A 2020 Study on Intentionality</h1>
                            <p>A 2020 study, conducted by Full-Circle Learning, gathered input from 15 representative regional trainers
                                from the Gambia, Ghana, Kenya, Liberia, Nigeria and Zambia. Their combined constituents included a total
                                of 1,260 teachers and 61,779 learners from a range of learning environments including village, public
                                and private schools, with projects centered on home study during the global school closures. The first
                                survey gauged the level of their adaptations to the pandemic before their group training. The second
                                survey, six months later, measured the impact of their own group-trainer sessions on the quality of
                                teacher training and student learning within their regions. The May survey indicated that all active
                                trainers had discovered ways to engage learners in home-based learning, despite lockdowns and school
                                closures. The October survey conveyed shifting attitudes based on the trainers’ sense of altruistic
                                intent. One hundred percent of the trainers participating in this Likert survey reported “true” or “very
                                true” when asked if they were inspired with new vison and a sense of purpose after completing their own
                                training and planning sessions. Two-thirds wrote additional comments explaining their enthusiasm with
                                terms such as “student purpose” “community motivation,” the chance to inspire “humanitarian students” or
                                “change agents” and the infusion of purposefully integrated activities into academic learning.</p>
                            <h1 className="subheading">Earlier Studies</h1>
                            <p>One independent study of a project in Los Angeles assessed the academic skills of a very small sampling
                                of Full-Circle learners each year over a five-year period. Experiencing the integration of character
                                education, academics and altruistic service projects, 75%-84% of these second- through fifth- graders
                                increased their grade equivalency in math, reading and vocabulary over the first six-month period.</p>
                            <p>Of those who remained in the study for two or more years, 50% enhanced grade equivalency by two or more
                                grade levels. (In other words, a child enrolled in the first month of the fourth grade might perform at
                                the level of a sixth-grade learner in her eighth month.)</p>
                            <p>Other California projects assessed the integration of learning and life skills in the years that
                                followed, between 2020 – 2015. Using a combination of parent surveys, student-self-surveys and teachers’
                                records, these schools documented continual enhancements in student growth.</p>
                            <h1 className="subheading">Characteristics of Learning and Growth</h1>
                            <p>Family members in these schools noticed that children who applied their creativity and their learning to
                                brighten the path of others suddenly wanted to learn even more. In 2007-2009, parents in four pilot
                                programs responded to anonymous surveys about their children, expressing various enhancements beyond
                                their expectations. Parents came from mostly low socio-economic ranges and communities of differing
                                dominant ethnicities. After six months of exposure to the learning model, parents reported higher- than
                                anticipated development in their children. The highest increases occurred in the following categories:
                            </p>
                            <List type="unstyled">
                                <li><em>Motivation to learn</em><br /><em>89%</em></li>
                                <li><em>Sense of joy in helping or serving others</em><br /><em>90%</em></li>
                                <li><em>Capacity and/or interest in problem solving</em><br /><em>75%</em></li>
                            </List>
                            <p>The relationship of these three items underscored the relationship of empathy-based cognitive action and
                                motivation. In addition to exercising collaborative learning strategies that encourage altruistic
                                identities, each of the schools offered learners at least four major community transformation projects
                                per year—some local and some global. The families’ anonymous reflections on the progress of their own
                                children showcased the pre-pandemic merits of intentionality in low-tech environments.</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default WhatWeDo