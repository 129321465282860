import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';
import familygarden from '../../assets/images/familygarden.png';
import Chad1 from '../../assets/images/Chad1.png';
import Chad2 from '../../assets/images/Chad2.png';
import Chad3 from '../../assets/images/Chad3.png';
import Chad4 from '../../assets/images/Chad4.png';

const images = [
  {
    src: Chad1,
    title: "School children and their two teachers in Chad."
  }, {
    src: Chad2,
    title: "An outdoor socially distanced gathering."
  }
];

const images1 = [
  {
    src: Chad3,
    title: "A class giving a presentation with song."
  }, {
    src: Chad4,
    title: "A young girl is ready to speak."
  }
];

const Chad = () => {
  const allImages = {
    images,
    images1,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Chad</h1>
        
          <div className="w-richtext">
            <figure className="w-richtext-align-fullwidth w-richtext-figure-type-image">
              <div><img
                src={familygarden}
                loading="lazy" alt="Teacher students stand among rows of plants" /></div>
            </figure>
            <h1>The Seeds of the Future</h1>
            <h1>Teachers need students as much as students need teachers</h1>
            <h3>A New Garden of Unity</h3>
            <p>When students crave the gifts of learning, their desire can unify and enrich an entire community.<br />
            </p>
            <p>At the Nouveau Jardin Community School in Moyo, Chad, learning was on hold due to the teachers’ lack
              of chalk to write lessons on the blackboard. Determined to solve this problem, students of all ages
              joined together in an agricultural project to both fund their education and enhance life in their
              community.<br /></p>
            <p>The students used a Full-Circle Learning grant to plant a garden in their town. Corn, sweet potatoes,
              and leafy greens all flourished in the sun. The students planted in such abundance that their new
              garden produced plenty of food to sell in the market, generating the money to buy chalk for their
              beloved teachers.<br /></p>
            <p>The students proudly tended this long-term and sustainable garden grown to fund the school. With this
              need met, they wanted to do more. Embracing the habit-of-heart of unity, they worked to bring in a
              harvest that would feed economically vulnerable members of the community with no charge.&nbsp;<br />
            </p>
            <p>A handful of seeds can transform the lives of young and old alike. Now the Nouveau Jardin School
              truly lives up to its name, a “new garden.”<br /></p>
            <p><em>Help tomorrow’s young leaders learn and grow. </em></p>
            <p><a href="/friends/how-you-can-help" className='inquires'>Please click below to donate
              today.</a></p>
            <p>&zwj;</p>
          </div>
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Brilliant Star became the first school in Chad to hostFull-Circle Learning trainings and
              translations. Visits to remote rural areas brought new skills and strategies to nomadic educators as
              well as villages. Children in various schools strived to master the habits of heart, to care for one
              another as one family, and to teach their parents conflict resolution and mindsets that would
              prevent corruption. Full-Circle Learning concepts continued to motivate students who extended their
              participation to include distance learning during the pandemic.</p>
            <p>In 2020, Lynn Whitehead, the founder of Brilliant Star, rejuvenated the program, diving deeper into
              the process of adapting and translating material and guiding the staff and regional facilitators. As
              a result, the Education Ministry monitored the training, praising the school and the potential
              impact of Full-Circle Learning on the quality of teaching in Chad. The following pictures feature:
            </p>
            <ol start="">
              <li>A school visit by facilitator Simadje Justin in 2020</li>
              <li>A 2020 teacher training session in one of two regions of Chad</li>
              <li>Children performing at Brilliant Star and at a rural school in 2016</li>
            </ol>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw'
        >
          {images1.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images1")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>

      </Row>
    </Container>
  )
}

export default Chad