import React from 'react'
import {
    Row, Col, Container
} from "reactstrap";

const HelpfulnessUnit = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <h1 className="mt-4 mb-4 fs-1">Helpfulness</h1>
                </Col>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >

                    <Row className="collection-list-3">
                        <Col sm="12" className="">
                            <h2 className="heading-21">Outline</h2>
                            <p>Teachers, parents or self-guided students can use this document to preview the elements and assignments to include based on available technologies.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Unit+Outline.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 1: Name the Taste</h2>
                            <p>Wendy Greene gives a taste of habits-of heart highlights learners can expect in this unit.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+1+Name+the+Taste.doc.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fcb7FVrepa5E%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dcb7FVrepa5E&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fcb7FVrepa5E%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 2: Benedict the Bee from Belize</h2>
                            <p>A bee story comes to you in a puppet show, starring Ophelia Douglas, filmed by her son, Young Douglas, Jr. If you cannot access the video, read the script and stage your own show! Take a peek at the charts.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+2++The+Bee+from+Beli.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Frfjf0D8tVoA&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Drfjf0D8tVoA&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Frfjf0D8tVoA%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 3: Family Word Games</h2>
                            <p>Word and music games help family members comprehend the mean of "helpfulness."</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+3+Family+Word+Games..docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 4: The Art of Healing</h2><p>Artist Masud Olufani shares helpful ways to view craftmanship. Learners of all ages explore helpful purposes for art, music and writing.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+4+The+Art+of+Helping.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-video w-embed resiliency_page_style_1">
                                    <iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FxarDtzf202k%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DxarDtzf202k&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FxarDtzf202k%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube" width="854" height="480" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
                                </div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 5: To Be or Not to Be</h2>
                            <p>Interviews with parent will present a chance to draw, act or write your family story!</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+5+To+Be+or+Not+to+Be.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 6: Bee Watchful </h2>
                            <p>Study how bees help the environment. Play a game to learn their secret codes. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+6+Bee+Watchful.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 7: Beelines &amp; Bar Codes</h2>
                            <p>Learn what bees and humans have in common, by studying QR codes and navigation systems.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+7+Beelines+and+Bar+C.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 8: Helpful Models</h2>
                            <p>Conduct your own insect experiments. Chart predictions and probabilities. Apply the results in projects that help others. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+8+Helpful+Models.doc.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div></div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 9: Human Habitats Need Help</h2>
                            <p>Predict future migration patterns. Exchange wisdom with those who share your goal of helpfulness.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Element+9+Human+Habitats+Need+Help.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 10: Hidden Gifts from History</h2>
                            <p>Learn about two lasting gifts through stories from distant parts of the world, and accept your own “lasting gift of helpfulness” challenge.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+10+Hidden+Gifts+from.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Element 11: A Sweet Celebration</h2>
                            <p>classNamemates or family members unite to celebrate successes—fun and refreshments included. </p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+11+A+Sweet+Celebrati.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                        <Col sm="12" className="">
                            <h2 className="heading-21">Glossary</h2>
                            <p>Review the meanings of underlined words here.</p>
                            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/helpfulness/Helpfulness+Element+12+Glossary.docx" className="download-button w-button">Download</a>
                            <div className="div-block-5">
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                                <div className="w-dyn-bind-empty w-video w-embed"></div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default HelpfulnessUnit