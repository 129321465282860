import React, { useState } from 'react'
import {
  Row, CardImg, Col, Container, Card
} from "reactstrap";
import MyModal from '../helpers/MyModal';

import Mexico1 from '../../assets/images/Mexico1.png';
import Mexico2 from '../../assets/images/Mexico2.png';
import Mexico3 from '../../assets/images/Mexico3.png';



const images = [
  {
    src: Mexico1,
    title: 'Piru children gathered holding balloons'
  },
  {
    src: Mexico2,
    title: "A child next to a sign saying Olivia's Tacos, a menu follows"
  },
  {
    src: Mexico3,
    title: 'A small child holding a decorated pillow'
  }
];

const Mexico = () => {
  const allImages = {
    images,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({ src: '', title: '' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalImage = (imageId, arrayName) => {
    const selectedArray = allImages[arrayName];
    if (selectedArray) {
      toggleModal();
      setCurrentIndex(selectedArray[imageId]);
    }
  };
  return (
    <Container>
      <MyModal currentIndex={currentIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">Mexico</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
          className='muli-colmn-tw my-3'
        >
          {images.map((image, index) => (
            <Card
              className="image-card"
              key={image.title}
              onClick={() => showModalImage(index, "images")}
              style={{ padding: "6px 0px" }}
            >
              <CardImg src={image.src} />
            </Card>
          ))}
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10
          }}
        >
          <div className="rich-text-block-2 color-link w-richtext">
            <p>Full-Circle Learning communities have launched several wisdom exchanges with children in Mexico over
              the years. Three examples follow: </p>
            <p>Children connecting across borders: A government agency had asked thatFull-Circle Learning launch a
              new preschool for Mexican-American preschool in Piru and Rancho Sespe, California, to help early
              learners begin their school career with the tools imbued by character-based academic learning
              culminating in service to others. One of the first habit-of-heart units was Friendship. The very
              first service-learning field triptook the preschoolers on a walk from school to the post office, to
              send wisdom exchange letters to families in Mexico and Central America. </p>
            <p>Pictured here, the Piru children send a wisdom exchange into the air to share “the air we breathe” as
              they depleted their balloons. </p>
            <p>Meanwhile, the Spanish translator of a book for elementary learners,<em>Habits of Oneness
            </em>introduced the curriculum to schools in her hometown in 2004. </p>
            <p>Another wisdom exchange ensued with a homeschool family, the Newcombs, who made frequent trips to
              Mexico. Olivia Newcomb, of Topanga, California, made it her personal goal at age 5 to raise money to
              furnish the library in Mexico with children’s books. She started a taco stand and hired her parents
              as cooks. Her service project succeeded after a year of hard work and the family’s travels back and
              forth. Twelve years later, after high school, Olivia traveled to developing nations to launch
              Full-Circle Learning projects before launching her singing career at opera school. </p>
            <p>In 2019, refugee children benefited from the compassion of Full-CircleLearners. Mothers and children
              fleeing unsafe conditions waited at La </p>
            <p>Casa, a safe house in Tijuana. Frightened of the future, the children needed comfort. Habits-of-Heart
              Club members Tarzana Elementary School provided it through a wisdom exchange. The children received
              pillows hand-decorated by the Tarzana students.</p>
          </div>
          <div className="rich-text-block w-dyn-bind-empty w-richtext"></div>
        </Col>
      </Row>
    </Container>

  )
}

export default Mexico